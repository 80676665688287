import React from 'react';
import { Redirect } from "react-router-dom"; 
import {Spin } from 'antd';
import '../App.css';
import {getUserToken,getUserID} from '../store/utility'
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

import {
    isIOS,
    isIE,
    isAndroid,
    isSafari,
  } from "react-device-detect";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class SessionVerification extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
        shortcut : "",
        token : "",
        user : {},
        session : null,
        session_found : false,
        id_dataset : -1, //default for aniseed
        params : {},
        dataset : {},
        connected_user_id : 0,
        redirection : 0,
        dataset_loaded : false,
        datasettreated : false,
        user_right : -1,
        session_got : false,
        stages : [],
        stages_got : false,
        stages_treated : false,
        user_infos_bind : false,
        infos_sent : false,
        unity_loaded : false,
    };

    componentDidMount(){
        this.setState({shortcut: this.props.match.params.shortcut});
        this.state.token = getUserToken();
        this.state.connected_user_id = getUserID();
        const u_id = getUserID() ? getUserID() : 0;

        axios.get(`/api/people/${JSON.parse(u_id)}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  this.setState({user:res.data.filter(set => set.id === parseInt(getUserID()))[0]});
                  this.state.user.password = "";
                  //console.log("user : ");
                  //console.log(res.data);
              });                                  
        }

    componentDidUpdate(prevState,prevProps){
        if (prevState.shortcut !== this.state.shortcut && !this.state.session_found){
            axios({
                method: 'get',
                url: `/api/sessionbyshortcut/`,
                params : { shortcut:this.state.shortcut},
              }
            ).then(res => {
                if (res.data.length > 0){
                    this.setState({session:res.data[0]});
                    this.setState({session_found:true});
                    //console.log("bound session");
                }
                else {
                    this.setState({session_found:true})
                    this.setState({redirection:1})
                }

            });
        }

        if (prevState.session !== this.state.session && !this.state.session_got){
            if (this.state.session !== undefined && this.state.session !== null){
                //console.log(" parse session : "+this.state.session.url)
            this.state.params.id = 1062;
            const session_params = this.state.session.url.split('&');
            session_params.map(param => {
                const p_values = param.split('=');
                if (p_values[0] === "id_dataset"){
                    p_values[0] = "id";
                }
                if (p_values[0] === "minTime"){
                    p_values[0] = "mintime";
                }
                if (p_values[0] === "maxTime"){
                    p_values[0] = "maxtime";
                }
                this.state.params[p_values[0]] = p_values[1];
            });
            if (this.state.params.id !== null && this.state.user_right < 0 || this.state.user_right === undefined){
                this.state.id_dataset = this.state.params.id;
                axios({
                    method: 'get',
                    url: `/api/useraccess/`,
                    params : { id_dataset:this.state.id_dataset,id_user:this.state.connected_user_id},
                  }
                ).then(res => {
                    if (res.data === 1 || res.data === "1"){
                        this.state.user_right = 1;
                    }
                    else {
                        this.setState({redirection:1});
                    }    
                }).catch(err => {
                    this.setState({redirection:1});
                });
            }
            //console.log("Session got");
           // //console.log(this.state.user_right);
            this.setState({session_got : true});
            }
        }

        if (prevState.id_dataset !== this.state.id_dataset && !this.state.dataset_loaded && this.state.id_dataset !== -1){
            axios({
                method: 'get',
                url: `/api/sitedataset/${this.state.id_dataset}/`,
              }
            ).then(res => {
                    this.setState({dataset:res.data}); 
                    this.setState({dataset_loaded:true});
            });
        }

        if (this.state.dataset !== prevState.dataset && this.state.dataset_loaded && !this.state.datasettreated){
            //console.log(this.state.params);
           for (let dataset_param in this.state.dataset){
               if (this.state.params[dataset_param] === undefined)
               {
                   if (dataset_param === "id_dataset"){
                    this.state.params['id'] = this.state.dataset[dataset_param];
                   }
                   else{
                    this.state.params[dataset_param] = this.state.dataset[dataset_param];
                   }
               }
           }

           if (this.state.params['quality'] === undefined || this.state.params['quality'] === null){
                this.state.params['quality'] = 0;
           }
           
           axios({
            method: 'get',
            url: `/api/sessionmeshid/`,
            params : { dataset_id:this.state.id_dataset},
            }).then(res => { 
                for (let elem in res.data){
                    this.state.stages[res.data[elem]['name']] = res.data[elem]['t'];
                    ////console.log("stages got")
                    this.setState({stages_got:true});
                }
            });
            
            if (this.state.params['time'] === undefined || this.state.params['time'] === null){
                this.state.params['time'] = this.state.params['maxTime'];
            }
           //console.log("dataset treated");
            //console.log(this.state.params);
            this.setState({datasettreated:true});
        }
        
        if (this.state.stages !== prevState.stages && this.state.stages_got && !this.state.stages_treated && this.state.params['stage'] !== undefined && this.state.params['stage'] !== null){
            //console.log("stage treated")
            const fullname = "Stage " + this.params['stage'];
            if (this.state.stages[fullname] !== undefined && this.state.stages[fullname] !== null){
                this.state.params['time'] = this.state.stages[fullname];
            }
            else if (this.state.stages[this.state.params['stage']] !== null && this.state.stages[this.state.params['stage']] !== undefined){
                this.state.params['time'] = this.state.stages[this.state.params['stage']];
            }
            else if (this.state.stages[fullname.toLowerCase()] !== undefined && this.state.stages[fullname.toLowerCase()] !== null){
                this.state.params['time'] = this.state.stages[fullname.toLowerCase()];
            }
            ////console.log("stages treated");
            this.setState({stages_treated:true});
        }

        if (prevState.user !== this.state.user && this.state.session_got && !this.state.user_infos_bind){
            //console.log("infos bind")
            this.state.params['u_ID']=0;
            this.state.params['u_name'] = "Public";
            this.state.params['u_surname'] = "";
            if (this.state.user !== undefined && this.state.user !== null && this.state.user.id !== 0){
                this.state.params['u_ID']=this.state.user.id;
                this.state.params['u_name'] = this.state.user.name;
                this.state.params['u_surname'] = this.state.user.surname;
         }
         ////console.log("user infos bind");
         
         this.setState({user_infos_bind: true});
        }

        if (this.state.user_infos_bind && this.state.datasettreated && !this.state.infos_sent && (this.props.unityLoaded || (isSafari || isIOS || isAndroid || isIE))){
           // //console.log("send infos");
            //send params
            //console.log(this.state.params);
            this.state.params['loaded_session'] = true;
            this.props.unityLoadFunc(this.state.params);
            //send stages
            this.props.receiveStage(this.state.stages);
            this.setState({infos_sent:true});
        }
    }

    render() {
        return (
            <div>
                <br></br>
                {
                    (!this.props.unityLoaded || !this.state.infos_sent) &&
                    <Spin indicator={antIcon} size="large" style={{marginLeft:'50%'}}></Spin>
                }
                <br></br><br></br>
                {
                    !this.props.unityLoaded &&
                    <span style={{fontSize:'21px',textAlign:'center',marginLeft:'39%'}} > The data viewer is loading in background, please wait </span>
                }
                {
                    this.props.unityLoaded && !this.state.infos_sent &&
                    <span style={{fontSize:'21px',textAlign:'center',marginLeft:'39%'}} > Session is being sent to the viewer, please wait </span>
                }
            </div>
        );
    }
}

export default SessionVerification;