import {connect} from "react-redux";
import React from "react";
import {
    AutoComplete,
    Button,
    Checkbox,
    Form,
    Icon,
    Input,
    InputNumber,
    List,
    message,
    Select,
    Tooltip,
    Upload
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {ReloadDatasets, SendTracking} from "../utils/api_callbacks";
import axios from "axios";
import {getUserToken} from "../store/utility";
import FloatingPanel from "../components/center_panel";
import store from "../store/store";
import {SetRedirectedUrl} from "../store/reducers/userSlice";

const {Option} = AutoComplete;


class ImportDataset extends React.Component {

    state = {
        set_error: false,
        set_name: "",
        set_starttime: 0,
        set_endtime: 0,
        set_comment: "",
        upload_texture: false,
        set_type: 0,
        set_spf: 0,
        set_dt: 0,
        buttonClicked: false,
        set_ncbi: 0,
        mesh_time: 1,
        mesh_quality: 1,
        mesh_channel: 0,
        texture_file: null,
        material_content: null,
        is_not_obj: false,
        file_uploaded: false,
        fileList: [],
        uploading: false,
        set_serverid: -1,
        display_loading_panel: false,
        loading_panel_text: "",
    }

    bind_loading_panel = (isactive, text) => {
        this.setState({display_loading_panel: isactive, loading_panel_text: text});
    }


    componentDidMount() {
    }

    ImportDataset = () => {
        this.bind_loading_panel(true, "Importing dataset , please wait . . .");
        let formData = new FormData();
        formData.append('file', this.state.fileList[0])
        axios({
            method: 'post',
            url: `/api/importdataset/`,
            headers: {Authorization: `Token ${getUserToken()}`},
            data: formData,
        }).then(res => {
            this.bind_loading_panel(false, "");
            ReloadDatasets();
            this.props.SetRedirectedUrl("/dataset");
            //console.log(res.data);

            //this.props.SetRedirectedUrl("/dataset/"+res.data.id);
        }).catch(err => {
            this.bind_loading_panel(false, "");
            console.log(err.response.data);
        });
        //return axios.post(`/api/importdataset/`, formData)
    }


    render() {
        const {uploading, fileList} = this.state;
        if (!this.props.connected && !this.props.staff) {
            return <div></div>
        }

        const props = {
            onRemove: file => {
                this.setState({file_uploaded: false});
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if (file.name.endsWith('.zip')) {
                    this.setState({is_not_obj: false});
                    this.setState({file_uploaded: true});
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                } else {
                    this.setState({is_not_obj: true});
                }
                return false;
            },
            fileList,
        };
        return (
            this.state.display_loading_panel ?
                <FloatingPanel text={this.state.loading_panel_text}></FloatingPanel>
                :
                <div style={{marginTop: "3%"}}>

                    <h1 style={{
                        color: '#292e37',
                        fontSize: '34px',
                        textAlign: 'center',
                        fontWeight: "bold",
                        minWidth: '750px'
                    }}> Import a dataset </h1>
                    {

                        this.state.set_error &&
                        <div><h2 style={{
                            color: '#292e37',
                            fontFamily: 'Baskerville',
                            fontSize: '34px',
                            textAlign: 'center',
                            fontWeight: "bold",
                            minWidth: '750px'
                        }}>Error during creation : {this.state.set_error}</h2>
                        </div>
                    }
                    <Form>

                        {
                            !this.state.file_uploaded && this.state.buttonClicked ?
                                <h2 style={{color: 'red', fontSize: '18px'}}>You need to upload a 3ip file (that
                                    contains an
                                    dataset instance to import a dataset</h2>
                                :
                                this.state.is_not_obj &&
                                <h2 style={{color: 'red', fontSize: '18px'}}>The instance that you upload MUST be a
                                    .zip
                                    file !</h2>

                        }
                        <div><h2 style={{fontSize: '21px'}}>
                            Choose a zip file
                        </h2>
                            <Upload {...props}>
                                <Button>
                                    <UploadOutlined/> Select File (ZIP)
                                </Button>
                            </Upload>
                        </div>
                        <br></br><br></br>
                        <Form.Item>
                            <Button type="primary" onClick={this.ImportDataset}
                                    style={{
                                        borderColor: '#44D4FF',
                                        backgroundColor: '#44D4FF',
                                        marginRight: '10px'
                                    }}>
                                Import dataset
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        connected: state.user.is_connected,
        staff: state.user.is_staff,
        server_list: state.user.server_list,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }

}
export default connect(mapStateToProps, mapDispatchToProps)(ImportDataset);