import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {
    dataset_by_tag: {}
}

const tagAdapter = createEntityAdapter({
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const tagSlice = createSlice({
    name: 'tag', initialState: tagAdapter.getInitialState(initialState), reducers: {
        // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))
        tagAdded: tagAdapter.addOne, // dispatch(datasetReceived({ id: '', ... }))
        tagListAdded: tagAdapter.addMany, //dispatch(datasetUpdated({ id: '', changes: {... } }))
        tagUpdated: tagAdapter.updateOne,
        tagListUpdated: tagAdapter.setAll,
        LoadMultipleTagSets: (state, param) => {
            const {payload} = param;
            for (let [key, value] of Object.entries(payload)) {
                if (value.length > 0) {
                    value.map(tag => {
                        if (!(key in state.dataset_by_tag)) {
                            state.dataset_by_tag[key] = []
                        }
                        state.dataset_by_tag[key].push(tag.id);
                    });
                }

            }
        },
        LoadTagSet: (state, param) => {
            const {payload} = param;
            const tag_id = payload[0]
            const set_id = payload[1]
            if (!(tag_id in state.dataset_by_tag)) {
                state.dataset_by_tag[tag_id] = []
            }
            state.dataset_by_tag[tag_id].push(set_id);
        },
    },
})

export const {tagAdded, LoadMultipleTagSets, tagListAdded, tagListUpdated, tagUpdated} = tagSlice.actions
// ??
export default tagSlice.reducer