import React from "react";
import {connect} from "react-redux";
import {AddTagInFilters, RemoveTagInFilters} from "../store/reducers/datasetSlice";


class DatasetTagFilter extends React.Component {


    componentDidMount() {
    }

    isChecked = (tag_id) => {
        const tags_checked = this.props.dataset.current_filtered_tags;
        return tags_checked.includes(tag_id);
    }
    handleChange = (tag_id) => {
        if (this.isChecked(tag_id)) {
            this.props.RemoveTagInFilters(tag_id);
        } else {
            this.props.AddTagInFilters(tag_id);
        }


    };

    render() {
        const tags = Object.values(this.props.tag.entities);
        return <div>
            <h3 style={{fontSize:"20px",marginLeft:"5px",marginTop:"10px"}}> Filter by tags</h3>
            {
            tags.map(tag =>
                <div key={"tag_" + tag.id}><label className={"GroupFilterText"}><input
                    style={{marginTop:"7px",marginRight:"5px"}}
                    onChange={() => this.handleChange(tag.id)}
                    checked={this.isChecked(tag.id)}
                    name={tag.id}
                    type="checkbox"></input> {tag.name}</label>
                    <img style={{display:"inline",width:"15px",height:"15px",verticalAlign:"center",marginLeft:"5px",paddingBottom:"5px",backgroundColor:tag.color}}></img>


                </div>)}
            <br></br></div>
    }
}


const mapStateToProps = (state) => {
    return {
        tag: state.tag,
        dataset: state.dataset,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        AddTagInFilters: (tag_id) => dispatch(AddTagInFilters(tag_id)),
        RemoveTagInFilters: (tag_id) => dispatch(RemoveTagInFilters(tag_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetTagFilter);