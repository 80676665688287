import React from 'react';
import axios from 'axios';
import { List,Input,Switch,Tooltip,PageHeader, Button, Descriptions,Select  } from 'antd';
import {NavLink,Link,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {getUserToken,getUserID,removeCookie,postNotification} from '../store/utility'
import {SendTracking} from "../utils/api_callbacks";
const { Option } = Select;
class UserManagement extends React.Component {

    state = {
      //all user managed by this user
        all_users : [],
        user : {},
        unfiltred_all_users: [],
        groups : [],
        public_groups : [],
        staff : false,
        beta : false,
        token : "",
        connected_user_id : -1,
        //signal to update render auto (just change this using setState() for refresh view)
        updater : false,
        user_institution : "",
        user_team : "",
        user_laboratory : "",
        user_size : 0,
        user_settings : [],
        unity_version:'stable',
        new_server_adress :'',
        
    }
    user_id = -1;
    
    getUserSettingsValue(p_setting)
    {
        let value = -1;
        if (this.state.user_settings !== undefined){
          this.state.user_settings.map(sett => {
          if (sett.setting === p_setting){
            value = sett.value;
          }
        });
      }
        return value;
    }

    // logout user
  onLogout = () =>{
    this.props.history.push('/');
    window.location.reload();
    this.props.logout();
  }

    //retrieve user + list of users managed by this user
    componentDidMount() {
      //is user admin from cache ? 
      this.setState({token : getUserToken()});
      this.state.connected_user_id = getUserID();
      window.addEventListener('disconnect',() => {this.componentDidMount();})
      this.state.unity_version = "stable";
      //timer for display
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);

    }

    //get user name inside html objects
    getUserName(id){
        const userName = {};
        this.state.all_users.map((user) => {
          if (user.id === id)
          {
            userName.name = user.name;
            userName.surname = user.surname;
          }
          
      });
      return userName;
    }

    componentDidUpdate(prevProps, prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });

        axios.get('/api/people-betatest/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({beta:res.data['beta']});
        });

            axios.get('/api/people-simulator/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulator: res.data['simulator'] });
                });

            if (this.state.token !== "" && this.state.token !== null) {
                axios.get(`/api/user/${this.state.connected_user_id}`, { headers: { Authorization: `Token ${this.state.token}` } })
                    .then(res => {
                        res.data.password = "";
                        this.setState({
                            user: res.data,
                        });
                    });
            }

      if (this.state.token !== "" && this.state.token !== null){
        axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                user_settings: res.data,
            });
        });
    }
  
      axios.get(`/api/userversion/`,{ params:{user_id:this.state.connected_user_id !== undefined && this.state.connected_user_id > 0?this.state.connected_user_id:0}})
      .then(res => {
          this.setState({
              unity_version: res.data,
          });
      });
  
      
      if (this.state.token !== "" && this.state.token !== null){
        axios.get(`/api/workbyuser/`,{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                user_institution: res.data.institution,
                user_team : res.data.team,
                user_laboratory : res.data.laboratory
            });
        });
    }
      
    if (this.state.token !== "" && this.state.token !== null){
      axios.get(`/api/usersize/`,{ headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => {
          this.setState({
              user_size: res.data,
          });
      });
  
      axios.get('/api/group/',{ headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  this.setState({
                    groups: res.data
                  });
                  this.state.groups.map(group => {
                    axios.get(`/api/people/${group.id_owner}`,{headers: { Authorization: `Token ${this.state.token}` } })
                   .then(res => {
                      res.password = "";
                      group.ownername = res.name;
                      group.ownersurname = res.surname;
                      group.ownerlogin = res.login;
                      });
                  });
                  this.state.groups.sort(function (a,b){
                    return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                  });
              })
  
              axios.get('/api/publicgroup/',{ headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  this.setState({
                    public_groups: res.data
                  });
  
                  this.state.public_groups.map(group => {
                    axios.get(`/api/people/${group.id_owner}`,{headers: { Authorization: `Token ${this.state.token}` } })
                   .then(res => {
                      res.password = "";
                      group.ownername = res.name;
                      group.ownersurname = res.surname;
                      group.ownerlogin = res.login;
                      });
                  });
  
                  this.state.public_groups.sort(function (a,b){
                    return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                  });
              })
              axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
              .then(res => {
                  this.setState({
                    all_users: res.data,
                    unfiltred_all_users: res.data
                  });
                  //Sort on name + surname, if not use item login 
                  this.state.all_users.sort(function (a,b){
                    if (a.name === "" && b.name === ""){
                      return a.login.toLowerCase()<b.login.toLowerCase()? -1 : a.login.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                    }
                    else if (a.name === ""){
                      return a.login.toLowerCase()<b.name.toLowerCase()? -1 : a.login.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                    }
                    else if (b.name === ""){
                      return a.name.toLowerCase()<b.login.toLowerCase()? -1 : a.name.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                    }
                    else {
                    return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;}
                  });
                  this.state.unfiltred_all_users.sort(function (a,b){
                    if (a.name === "" && b.name === ""){
                      return a.login.toLowerCase()<b.login.toLowerCase()? -1 : a.login.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                    }
                    else if (a.name === ""){
                      return a.login.toLowerCase()<b.name.toLowerCase()? -1 : a.login.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                    }
                    else if (b.name === ""){
                      return a.name.toLowerCase()<b.login.toLowerCase()? -1 : a.name.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
                    }else{
                    return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
                  }
                  });
              })
  }
    }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
  }

  bytesToSize = bytes => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0 || bytes === -1) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

 clearTutorialSettings = () => {
   if (this.state.token !== "" && this.state.token !== null){
    if (this.getUserSettingsValue("tutorial") !== "false"){
    axios.get(`/api/deletetutorials/`,{ headers: { Authorization: `Token ${this.state.token}` } }).then(res => {
        axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                user_settings: res.data,
            });
        });
    });
  }
    else {

    }
  }
 }

 onChangeSetting(checked,setting) {
  axios({
    method: 'get',
    url: '/api/updatesetting/',
    params: {
        setting_name:setting,
        setting_value:checked,
    },
    headers :{ Authorization: `Token ${this.state.token}` }
  }).then(res => {
    if (this.state.token !== "" && this.state.token !== null){
      axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => {
          this.setState({
              user_settings: res.data,
          });
      });
      postNotification(<h1 style={{color:'#44D4FF',fontSize:'18px'}}>Successfuly updated setting.</h1>, 'Setting new value : '+checked);
    }
  });
}

onVersionChange = (value) => {
  this.setState({unity_version:value});
  axios.get(`/api/storeviewerversion/`,{params:{version:value}, headers: { Authorization: `Token ${this.state.token}` } }).then(res => {      postNotification(<h1 style={{color:'#44D4FF',fontSize:'18px'}}>Viewer version updated.</h1>, 'New version : '+value);
});
}

    //called everytime the DOM has changed
    render() {
        return (
          this.state.token === null || this.state.token === ""?
          <div>Please register to access this page</div>
          :
            <div>
              <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px'}}> User Management </h1>
              <div >
              <div style={{textAlign:'center'}}><Tooltip placement="bottom" title={<span>Log out from MorphoNet</span>}>
                  <img alt="Logout" onClick={this.onLogout} width='70' src="/images/refonte/LogOut.png" style={{marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                </Tooltip>
                <Link to = "/userbin">
                    <Tooltip placement="bottom" title={<span>User bin</span>}>
                    <img onClick={() => 
                    this.props.sendTracking(-1,"Web,Bin,view")
                    } alt="Logout" width='50' src="/images/garbage.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                    </Tooltip>
                    </Link>
                  {
                      this.state.token !== undefined && this.state.token !== "" &&
                      <Link to = "/group">
                          <Tooltip placement="bottom" title={<span>Open group management page</span>}>
                              <img  alt="Logout" width='50' onClick={() => {
                                 SendTracking(-1,"Web,Users,DistantServers,view");
                              }} src="/images/refonte/users-group.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                          </Tooltip>
                      </Link>
                  }
                 {
                  this.state.staff &&
                    <Link to = "/userslist">
                    <Tooltip placement="bottom" title={<span>User list</span>}>
                    <img alt="Logout" width='50' onClick={() => {
                      this.props.sendTracking(-1,"Web,Users,UserList,view");
                    }}src="/images/refonte/man-user.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                    </Tooltip>
                    </Link>
                }

                  {
                   (this.state.staff || this.state.beta ) &&
                    <Link to = "/morphostat">
                    <Tooltip placement="bottom" title={<span>Connexions and interactions</span>}>
                    <img style={{marginLeft:''}} alt="Logout" width='50' onClick={() => {
                      this.props.sendTracking(-1,"Web,Users,MorphoStat,view");
                    }}src="/images/time-left.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                    </Tooltip>
                    </Link>
                }
                {
                  this.state.token !== undefined && this.state.token !== "" && 
                  <Link to = "/distantservers">
                  <Tooltip placement="bottom" title={<span>Manage your distant servers</span>}>
                    <img style={{marginLeft:''}} alt="Logout" width='50' onClick={() => {
                      this.props.sendTracking(-1,"Web,Users,DistantServers,view");
                    }}src="/images/cloud-upload-2.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                  </Tooltip>
                  </Link>
                }

{
                  this.state.token !== undefined && this.state.token !== "" && 
                  <Link to = "/tags">
                  <Tooltip placement="bottom" title={<span>Manage your tags</span>}>
                    <img style={{marginLeft:''}} alt="Logout" width='50' onClick={() => {
                      this.props.sendTracking(-1,"Web,Users,Tags,view");
                    }}src="/images/refonte/tag.png" style={{marginLeft:'35px',marginTop:'6%',mixBlendMode:'multiply',cursor:'pointer'}}></img>
                  </Tooltip>
                  </Link>
                            }
                            {this.state.token !== undefined && this.state.token !== "" && this.state.simulator &&
                                <Link to="/simulation">
                                    <Tooltip placement="bottom" title={<span>Simulations</span>}>
                                        <img onClick={() =>
                                            this.props.sendTracking(-1, "Web,Simulation,view")
                                        } alt="simulation" width='50' src="/images/plant.png" style={{ marginLeft: '35px', marginTop: '6%', mixBlendMode: 'multiply', cursor: 'pointer' }}></img>
                                    </Tooltip>
                                </Link>
                            }

              </div>
                <br></br><br></br><br></br><br></br>
                <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Yourself</p>
                <PageHeader
                  ghost={false}
                  title={this.state.user.name+" "+this.state.user.surname}
                  subTitle={this.state.user.login}
                  extra={[
                    <NavLink exact to={{pathname:`/display-user`,aboutProps:this.state.user}}>
                    <Button onClick={() =>   
                    this.props.sendTracking(-1,"Web,Users,EditUser,view")} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} key="1" type="primary">
                      Edit user
                    </Button></NavLink>,
                  ]}
                >
                  <Descriptions size="small" column={3}>
                  <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Email</span>}><p style={{fontSize:'17px'}}> {this.state.user.email}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Institutions</span>}><p style={{fontSize:'17px'}}>{this.state.user_institution?this.state.user_institution:"Undefined"}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Laboratory</span>}><p style={{fontSize:'17px'}}>{this.state.user_laboratory?this.state.user_laboratory:"Undefined"}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Team</span>}><p style={{fontSize:'17px'}}>{this.state.user_team?this.state.user_team:"Undefined"}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Fonction</span>}><p style={{fontSize:'17px'}}>{this.state.user.fonction?this.state.user.fonction:"Undefined"}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Signed up the</span>}><p style={{fontSize:'17px'}}>{this.state.user.date?new Date(this.state.user.date).toISOString().replace(/T/gi,' ').split('.')[0]:"Undefined"}</p></Descriptions.Item>
                    <Descriptions.Item label={<span style={{color:'#44D4FF',fontSize:'17px'}}>Quota</span>}><p style={{fontSize:'17px'}}>{this.state.user_size?this.bytesToSize(this.state.user_size):"0Mb"}/{this.state.user.quota?this.bytesToSize(this.state.user.quota):"0Mb"}</p></Descriptions.Item>
                  </Descriptions>
                </PageHeader>
                <p style={{marginLeft:'1%',fontSize:'17px'}}> </p>
                <br></br>
                <p style={{marginLeft:'1%',fontSize:'17px'}}> </p>
              </div>

                <div>
                    <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Settings</p>
                    <div style={{marginLeft:'1%'}}><Tooltip placement="top" title={<span>The version of the 3D Visualizer that will be loaded</span>}>
                        <span style={{fontSize:'17px'}}>- MorphoNet data viewer version</span>
                        <br/>
                        <br/>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      value={this.state.unity_version}
                      placeholder="Select a version"
                      optionFilterProp="children"
                      onChange={this.onVersionChange}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="stable">Stable</Option>
                      <Option value="dev">Development (Unstable)</Option>
                    {
                        (this.state.staff || this.state.beta ) &&
                        <Option value="beta">Beta (Mid range update)</Option>
                      }
                      {
                        (this.state.staff || this.state.beta ) &&
                        <Option value="test">Test (Short range update)</Option>
                      }

                    </Select>
                    </Tooltip>
                    <br></br>
                    <br></br>
                    <i style={{fontSize:'15px'}}> You can change the viewer version. The main version is the “Stable” one, it has been tested. You can change to “Development”, a version that contain more feature but not completly tested, and no documentation on updated feature is available. You need to restart the website to apply this setting. </i>
                        <p style={{marginLeft:'1%',fontSize:'17px'}}> </p></div>

              </div>

              <br></br>
              <div>

                  {false && <div><span style={{marginLeft:'1%',fontSize:'17px'}}> Allow favourite data management</span>
                  <Tooltip placement="top" title={<span>Favourite datasets, groups, ...</span>}><Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked = {this.getUserSettingsValue("favoritedisplay") !== "false"}
                  onChange = {(checked) => this.onChangeSetting(checked,"favoritedisplay")}
                  style = {{marginLeft: '25px'}}
                />
                  </Tooltip>
                  <br></br>
                  <i style={{marginLeft:'1%',fontSize:'15px'}}>Disabling this option remove the favourite management part from the website. You can no longer add/see your favourites on top of the dataset list.</i>
            <br></br><br></br></div>}

                  { false && <div><span style={{marginLeft:'1%',fontSize:'17px'}}> Display collaborative mode</span>
                <Tooltip placement="top" title={<span>Disabled while collaborative mode is still in development</span>}><Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  style = {{marginLeft: '25px'}}
                  checked = {this.getUserSettingsValue("collaborativemode") !== "false"}
                  onChange = {(checked) => this.onChangeSetting(checked,"collaborativemode")}
                /></Tooltip>
                  <br></br>
                  <i style={{marginLeft:'1%',fontSize:'15px'}} >Disabling this option remove the collaborative system from the website. It only includes the priority marker on the datasets, but more collaborative options will be added in later release.</i>

                      <br></br><br></br></div>}
                  { false && <div><Tooltip placement="top" title={<span>Display tutorials (need restart)</span>}>
                <span style={{marginLeft:'1%',fontSize:'17px'}}> Display tutorials (need restart)</span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked = {this.getUserSettingsValue("tutorial") !== "false"}
                  onChange = {(checked) => {this.onChangeSetting(checked,"tutorial");if (checked){removeCookie('hide_tutorials')}}}
                  style = {{marginLeft: '25px'}}
                />
                </Tooltip>
                  <br></br>
                  <i style={{marginLeft:'1%',fontSize:'15px'}} >Enabling this option will display the tutorials on the website the next time you connect to MorphoNet. This option disable automatically when you click “hide tutorials” when a tutorial is loaded.</i>

                      <br></br><br></br><br></br></div>
                  }

                {
                   (this.state.staff || this.state.beta ) && false &&
                    <div>
                  <Tooltip placement="top" title={<span>When loading a dataset, use standalone installed version</span>}>
                  <span style={{marginLeft:'1%',fontSize:'17px'}}> When loading a dataset, use standalone installed version</span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked = {this.getUserSettingsValue("standalone_start") === "true"}
                    onChange = {(checked) => {this.onChangeSetting(checked,"standalone_start");}}
                    style = {{marginLeft: '25px'}}
                  />
                  </Tooltip>
                  <br></br>
                  <span style={{marginLeft:'1%',fontSize:'18px'}}> If you wish to download MorphoNet standalone versions, follow this link <a href="/download"></a>.</span>
                        <br></br><br></br><br></br>
                    </div>
                }


                {
                   (this.state.staff || this.state.beta ) &&
                    <div>
                <span style={{marginLeft:'1%',fontSize:'17px'}}>- Server adress used by default when requesting a distant plot session on a dataset</span>
                  <Tooltip placement="top" title={<span>Input default adress </span>}>
                      <br></br><br></br>
             <Input
                 style={{marginLeft:'25px', width:'25%'}}
                 placeholder="Default MorphoPlot server adress"
                 defaultValue={this.getUserSettingsValue("distant_plot_adress") !== -1?this.getUserSettingsValue("distant_plot_adress"):"Default MorphoPlot server adress"}
                 onChange={value => { this.setState({new_server_adress:value.target.value});}}>
             </Input>
                <Button style={{marginLeft:'25px'}} type="primary" onClick={() => {this.onChangeSetting(this.state.new_server_adress,"distant_plot_adress");}}>Apply</Button>
                  </Tooltip>
                  <br></br>
                  <br></br>
                  <i style={{marginLeft:'1%',fontSize:'15px'}}>Customize the adress of the server that will handle data for a distant curation process on specified omero dataset.</i>
                    </div>
                }
                <br></br><br></br>

                  <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Troubleshooting</p>
                <span style={{marginLeft:'1%',fontSize:'18px',display:'inline', fontWeight:'bold'}}> If you have problem with the viewer, you can try :</span>
                <br></br><br></br>
                <Tooltip placement="top" title={<span>Clear unity cache</span>}>
                <span style={{fontSize:'17px'}}> - Clear unity Cache : </span>
                <Button onClick={() => {  
                  this.props.sendTracking(-1,"Web,Users,ClearCache");this.props.clearCache();}} style={{marginLeft:'1%',borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} key="1" type="primary">
                      Clear
                </Button>
                </Tooltip>
                  <br></br>
                  <br></br>
                  <i style={{marginLeft:'1%',fontSize:'15px'}} > When you 3D viewer seems to have problems to load data/load itself, you can try to click here. It will clear the content of the local storage of the viewer.</i>  <br ></br>
                      <i style={{marginLeft:'1%',fontSize:'15px'}}>If problem still occurs, you can clear the browser cache (in your browser options), or send us a feedback using the feedback system.</i>
                <br></br><br></br>
                <span style={{fontSize:'17px'}}> - You can access troubleshootings and a tutorial to clear your browser cache <a href="/help_troubleshoot">here</a>. </span>
              </div>
            </div>
           );
    }
}

//allow the compoennt to be accessed everywhere else
const mapStateToProps = state => {
  return {
      isAuthenticated: state.token !== null,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps) (UserManagement));
