import React from 'react';
import { Row, Col,Card} from 'antd';

const { Meta } = Card;

class Team extends React.Component {
    constructor(props){
        super(props);
	}
	state = {

	}

    render(){
        return(
			<div>

            <div style={{marginTop:'3%',textAlign:'center'}}>
                <h1 style={{fontWeight:'bold',fontSize:'42px', textDecoration:'underline'}}>Meet our team</h1>
                <br></br>
                <br></br>

                <Row gutter={[24, 24]}  >

                    {/*  First person  */}
                    <Col  span={4} style={{marginLeft: '3%' , width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Emmanuel Faure" src="/images/Emmanuel.png" height="240" /> }
                        style={{ margin:'auto'}}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Emmanuel Faure</span></div>} description={<div><span>Researcher at the CNRS. He’s the founder and Chief Scientific Officer of MorphoNet.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>emmanuel.faure@lirmm.fr</span></div>} />
                    </Card>
                    </Col>


                    {/*  Second person  */}
                    <Col  span={4} style={{width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Patrick Lemaire" src="/images/patrick.png" height="240" /> }
                        style={{ margin:'auto'}}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Patrick Lemaire</span></div>} description={<div><span>Research Director at the CNRS, he is involved as a biological consultant. His view is crucial to the success.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>patrick.lemaire@crbm.cnrs.fr</span></div>} />
                    </Card>
                    </Col>

                    {/*  Third person  */}
                    <Col  span={4} style={{width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Benjamin Gallean" src="/images/Benjam.png" height="240" /> }
                        style={{ margin:'auto' }}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Benjamin Gallean</span></div>} description={<div><span>Freshly graduated, he works as a full-stack developer for MorphoNet. You'll surely find him coding.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>benjamin.gallean@lirmm.fr</span></div>} />
                    </Card>
                    </Col>

                    {/*  fourth person  */}
                    <Col span={4} style={{width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Gwendal Fouché" src="/images/Gwendal.png" height="240" /> }
                        style={{ margin:'auto' }}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Gwendal Fouché</span></div>} description={<div><span>PhD student, he works on the immersive interaction and the visualization of Temporal 3D aka the VR app.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>gwendal.fouche@inria.fr</span></div>} />
                    </Card>
                    </Col>

                   {/*  five person  */}
                    <Col span={4} style={{width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Tao Laurent" src="/images/Tao.png" height="240" /> }
                        style={{margin:'auto' }}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Tao Laurent</span></div>} description={<div><span>Intern student, he works on the integration of LIDAR data in morphoNet.This includes visualization and annotation.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>tao.laurent@lirmm.fr</span></div>} />
                    </Card>
                    </Col>

                    {/*  six person  */}
                    <Col span={4} style={{width: 275}}>
                    <Card
                        hoverable
                        cover={<img alt="Marine Gardeisen" src="/images/Marine.png" height="240" /> }
                        style={{ margin:'auto' }}
                    >
                        <Meta title={<div> <span style={{textDecoration:'underline'}}>Marine Gardeisen</span></div>} description={<div><span>Research engineer, she code on the front part. To improve the design and the user experience of the website.</span><br></br><br></br><img alt="email" height={30} src="/images/Feedbacks.png"/><span style={{color:'black', fontSize:'12px'}}>marine.gardeisen@lirmm.fr</span></div>} />
                    </Card>
                    </Col>
                </Row>
			</div>

                <div style={{marginLeft: '7%',marginTop:'3%' }}>
                    <h1 style={{fontWeight:'bold',fontSize:'42px', textDecoration:'underline', textAlign:'center'}}>Beta users</h1>
                    <i style={{fontWeight:'italic',fontSize:'20px',color:'grey'}}>Here are the beta users who have contributed or still contribute to the successful development of this tool. We read their feedback, encouragement, suggestions and we work with this precious information. Thanks for your collaboration</i>
                    <br></br>
                    <br></br>

                    <Row style={{margin:'auto',width:'95%', verticalAlign:'center'}} gutter={[24, 24]}>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt={"anuradha"} width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Anuradha <br></br> Kar</span></div>
                            <br></br>
                        </Col>


                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt="Luc" width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Luc <br></br>  Lederer </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt={"Kilian"} width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Kilian <br></br>  Biasuz </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt={"Julien"} width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Julien <br></br>  Laussu </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt="Sigi" width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Siqi <br></br>  Liu </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt={"Jan"} width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Jan <br></br>  Traas </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt="Manuel" width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Manuel <br></br>  Petit </span></div>
                            <br></br>
                        </Col>

                        <Col  span={4} style={{width: 230}}>
                            <div style={{verticalAlign:'center', margin:'auto'}} >
                                <img alt="Piette" width={80} style={{verticalAlign:'center',float:'left', margin:'auto'}} src='images/unisex_best.png'></img>
                                <span style={{textAlign: 'left', verticalAlign:'center',color:'black',fontSize:'25px', margin:'auto'}}> Piette <br></br>  Jacques </span></div>
                            <br></br>
                        </Col>


                </Row>
                </div>




            </div>
        );
    }
}

export default Team;