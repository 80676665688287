import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';

class HelpTutorial extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
		staff : false,
        source_link : "helpfiles/tutorials.md",
	}


    render(){
        return(
			<div>
            <div style={{margin:'auto',marginTop:'1%'}}>
                {
                    this.state.source_link !== "" &&
                    <MorphoMDEditor subfolderName={"tutorials"} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpTutorial;