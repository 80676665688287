import React, { Component} from "react";
import Unity, { UnityContext  } from "react-unity-webgl";
import { withRouter } from 'react-router-dom';
import { Tooltip } from 'antd';
import {getUserToken,getUserID,getCookie,isOnStandalone} from '../store/utility'
import axios from 'axios';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import {UnityIsLoaded} from "../store/reducers/datasetSlice";

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

class UnityContainer extends Component {
        constructor(props){
          super(props);

          this.escFunction = this.escFunction.bind(this);
          //this.button_explorer = React.createRef();
          this.explorer = React.createRef();
          this.explorer_colormap = React.createRef();
          this.unity_div = React.createRef();
        }



        state = {
          lineage_id_cell : -1,
          connected_user_id : -1,
          lineage_time_cell : -1,
          unityProps : {},
          sessionStages : [],
          user: undefined,
          user_right: 3,
          token: "",
          sessionMode : "",
          lineage : null,
          active : false,
          done : false,
          loaded: false,
          send_reset : false,
          fullscreen : false,
          windowed_mode : false,
          previously_windowed : false,
          session_stages : [],
          defaultsession_treated : false,
          api_mode : false,
          stages_treated: false,
          display_lineage: false,
          codeUrl :  undefined,
          loaderUrl : undefined,
          dataUrl : undefined,
          frameworkUrl: undefined,
          unity_version : undefined,
          unity_infos_got : false,
          updated_dataset : false,
          live_mode : false,
          right_found : false,
          mobile_parameters_sent : false,
          upload_name : "",
          focus_canvas: "",

          lineage_close : false,
          beta : false,
          user_settings : [],
          user_settings_got : false,
        }

     /*   getTimeFromStage = (stagegiven) => {
          var fullname = "Stage "+stagegiven;
          return this.state.sessionStages[fullname];
        }*/




        escFunction(event){
          if(event.keyCode === 27) {
            /////console.log("Escape");
          }
        }


        getUserSettingsValue = (p_setting) =>
        {
            let value = -1;
            if (this.state.user_settings !== undefined){
              this.state.user_settings.map(sett => {
              if (sett.setting === p_setting){
                value = sett.value;
              }
            });
          }
          ////console.log(value);
          return value;
        }

        
        componentDidMount(){
          this.state.connected_user_id = getUserID();
          this.state.token = getUserToken();
            const u_id = getUserID() ? getUserID() : 0;

            if (this.state.token !== "" && this.state.token !== null){
            axios.get(`/api/settings/`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    user_settings: res.data,
                });
                this.setState({user_settings_got : true});
                ////console.log(this.state.user_settings);
            }).catch(err => {this.setState({user_settings_got : true});});

            axios.get('/api/people-betatest/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({beta:res.data['beta']});
            });
            
        }else {
          this.setState({user_settings_got : true});
        }

          this.setState({api_mode:this.props.location.pathname.includes("morphoapi")});
          if (JSON.parse(u_id) !== 0){
            axios.get(`/api/people/${JSON.parse(u_id)}/`,{ headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({user:res.data});
                });       
              }                        
          if (!isOnStandalone()) window.addEventListener("progress",this.onProgressUnity,false);
          axios.get(`/api/unityinfos/`,{ params:{user_id:u_id} })
            .then(res => {
              ////console.log(res.data.infos);
                this.setState({codeUrl:"/"+res.data.infos.codeUrl});
                this.setState({loaderUrl:"/"+res.data.infos.loaderUrl});
                this.setState({frameworkUrl:"/"+res.data.infos.frameworkUrl});
                this.setState({dataUrl:"/"+res.data.infos.dataUrl});
                ////console.log(res.data.infos.unity_version);
            }).catch(err => {});
            if (!isOnStandalone()) window.addEventListener("message",this.receiveMessage,false);
            if (!isOnStandalone())  document.addEventListener("keydown", this.escFunction, false);

            if (!isOnStandalone()) this.explorer.current.addEventListener("change",this.handleFileUpload);
            if (!isOnStandalone()) this.explorer_colormap.current.addEventListener("change",this.handleColormapUpload);
        }

        handleColormapUpload = (event) => {const reader = new FileReader();
          this.setState({upload_name:event.target.files[0].name});
          reader.readAsText(event.target.files[0]);
          reader.onload = function(e) {
              ////console.log(reader.result);
              const info_name = this.state.upload_name.split('.')[0];
              const id_dataset = this.state.unityProps['id'];
              //  //console.log("infos = "+info_name);
             // //console.log("type = "+info_type);
            //  //console.log("datatype = "+datatype);
            //  //console.log("id_dataset = "+id_dataset);
////console.log("ip_people = "+id_people);

              axios({
                method: 'post',
                url: '/api/savecolormaptext/',
                data: {
                  name : info_name,
                  id_dataset : id_dataset,
                  content : reader.result,
                },
                headers :{ Authorization: `Token ${this.state.token}` }
              });

          }.bind(this);
        }
        handleFileUpload = (event) => {
          const reader = new FileReader();
          this.setState({upload_name:event.target.files[0].name});
          reader.readAsText(event.target.files[0]);
          reader.onload = function(e) {
              ////console.log(reader.result);
              const info_type = "2";
              const info_name = this.state.upload_name.split('.')[0];
              const id_people = this.state.connected_user_id;
              const id_dataset = this.state.unityProps['id'];
              let startIndex = 0;
              const lines = reader.result.split('\n');
              while (lines[startIndex][0] === '#'){
                startIndex++;
              }
              const datatype = lines[startIndex].split(':')[1];
              //  //console.log("infos = "+info_name);
             // //console.log("type = "+info_type);
            //  //console.log("datatype = "+datatype);
            //  //console.log("id_dataset = "+id_dataset);
////console.log("ip_people = "+id_people);

              axios({
                method: 'post',
                url: '/api/createcorrespondencesite/',
                data: {
                  infos : info_name,
                  type : info_type,
                  datatype: datatype,
                  id_dataset : id_dataset,
                  id_people : id_people,
                  r_field : reader.result,
                },
                headers :{ Authorization: `Token ${this.state.token}` }
              });

          }.bind(this);
        }

        onProgressUnity = (param) => {
          ////console.log("Progress : ");
        //  //console.log(param);
        }
        componentWillUnmount(){
          document.removeEventListener("keydown", this.escFunction, false);
        }

        receiveMessage = (event) => {
           if (event.data !== "" && this.state.done === true && this.state.api_mode)
             this.API_Load(event.data.toString());
         }

          //use the url data to start Unity
       API_Load = (param) => {
           let sent = "?";
           const splitted = param.split('&');
           for (let parameter in splitted){
            const splitted_par = splitted[parameter].split('=');
            sent = sent + splitted_par[0]+"="+splitted_par[1]+"&"
        }
       sent = sent.substring(0, sent.length - 1);
           const pairs = sent.substring(sent.indexOf('?') + 1).split('&');
           for (let i = 0; i < pairs.length; i++){
          const pair = pairs[i].split('=');
          if (pair[0] === "stage")sent=sent+"&time="+this.getTimeFromStage(pair[1]);
      }
      this.unityContent.send("SetsManager","API_Load",sent);
    }
        
        getTimeFromStage = (stage_given) => {
            const full_name = "Stage " + stage_given;
            return this.state.session_stages[full_name];
        }

        componentDidUpdate(prevProps,prevState){

          if (this.props.liveMode !== prevProps.liveMode){
            this.setState({live_mode:this.props.liveMode});
          }

          if (this.props.closeLineageFlag !== this.state.lineage_close && this.props.closeLineageFlag !== undefined){
            this.setState({lineage_close:this.props.closeLineageFlag});
            this.unityContent.send("SetsManager","forceLineageClose",0);
          }

          if (this.state.loaderUrl !== undefined && this.state.dataUrl !== undefined && this.state.frameworkUrl !== undefined && this.state.codeUrl !== undefined && !this.state.unity_infos_got && this.getUserSettingsValue("standalone_start") !== "true" && !isOnStandalone()){
                      //unity data to use
                      ////console.log("Path : "+this.state.unity_json_path);
                      ////console.log("Loader path : "+this.state.unity_js_loader_path );
 
                      this.unityContent = new UnityContext (
                        {
                        loaderUrl: this.state.loaderUrl,
                        dataUrl: this.state.dataUrl,
                        frameworkUrl: this.state.frameworkUrl,
                        codeUrl: this.state.codeUrl,
                        }       
                      );
/*
                      this.unityContent.on("error", function (message) {
                        //console.log("Error !!!");
                        //console.log(message);
                      });*/
                    

                     // this.receiveMessage = this.receiveMessage.bind(this);
                      //callback from unity
                      this.unityContent.on("RetrieveParameters", () => {
                       // //console.log("retrieve");
                        if (this.state.live_mode){
                          this.unityContent.send("LoadParameters","DisableInputKeyboard",1);
                            const obj = this.state.unityProps.obj;
                            const stl = this.state.unityProps.stl;
                            this.state.unityProps.obj = undefined;
                          this.state.unityProps.stl = undefined;
                          var sentparams = JSON.stringify(this.state.unityProps);
                          ////console.log(obj.length);
                          
                          if (obj !== null && obj !== {}){
                            for (let string in obj){
                            this.unityContent.send("LoadParameters","receiveOBJStringPart",obj[string]);
                          }
                          this.unityContent.send("LoadParameters","OBJ_received");
                          }else {
                            if (stl !== null && stl !== {} && stl !== undefined){
                              this.unityContent.send("LoadParameters","receiveSTL",ab2str(stl));
                            }
                          }
                          this.unityContent.send("LoadParameters","getParameters",sentparams);

                      }
                      else {
                        var sentparams = JSON.stringify(this.state.unityProps);
                            //console.log(sentparams);
                        this.unityContent.send("LoadParameters","getParameters",sentparams);
                        if (this.state.api_mode){
                          this.unityContent.send("LoadParameters","DisableInputKeyboard",1);
                        }
                      }
                      });

                      this.unityContent.on("RetrieveParametersLiveMode", () => {
                          const sentparams = JSON.stringify(this.state.unityProps);
                          this.unityContent.send("LoadParameters","getParameters",sentparams);
                      });
            
                      //message from external message
                      this.unityContent.on("LoadingReady", () => {
                        this.setState({loaded:true});
                        this.props.unityLoaded();
                      });

                      this.unityContent.on("selectColormap",() => {
                         this.explorer_colormap.current.click();
                      });
                      this.unityContent.on("selectFile", () => {
                       /* var fileuploader = document.getElementById('fileuploader');
                        if (!fileuploader) {
                            //console.log("Create element");
                            fileuploader = document.createElement('input');
                            fileuploader.setAttribute('style','display:none;');
                            fileuploader.setAttribute('type', 'file');
                            fileuploader.setAttribute('id', 'fileuploader');
                            fileuploader.setAttribute('class', 'focused');
                            document.getElementsByTagName('body')[0].appendChild(fileuploader);

                            fileuploader.onchange = function(e) {
                            var files = e.target.files;
                                for (var i = 0, f; f = files[i]; i++) {
                                    window.alert(URL.createObjectURL(f));
                                   // SendMessage('" + gameObject.name +@"', 'FileDialogResult', URL.createObjectURL(f));
                                }
                            };
                        }
                        if (fileuploader.getAttribute('class') === 'focused') {
                            fileuploader.setAttribute('class', '');
                            
                        }
                        fileuploader.click();*/
                       // var new_node = ReactDOM.findDOMNode(this.refs.explorer);
                       // //console.log(new_node);
                       // new_node.trigger();
                       ////console.log(this.explorer.current);
                       //this.explorer.current.click();
                    //  //console.log(this.explorer.current);
                        this.explorer.current.click();
                       //var inputElement = document.createElement("input");

                       // Set its type to file
                       //inputElement.type = "file";
                   
  
                       // set onchange event to call callback when user has selected file
                       //inputElement.addEventListener("change", () => {//console.log("test")})
                       
                       // dispatch a click event to open the file dialog
                       //inputElement.dispatchEvent(new MouseEvent("click")); 
                       
                       
                       ////console.log($("#explorer")[0]);
                      //$("#explorer")[0].click();
                       ////console.log(this.unity_div.current.children[0]);
                       //this.unity_div.current.children[0].trigger('click');
                       
                       //document.getElementById('explorer').click();
                       //this.button_explorer.current.click();
                        //this.button_explorer.current.onClick();
                        //$("#selectFile").click();
                      });
            
                      this.unityContent.on("OpenLineage", (i) => {
                        this.props.changeLineage(this.state.unityProps['id'],i,true);
                      });
            
                      this.unityContent.on("ReceiveMinTime", (min_time) => {
                          this.props.receiveMinTime(min_time);
                      });
                      this.unityContent.on("SendInfoToLineage", (infostring) => {
                        this.props.receiveInfoLineage(infostring);
                      });

                      this.unityContent.on("ReceiveMaxTime", (max_time) => {
                        this.props.receiveMaxTime(max_time);
                    });

                    this.unityContent.on("LeaveFullscreen", () => {
                      this.LeaveFullscreen();
                    });

                      this.unityContent.on("SendLineageMorphoPlot", (lineage) => {
                        this.props.storeMorphoPlotLineage(lineage);
                      });
                      this.unityContent.on("CloseLineage", () => {
                        this.props.changeLineage(this.state.unityProps['id'],false);
                        this.props.clearMorphoPlotLineage();
                      }) ;
            
                      this.unityContent.on("ClearAll", () =>{
                        this.props.clearAllLineage();
                      });
            
                      this.unityContent.on("Hide", (tid) =>{
                      //  var params = tid.split('/');
                        this.props.HideInLineage(tid);
                      });

                      this.unityContent.on("progress", (progression) =>{
                        this.props.onProgressUnity(progression);
                      });
            
                      this.unityContent.on("Show", (tid) =>{
                       // var params = tid.split('/');
                        this.props.ShowInLineage(tid);
                      });
            
                      this.unityContent.on("HideCellsAllTime", (vlistcells) =>{
                       // var params = vlistcells.split('/');
                        this.props.HideAllTimes(vlistcells);
                      });
            
                      this.unityContent.on("DatasetLoadFinished", () => {
                        this.setState({windowed_mode:false});
                      });
            
                      this.unityContent.on("Colorize",(tidrgb) => {
                        //var params = tidrgb.split('/');
                        this.props.Colorize(/*params[0],params[1],params[2],params[3],params[4]*/tidrgb);
                      });
            
                      this.unityContent.on("Uncolorize", (t,id) => {
                        this.props.Uncolorize(t+"/"+id);
                      });
            
                      this.unityContent.on("ApplySelection", (rgblistcells) => {
                        ////console.log(rgblistcells);
                       // var params = rgblistcells.split('/');
                        this.props.ApplySelection(rgblistcells);
                      });

                      this.unityContent.on("ApplySelectionMap", (rgblistcells) => {
                        ////console.log(rgblistcells);
                       // var params = rgblistcells.split('/');
                        this.props.ApplySelectionMap(rgblistcells);
                      });
            
                      this.unityContent.on("ResetSelectionOnSelectedCells",(listcells) => {
                        this.props.ResetSelectionOnSelectedCells(listcells);
                      });
            
                      this.unityContent.on("ReceiveColorInfos", (iddata) => {
                        this.props.receiveColorInfo(iddata);
                      });

                      
                      this.unityContent.on("HideCells",(vlistcells) => {
                        this.props.HideCells(vlistcells);
                      });
            
                      this.unityContent.on("PropagateThis", (vrgblistcells)=> {
                        //var params = vrgblistcells.split('/');
                        this.props.PropagateThis(vrgblistcells);
                      });

                      this.unityContent.on("UncolorizeMultiple", (listcells) => {
                        this.props.uncolorizeMultipleCells(listcells);
                      });

                      this.unityContent.on("ColorizeMultiple", (clistcells) => {
                        this.props.colorizeMultipleCells(clistcells);
                      });

                      this.unityContent.on("updateInfoDeployed", (info_id) => {
                        this.props.updateInfoDeployed(info_id);
                      });

                      this.unityContent.on("multipleSelection",(listcellscolor) => {
                        this.props.multipleSelection(listcellscolor);
                      });

                      this.unityContent.on("resetLineage",() => {
                       this.props.resetLineage();
                      });

                      this.unityContent.on("SendColors",(listcellscolor) => {
                        this.props.SendColors(listcellscolor);
                      });

                      this.unityContent.on("SendShowCells",(celllist) => {
                        this.props.SendShowCells(celllist);
                      });

                      this.unityContent.on("SendHideCells",(celllist) => {
                        this.props.SendHideCells(celllist);
                      });

                      this.unityContent.on("clearCells",(celllist) => {
                        this.props.clearCells(celllist);
                      });

                      this.unityContent.on("SendBackgroundColor",(color) => {
                        this.props.SendBackgroundColor(color);
                      });

                      this.unityContent.on("updateSelectionColor",(parames) => {
                        this.props.updateSelectionColor(parames);
                      });

                      this.unityContent.on("cancelPropagationListCells",(parames) => {
                        this.props.cancelCells(parames);
                      });

                      this.unityContent.on("pickCells",(parames) => {
                        this.props.pickCells(parames);
                      });
                      

                      
                      this.setState({unity_infos_got:true});
          }

          if ((this.props.lineageTimeCell !== undefined &&  this.props.lineageTimeCell !== "-1/false" && this.props.lineageTimeCell !== "-1/true" && this.props.lineageTimeCell !== this.state.lineage_time_cell) || (this.props.lineageIdCell !== undefined && this.props.lineageIdCell !== -1 && this.props.lineageIdCell !== this.state.lineage_id_cell)){
              const time = this.props.lineageTimeCell.split('/')[0];
              var id = this.props.lineageIdCell;
            this.setState({lineage_time_cell:this.props.lineageTimeCell});
            this.setState({lineage_id_cell:this.props.lineageIdCell});
            this.unityContent.send("SetsManager","lineage_showCell",time+","+id+",0");

          }

          if (this.props.keyboardState !== prevProps.keyboardState){
            this.unityContent.send("LoadParameters","DisableInputOnly",(this.props.keyboardState === true?0:1));
          }

          if (this.props.focusCanvas !== this.state.focus_canvas && !isOnStandalone() && this.getUserSettingsValue("standalone_start") !== "true"){
         //   //console.log("props focus changed");
            this.setState({focus_canvas:this.props.focusCanvas});
            if (!this.state.windowed_mode){
            //  //console.log("send focus");
              this.unityContent.send("LoadParameters","FocusCanvas",this.props.focusCanvas);
            }
          }
          if (this.state.unity_infos_got !== prevState.unity_infos_got && this.state.unity_infos_got === true){
            this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
          }

          if (this.props.sessionStages !== prevProps.sessionStages){
            this.setState({session_stages:this.props.sessionStages});
            this.setState({stages_got:true});
          }

          if (this.props.updatedDataset !== prevProps.updatedDataset){
            this.setState({updated_dataset:this.props.updatedDataset});
          }

          if (this.state.defaultsession_treated && this.state.stages_got && !this.state.stages_treated){
              const fullname = "Stage " + this.state.unityProps['stage'];
              ////console.log("fullname : "+fullname);
            if (this.state.session_stages[fullname] !== undefined && this.state.session_stages[fullname] !== null){
                this.state.unityProps['time'] = this.state.session_stages[fullname];
            }
            else if (this.state.session_stages[this.state.unityProps['stage']] !== null && this.state.session_stages[this.state.unityProps['stage']] !== undefined){
                this.state.unityProps['time'] = this.state.session_stages[this.state.unityProps['stage']];
            }
            else if (this.state.session_stages[fullname.toLowerCase()] !== undefined && this.session_stages.stages[fullname.toLowerCase()] !== null){
                this.state.unityProps['time'] = this.state.session_stages[fullname.toLowerCase()];
            }
            ////console.log("stages treated");
            this.setState({stages_treated:true});
        }

          if (this.props.windowed_mode !== prevProps.windowed_mode && !isOnStandalone() && this.getUserSettingsValue("standalone_start") !== "true"){
            this.setState({windowed_mode:this.props.windowed_mode});
            if (this.props.windowed_mode === true){
              this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
            }
            else {
              if (this.props.active || this.state.api_mode){
                this.unityContent.send("LoadParameters","DisableInputKeyboard",1);
              }
            }
          }

          if (this.props.active !== prevProps.active && !this.state.live_mode && !isOnStandalone() && this.getUserSettingsValue("standalone_start") !== "true"){
              if (this.state.windowed_mode === true || !this.props.active && !this.state.api_mode){
              this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
            }
            else{
              this.unityContent.send("LoadParameters","DisableInputKeyboard",1);
            }
          }

          if (this.props.active !== prevProps.active){
            this.setState({active:this.props.active});
            if (!this.props.active){
              this.setState({previously_windowed:this.state.windowed_mode});
              this.setState({windowed_mode:false});
            }
            else {
              if (this.state.previously_windowed && !this.state.updated_dataset){
                  this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
                  this.setState({windowed_mode:this.state.previously_windowed});
              }else{
                this.setState({updated_dataset:false});
                this.props.ResetUpdatedDataset();
              }
            }
          }


          if (this.props.sendReset !== prevProps.sendReset){
            this.setState({right_found:false});
            this.setState({done:false});
            this.setState({unityProps:undefined});
            this.setState({defaultsession_treated:false});
            this.setState({mobile_parameters_sent:false});
            this.setState({send_reset:this.props.sendReset});
            if (this.props.sendReset === true && !isOnStandalone()  && this.getUserSettingsValue("standalone_start") !== "true"){
              this.unityContent.send("LoadParameters","ResetFromWebsite");
              this.props.resetUnityState();
            }
          }

          if (this.props.clearCache !== prevProps.clearCache){
            this.setState({done:false});
            this.setState({unityProps:undefined});
            this.setState({send_reset:this.props.clearCache});
            this.setState({right_found:false});
            this.setState({defaultsession_treated:false});
            if (this.props.clearCache && !isOnStandalone()  && this.getUserSettingsValue("standalone_start") !== "true"){
              this.unityContent.send("LoadParameters","ResetFromWebsiteAndCache");
              this.props.resetUnityState();
            }
          }

          if (this.props.myCustomProps !== this.state.unityProps && this.props.myCustomProps !== undefined && this.props.myCustomProps !== {} && ((this.props.myCustomProps.obj !== undefined && this.props.myCustomProps.obj !== {}) || (this.props.myCustomProps.stl !== undefined && this.props.myCustomProps !== {})) && this.state.live_mode && this.state.loaded){
         //   //console.log("Live data");
              Object.assign(this.state.unityProps,this.props.myCustomProps);
            this.unityContent.send("LoadParameters","ReceiveLiveModeProps");


          }

          if (this.props.myCustomProps !== prevProps.myCustomProps && this.props.myCustomProps !== undefined && !this.state.defaultsession_treated && !this.state.live_mode){
              //console.log(this.props.myCustomProps);
              this.state.unityProps  = Object.assign({},this.props.myCustomProps);

            if (getCookie('rpgd_track') !== undefined || getCookie('rpgd_track') === "false") {
              this.state.unityProps['rgpd'] = getCookie('rpgd_track');
            }
            this.state.unityProps['u_ID'] = "0";

            if (this.state.unityProps.id !== 0 && this.state.unityProps.id !== "0" && !this.state.api_mode){
              if (this.state.user !== undefined){

              this.state.unityProps['u_name'] = this.state.user.name;
              this.state.unityProps['u_surname'] = this.state.user.surname;
              this.state.unityProps['u_right'] = this.state.user_right;
            }
            else {
              this.state.unityProps['u_name'] = 'public';
              this.state.unityProps['u_surname'] = 'access';
              this.state.unityProps['u_right'] = "3";
            }

            if (this.state.connected_user_id !== -1 && this.state.connected_user_id !== null){
                 this.state.unityProps['u_ID'] = this.state.connected_user_id.toString();
            }
            }
            
            if (this.state.api_mode){
              this.state.unityProps['u_ID'] = "0";
              this.state.unityProps['u_name'] = 'public';
              this.state.unityProps['u_surname'] = 'access';
              this.state.unityProps['u_right'] = "3";
              this.setState({right_found : true});
            }
            ////console.log("loaded session : ");
            ////console.log(this.state.unityProps['loaded_session']);
            //get defaut session url 
            axios.get(`/api/firstdefautsession/`,{ params :{id_dataset:this.state.unityProps.id},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                ////console.log(res.data);
                if (res.data !== "failed" && this.state.unityProps['loaded_session'] === undefined || (this.state.unityProps['loaded_session'] !== true && this.state.unityProps['loaded_session'] !== "true")){
                    const params = res.data.split("&");
                    params.map(param => {
                      const value = param.split("=");
                      if (value[0] === "id_dataset")
                  {
                    value[0] = "id"
                  }
                  if (value[0] === "minTime")
                  {
                    value[0] = "mintime"
                  }
                  if (value[0] === "maxTime")
                  {
                    value[0] = "maxtime"
                  }
                  
                   this.state.unityProps[value[0]] = value[1];
                    ////console.log(value[0] +" - > "+value[1]);
                });
              }
                //useSelector(state => state.dataset.current_quality_by_id[props.item.id]);

              if (this.props.current_quality_by_id[this.state.unityProps.id] !== undefined){
                this.state.unityProps['quality'] = this.props.current_quality_by_id[this.state.unityProps.id];
              }

              if (this.props.overwrote_times[this.state.unityProps.id] !== undefined){
                  //console.log("overwrote");
                  //console.log(this.props.overwrote_times[this.state.unityProps.id]);
                this.state.unityProps['mintime'] = parseInt(this.props.overwrote_times[this.state.unityProps.id][0]);
                this.state.unityProps['maxtime'] = parseInt(this.props.overwrote_times[this.state.unityProps.id][1]);
              }
            
              if (this.state.unityProps['time'] < this.state.unityProps['mintime']){
                this.state.unityProps['time'] = this.state.unityProps['mintime'];
              }

              if (this.state.unityProps['time'] > this.state.unityProps['maxtime']){
                this.state.unityProps['time'] = this.state.unityProps['maxtime'];
              }
                //console.log(this.state.unityProps);
                this.setState({defaultsession_treated:true});
            }); 
            
            if (this.state.api_mode && !isOnStandalone()){
            this.unityContent.send("LoadParameters","DisableInputKeyboard",1);}
          }

          if (this.state.done !== prevState.done && this.state.api_mode) {
            this.API_Load(this.props.location.search);
        }


        if ( (isOnStandalone() || this.getUserSettingsValue("standalone_start") === "true") && (getUserID() === 54 || getUserID() === "54" || getUserID() === "1" || getUserID() === 1 || this.state.beta) && this.state.right_found && !this.state.mobile_parameters_sent && this.state.defaultsession_treated && (!this.state.api_mode || (this.state.api_mode && this.state.stages_treated))){
          //store the string in the database by IP
          //this.state.unityProps['u_ID'] = 0;
          this.state.unityProps["comments"] = "";
          this.state.unityProps["categorie"] = "";
          this.state.unityProps["name"] = this.state.unityProps["name"].replace(',',';');
          this.state.unityProps["u_token"] = getUserToken()?getUserToken():"null";
            const today = new Date();
            //var timezone = today.getTimezoneOffset() * 60 * 1000;
          //date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+ '|'+ today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
          this.state.unityProps["date"] = today.getTime() /*- timezone*/;
         // //console.log(this.state.unityProps);
          this.setState({mobile_parameters_sent:true});
        }

          if (this.state.loaded  &&  this.state.active &&  this.state.right_found && !this.state.done && this.state.defaultsession_treated && (!this.state.api_mode || (this.state.api_mode && this.state.stages_treated))  && !isOnStandalone() && this.getUserSettingsValue("standalone_start") !== "true"){
            //console.log("Sending props signal");
            //console.log(this.state.unityProps);
            if (this.state.api_mode){
              if (this.state.stages_treated && this.state.unityProps['stage'] !== undefined){
                this.state.unityProps['time']=this.getTimeFromStage(this.state.unityProps['stage']);
              }

              if (this.state.unityProps['api_maxtime'] !== null && this.state.unityProps["api_maxtime"] !== undefined){
                this.state.unityProps['maxtime'] = this.state.unityProps['api_maxtime'];
              }

              if (this.state.unityProps['api_mintime'] !== null && this.state.unityProps["api_maxtime"] !== undefined){
                this.state.unityProps['mintime'] = this.state.unityProps['api_mintime'];
              }

                          
              if (this.state.unityProps['time'] < this.state.unityProps['mintime']){
                this.state.unityProps['time'] = this.state.unityProps['mintime'];
              }

              if (this.state.unityProps['time'] > this.state.unityProps['maxtime']){
                this.state.unityProps['time'] = this.state.unityProps['maxtime'];
              }
            }
            //console.log(this.state.unityProps);

            this.state.unityProps['u_token'] = getUserToken()?getUserToken():"null";
           /* if (!this.state.api_mode)
              this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
            else 
              this.unityContent.send("LoadParameters","DisableInputKeyboard",1);*/

            if (this.state.unityProps['id'] === 0 || this.state.unityProps['id'] === '0'){
              this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
            }
            
            this.unityContent.send("LoadParameters","ReceiveProps");
            this.setState({done:true});
          }

          if (this.state.loaded !== prevState.loaded){
            this.props.unityLoadedFeedback(this.state.loaded);
            this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
          }

          if (this.props.myCustomProps !== prevProps.myCustomProps && this.props.myCustomProps !== undefined && !this.state.live_mode && !this.state.api_mode){
            if (this.props.myCustomProps.id > 0) {
               // //console.log("user id = "+this.state.connected_user_id);
              axios.get('/api/right-dataset/',{params:{id_people:getUserID()?getUserID():0,id_dataset:this.props.myCustomProps.id}})
            .then(res => {
               this.state.unityProps['u_right'] = res.data;
              // //console.log("Right founds = "+res.data);
                this.setState({user_right:res.data});
                this.setState({right_found : true});
            }).catch(err => {this.setState({right_found : true});this.state.unityProps['u_right']="3"});

          }
          else {
            this.setState({user_right:1});
            this.setState({right_found : true});
          }
        }

          if (this.props.sessionStages !== prevProps.sessionStages){
            this.state.sessionStages = this.props.sessionStages;
            if (this.props.sessionMode === undefined){
              this.state.unityProps['quality'] = this.props.setquality > 0 ? this.props.setquality:1;
            }
          }
        }

        LeaveFullscreen = () => {
         // //console.log("Leave fullscreen");
          this.setState({fullscreen:false});
          if (this.state.windowed_mode){
            this.unityContent.send("LoadParameters","DisableInputKeyboard",0);
          }
        }

        EnterFullscreen = () => {
          this.setState({fullscreen:true});
          this.unityContent.send("LoadParameters","DisableInputKeyboard",1);
          this.unityContent.setFullscreen(true);
        }

        manageViewerClose = () =>{
          this.props.showUnity();
        }

        render() {
          //#f5f5f5
            return  (
            !isOnStandalone() ? 
            <div ref={this.unity_div} style={{zIndex:'10',right:this.state.windowed_mode && !this.state.api_mode?'1.5%':'unset',bottom:this.state.windowed_mode && !this.state.api_mode?'0':'unset',position:'fixed',marginBottom:this.state.active && !this.state.windowed_mode?'3%':this.state.api_mode?'0':'1.5%',marginTop:'0%',marginLeft:this.state.active?'0%':'0%',align:'center',width: this.state.windowed_mode && !this.state.api_mode ?'300px':this.state.active && !this.state.api_mode?'97.5%':this.state.api_mode && this.props.location.search !== ""?'100%':'1px', height: this.state.windowed_mode && !this.state.api_mode?'300px':this.state.active && !this.state.api_mode?'90%':this.state.api_mode && this.props.location.search !== ""?'100%':'1px'}}> 
           
                  
              {
                            !this.state.loaded && this.state.api_mode && this.props.location.search !== "" && 
                            <h1 style={{fontWeight:'bold',fontSize:'21px'}}>3D viewer is loading ...</h1>
                  }
              {
                !this.state.api_mode &&
                <div style={{backgroundColor:'white',height:this.state.windowed_mode?'19px':this.state.active?'19px':'19px'}}>
                  <Tooltip placement="bottom" title={<span>Close viewer</span>}>
                      <img alt="Viewer" onClick={() => this.props.showUnity(!this.state.active)} height={this.state.windowed_mode?'12px':this.state.active?'12px':'0px'}  src="/images/refonte/window_close.png" style={{verticalAlign:'top',marginTop:'4px',marginLeft:'3px',cursor:'pointer',mixBlendMode:'multiply'}}></img>
                    </Tooltip>
                    {
                    this.state.windowed_mode === false ?
                    <Tooltip placement="bottom" title={<span>Minimize viewer</span>}>
                      <img alt="Viewer" onClick={() => this.props.sendWindowedMode()} height={this.state.windowed_mode?'12px':this.state.active?'12px':'0px'}  src="/images/refonte/window_minimize.png" style={{verticalAlign:'top',marginTop:'4px',marginLeft:'3px',cursor:'pointer',mixBlendMode:'multiply'}}></img>
                    </Tooltip>
                    :
                    <Tooltip placement="bottom" title={<span>Maximize viewer</span>}>
                      <img alt="Viewer"  onClick={() => this.props.sendWindowedMode()} height={this.state.windowed_mode?'12px':this.state.active?'12px':'0px'}  src="/images/refonte/window_increase.png" style={{verticalAlign:'top',marginTop:'4px',marginLeft:'3px',cursor:'pointer',mixBlendMode:'multiply'}}></img>
                    </Tooltip>
                    }
                    <Tooltip placement="bottom" title={<span>Go in fullscreen mode</span>}>
                      <img alt="Viewer" onClick={this.EnterFullscreen} height={this.state.windowed_mode?'12px':this.state.active?'12px':'0px'}  src="/images/refonte/window_fullscreen.png" style={{verticalAlign:'top',marginTop:'4px',marginLeft:'3px',cursor:'pointer',mixBlendMode:'multiply'}}></img>
                    </Tooltip>
                    <input type='file' ref={this.explorer} style={{display:'none'}}></input>
                    <input type='file' ref={this.explorer_colormap} style={{display:'none'}}></input>
                </div>
              }
              {
                this.unityContent !== undefined &&
                <Unity style={{zIndex:'10',right:this.state.windowed_mode && !this.state.api_mode?'1.5%':'unset',bottom:this.state.windowed_mode && !this.state.api_mode?'0':'unset',position:'fixed',marginBottom:this.state.active && !this.state.windowed_mode?'3%':this.state.api_mode?'0':'1.5%',marginTop:'0%',marginLeft:this.state.active?'0%':'0%',align:'center',width: this.state.windowed_mode && !this.state.api_mode ?'300px':this.state.active && !this.state.api_mode?'97.5%':this.state.api_mode && this.props.location.search !== ""?'100%':'1px', height: this.state.windowed_mode && !this.state.api_mode?'281px':this.state.active && !this.state.api_mode?'90%':this.state.api_mode && this.props.location.search !== ""?'100%':'1px'}} unityContext={this.unityContent} />  
              }
            </div>
            :
            <div></div>);
        }
}

const mapStateToProps = (state) => {
    return {
        overwrote_times: state.dataset.overwrote_time_by_id,
        current_quality_by_id : state.dataset.current_quality_by_id,
    }
}
//bind logout to global logout system
const mapDispatchToProps = dispatch => {
return {
  logout: () => dispatch(actions.logout()),
    unityLoaded : () => dispatch(UnityIsLoaded()),
}
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UnityContainer));