import React from "react";
import InfosListView from "./infosListContainer";
import {ChooseSetFromUrl} from "../utils/api_callbacks";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {IsAdminOrCreator, IsAtLeastManager, IsAtLeastReader} from "../utils/tools";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";
import {SetRedirectedUrl} from "../store/reducers/userSlice";


class InfosListDummy extends React.Component {
    state = {
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    handleClickNewInfos = () => {
        this.props.SetRedirectedUrl("/dataset/" + this.props.chosen_set + "/properties/create/")
    }

    render() {

        const chosen_dataset = this.props.datasets[parseInt(this.props.chosen_set)]
        return (
            <div>
                <ChosenSetTopPanel {...this.props} set={chosen_dataset}></ChosenSetTopPanel>

                <br></br>
                {chosen_dataset!==undefined &&<p className={"DatasetDescriptionName"}>{chosen_dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${chosen_dataset.id})` : ""}</p>}
                <div style={{marginTop: "1%"}}>
                        <span style={{fontSize: '21px', color: 'black'}}>Properties</span>
                    <br/><br/>
                    {
                        (this.props.connected && this.props.chosen_set !== undefined && IsAtLeastManager(this.props.chosen_set)) &&
                        <div>
                            <span style={{color: '#44D4FF', fontSize: '20px'}}>Create a property</span>
                            <img alt="Create property " style={{cursor: 'pointer', marginLeft: "10px"}} onClick={() => {
                                this.handleClickNewInfos()
                            }} width={30} src="/images/refonte/add.png"></img>
                        </div>
                    }
                    {
                        this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                        <div style={{marginTop: "1%"}}><InfosListView filtertype={"even"}
                                                                      sorttype={"reversedname"}></InfosListView></div>
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset.entities,
        rights_by_dataset_id: state.user.rights_by_dataset_id,
        connected: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InfosListDummy)