import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import axios from 'axios';
import { notification  } from 'antd';
import {
    isMobile,
    isIOS,
    isEdge,
    isAndroid,
    isTablet,
    isFirefox,
    isChrome,
  } from "react-device-detect";
  
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
//used in development
export const DJANGO_PORT = 58499;

export const isOnStandalone = () => {
    return (isTablet || isMobile ||  isIOS || isAndroid || (!isFirefox && !isEdge && !isChrome));
}
//Clone object and update correspondinf properties : ... = clone 
export const updateObject = (oldObject,updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

export const setCookie = (name,value,parameters) => {
    //console.log(parameters);
    const cryptValue = encryptString(value);
    Cookies.set(name,cryptValue,parameters);
}

export const postNotification = (title,message) => {
    notification.open({
        message: title,
        description:
        message,
        onClick: () => {

        },
      });
}
export const removeCookie = (name) => {
    Cookies.remove(name);
}

export const getCookie = (name) => {
    if (Cookies.get(name) === undefined){
        return null;
    }
    //console.log(value);
    return decryptString(Cookies.get(name));
}

export const isUserStaff2 = () => {
    const token = getUserToken();
    return axios.get('/api/people-admin/', { headers: { Authorization: `Token ${token}` } })
    .then(res => {
         return res.data['staff'];
    });
}

export const isUserStaff = () => {
    if (getCookie('right') !== undefined)
        return getCookie('right') === "true";
    return false;
}

export const getUserToken = () => {
    if (getCookie('token') !== undefined)
        return getCookie('token');
    return null;
}

export const getUserID = () => {
    if (getCookie('user') !== undefined)
        return getCookie('user');
    return null;
}

export const encryptString = message => {
    return CryptoJS.AES.encrypt(message,'ncodsubnvojsfvsdv542154ds').toString();
  }
  
export const decryptString = message => {
    const bytes = CryptoJS.AES.decrypt(message, 'ncodsubnvojsfvsdv542154ds');
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  