import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import {SetPhyloTree} from "../store/reducers/datasetSlice";

const dispatchEventTreeLoaded = () => {
    window.dispatchEvent(new Event("updateTree"));
};

function createTree(tree, id_ncbi, n) {
    if (tree.has(id_ncbi) || id_ncbi === 1) {
        const node = tree.get(id_ncbi);
        const childs = [];
        for (let [key, value] of tree.entries()) {
            if (value !== undefined && value !== null && value.id_parent === id_ncbi && key !== id_ncbi) {
                childs.push(value);
            }
        }

        if (childs.length === 0 && id_ncbi !== 1) {
            return node.name + ":" + n;
        }

        if (childs.length === 1) {
            return createTree(tree, childs[0].id, n++);
        }

        if (childs.length > 1) {
            let result = "(";
            let ll = 0;
            childs.map(child => {
                result = result + createTree(tree, child.id, 1);
                if (ll < childs.length - 1) {
                    result = result + ",";
                    ll++;
                }
            });
            result = result + '):' + n;
            return result;
        }
    }
}

class PhyloTreeLoader extends React.Component {
    state = {
        current_set_list: [],
    }


    BuildPhyloTree = () => {
        const tree = new Map();
        let morpho_tree = "";
        //CreatePhylotree
        const datasets = Object.values(this.props.datasets);
        if (datasets.length > 0) {
            //console.log("Detected sets change");
            const id_list = [];

            //create node
            axios({
                    method: 'get',
                    url: `/api/phyloparentnode/`,
                    params: {id_ncbi: 1},
                }
            ).then(res => {
                let initnode = res.data[0];
                tree.set(1, initnode);
                let node = {};
                datasets.map(async set => {
                    const ncbi_id = set.id_ncbi;
                    if (ncbi_id === 0 || ncbi_id === "0") {
                        if (!tree.has(ncbi_id)) {

                            node.id = ncbi_id;
                            node.id_parent = 1;
                            node.name = "Unclassified";
                        }
                    } else {
                        //add all the ids to a list
                        if (!id_list.includes(ncbi_id)) {
                            id_list.push(ncbi_id);
                        }
                    }
                });
                //Get tree for all the ids
                axios({
                        method: 'get',
                        url: `/api/phyloparenttree/`,
                        params: {id_ncbi_list: JSON.stringify(id_list)},
                    }
                ).then(res => {
                    res.data.map(item => {
                        tree.set(item.id, item);
                        morpho_tree = createTree(tree, 1, 1);

                    });
                    tree.set(0, node);
                    morpho_tree = createTree(tree, 1, 1);
                }).then(res => {
                    this.props.SetPhyloTree(morpho_tree);
                    dispatchEventTreeLoaded();
                })

                //GET SESSIONS list
            });
        }
    }

    componentDidMount() {
        window.addEventListener('storage', () => {
            setTimeout(
                this.BuildPhyloTree, 500);


        })
    }


    render() {
        return ("");
    }

}

const mapStateToProps = (state) => {
    return {
        datasets: state.dataset.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetPhyloTree: (tree) => dispatch(SetPhyloTree(tree)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhyloTreeLoader);