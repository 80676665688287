import {connect} from "react-redux";
import React from "react";
import InfoInstance from "./infosInstanceContainer";
import {GetSetInUrl, IsAtLeastReader} from "../utils/tools";
import {ChooseADataset} from "../utils/api_callbacks";


function ApplyFiltering(data, filtertype, chosen_set) {
    if (filtertype === "choosenset") {
        return data.filter(key => key.id_dataset === chosen_set);
    }

    return data;
}

function ApplySorting(data, sorttype) {
    if (sorttype === "reversedname") {
        return data.sort((a, b) => a.infos.localeCompare(b.infos));
    }

    return data;
}


class InfosListView extends React.Component {

    state = {}

    componentDidMount() {
        const set = GetSetInUrl(window.location.pathname);
        ChooseADataset(set);
    }

    render() {
        const infos = ApplySorting(Object.values(this.props.infos).filter(key => key.id_dataset === this.props.chosen_set), this.props.sorttype);
        return <div>
            {this.props.connected && IsAtLeastReader(this.props.chosen_set) && infos.map(info => <InfoInstance
                key={"info_" + info.id} info={info}></InfoInstance>)}
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        infos: state.infos.entities,
        datasets: state.dataset.entities,
        user_is_admin: state.user.is_staff,
        connected: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(InfosListView);