import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {
    dataset_by_proprity: {}
}

const priorityAdapter = createEntityAdapter({
    // Keep the "all IDs" array sorted based on book titles
})

const prioritySlice = createSlice({
    name: 'priority', initialState: priorityAdapter.getInitialState(initialState), reducers: {
        // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))
        priorityListAdded: priorityAdapter.addMany,
        LoadMultiplePrioritiesDataset: (state, param) => {
            const {payload} = param;
            payload.map(tagset => {
                const set_id = tagset[0]
                const prio = tagset[1]
                state.dataset_by_proprity[set_id] = parseInt(prio.value);
                return tagset;
            });
        }
    },
})

export const {priorityListAdded, LoadMultiplePrioritiesDataset} = prioritySlice.actions
// ??
export default prioritySlice.reducer