import React from "react";
import {connect} from "react-redux";
import {
    AddTagInFilters,
    RemoveTagInFilters,
    SetOnlyFavourite,
    SetOnlyOthers,
    SetOnlyUser
} from "../store/reducers/datasetSlice";


class DatasetAdvancedFilter extends React.Component {

    render() {
        const checked_fav = this.props.dataset.current_only_favourites;
        const checked_user = this.props.dataset.current_only_user;
        const checked_others = this.props.dataset.current_only_others;
        return <div>
            <h3 style={{fontSize:"20px",marginLeft:"5px",marginTop:"10px",textAlign:"unset"}}> Advanced filters</h3>
            { /* Filter favourites only */}
            <div key={"favourite"}>       <input
                style={{marginLeft:"5px",verticalAlign:"unset"}}
                onChange={() => {
                    this.props.SetOnlyFavourite();
                }}
                checked={checked_fav}
                type="checkbox"></input><label style={{fontSize:'18px'}}> Favorites </label><img
                alt={"FilterByFavourites"} width='20' style={{paddingBottom:"5px",marginLeft:"5px"}}  src={'/images/refonte/star_on_3.png'}></img></div>
            { /* Filter my datasets only */}
            <div key={"user"}>       <input
                style={{marginLeft:"5px",verticalAlign:"unset"}}
                onChange={() => {
                    this.props.SetOnlyUser();
                }}
                checked={checked_user}
                type="checkbox"></input><label style={{fontSize:'18px'}}> My datasets </label><img
                alt={"FilterByUserOwnership"} width='20' style={{paddingBottom:"5px",marginLeft:"5px"}}  src={'/images/refonte/man-user.png'}></img></div>
            { /* Filter others datasets only */}
            <div key={"others"}>       <input
                style={{marginLeft:"5px",verticalAlign:"unset"}}
                onChange={() => {
                    this.props.SetOnlyOthers();
                }}
                checked={checked_others}
                type="checkbox"></input><label style={{fontSize:'18px'}}> Others datasets </label><img
                alt={"FilterByUserOwnership"} width='20' style={{paddingBottom:"5px",marginLeft:"5px"}}  src={'/images/refonte/users-group_2.png'}></img></div>
            <br></br>
        </div>

    }
}

// bind components props to specific parts of the store
// ex : is_connected : state.user.is_connected
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
    }
}

// bind components props to specific actions that will update the store
// ex : function_name : (data) => dispatch(function_in_slice()),
const mapDispatchToProps = dispatch => {
    return {
        SetOnlyFavourite : () => dispatch(SetOnlyFavourite()),
        SetOnlyUser : () => dispatch(SetOnlyUser()),
        SetOnlyOthers : () => dispatch(SetOnlyOthers()),
        AddTagInFilters: (tag_id) => dispatch(AddTagInFilters(tag_id)),
        RemoveTagInFilters: (tag_id) => dispatch(RemoveTagInFilters(tag_id)),
    }
}

// connect bind the 2 functions above to the component
export default connect(mapStateToProps, mapDispatchToProps)(DatasetAdvancedFilter);