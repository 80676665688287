import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {
    infos_by_set: {}, owner_by_info: {},
}

const infosAdapter = createEntityAdapter({
    // Keep the "all IDs" array sorted based on book titles
})

const infosSlice = createSlice({
    name: 'infos', initialState: infosAdapter.getInitialState(initialState), reducers: {
        // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))
        infosAdded: infosAdapter.addOne, // dispatch(datasetReceived({ id: '', ... }))
        infosListAdded: infosAdapter.addMany, //dispatch(datasetUpdated({ id: '', changes: {... } }))
        infosUpdated: infosAdapter.updateOne,
        infosListUpdated: infosAdapter.setAll,
        LoadMultipleInfosSets: (state, param) => {
            const {payload} = param;
            payload.map(tags => {
                const info = tags[0]
                const set_id = tags[1]
                if (!(set_id in state.infos_by_set)) {
                    state.infos_by_set[set_id] = []
                }
                state.infos_by_set[set_id].push(info);
                return tags;
            });
        },
        LoadMultipleInfosOwners: (state, param) => {
            const {payload} = param;
            for (const [key, value] of Object.entries(payload)) {
                state.owner_by_info[key] = value;
            }

        },
        LoadInfosSet: (state, param) => {
            const {payload} = param;
            const info = payload[0]
            const set = payload[1]
            if (!(set in state.infos_by_set)) {
                state.infos_by_set[set] = []
            }
            state.infos_by_set[set].push(info);
        },
        ClearInfos: (state,param) => {
            state.infos_by_set= {}
        }
    },
})

export const {ClearInfos,LoadMultipleInfosOwners, infosAdded, infosListAdded, infosListUpdated, infosUpdated} = infosSlice.actions
// ??
export default infosSlice.reducer