import React from 'react';
import axios from 'axios';
import { List,Input,Button,Radio   } from 'antd';
import { SketchPicker } from 'react-color';
import {getUserToken,getUserID} from '../store/utility';
import {ReLoadUserTags} from "../utils/api_callbacks";
const { Search } = Input;



const status_options = [
    { label: 'On', value: 'Public' },
    { label: 'Off', value: 'Private' },
  ];

  const share_options = [
    { label: 'Shared', value: 'Shared' },
    { label: 'Unshared', value: 'Unshared' },
  ];

class TagManagement extends React.Component {
    //contains all the data needed for page
    state = {
        //all user managed by this user
        groups : [],
        sharing_by_tags : [],
        user_tags : [],
        deployed_tags : [],
        new_names : [],
        new_publics : [],
        new_comments : [],
        new_colors : [],
        staff : false,
        token : "",
        connected_user_id : -1,
        searched_value : "",

        new_name : "Default name",
        new_comment : "Default comment",
        new_public : false,
        new_color : "#F0F8FF",
        
    }

    //called once before rendering( when the component is created in the web page)
    componentDidMount() {

        //store connected user data
        this.setState({token : getUserToken()});
        this.state.connected_user_id = getUserID();

        //as soon as we get user, we can ask server for his tags
        axios.get('/api/usertags/', { headers: { Authorization: `Token ${getUserToken()}` } })
        .then(res => {
            this.setState({user_tags:res.data});
            //for each of his tags, ask server for the tag sharing list for update
            res.data.map(tag => {
                axios.get('/api/sharingbytag/', { params:{tag_id:tag['id']},headers: { Authorization: `Token ${getUserToken()}` } })
                .then(resb =>{
                    this.state.sharing_by_tags[tag.id] = resb.data['result'];
                });
            })
        });
    }

    //call this function after every server update, to maintain the tag list up to date
    refreshTagList = () => {
        axios.get('/api/usertags/', { headers: { Authorization: `Token ${getUserToken()}` } })
        .then(res => {
            this.setState({user_tags:res.data});
            ReLoadUserTags();
            res.data.map(tag => {
                axios.get('/api/sharingbytag/', { params:{tag_id:tag['id']},headers: { Authorization: `Token ${getUserToken()}` } })
                .then(resb =>{
                    this.state.sharing_by_tags[tag.id] = resb.data['result'];
                });
            })
        });
    }

    //is called every frame, parameters are the previous page datas , so you can compare it with the new ones prevProps <> this.props and prevState <> this.state
    componentDidUpdate(prevProps, prevState){
        //As soon as user is ocnnected and found get his group
        if (this.state.token !== undefined && this.state.token !== "" && this.state.token !== prevState.token){
            axios.get('/api/group/',{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                groups: res.data
                });

            })
            }
    }

    //send new tag data to server
    updateTag = (item) => {
        //if we updated tag name, use it , if not , use original one
        const new_name = this.state.new_names[item.id] !== undefined ? this.state.new_names[item.id] : item.name;
        const new_comments = this.state.new_comments[item.id] !== undefined ? this.state.new_comments[item.id] : item.comment;
        const new_public = this.state.new_publics[item.id] !== undefined ? this.state.new_publics[item.id] : item.public;
        const new_color = this.state.new_colors[item.id] !== undefined ? this.state.new_colors[item.id] : item.color;
        axios.get('/api/updateatag/', { params:{tag_color:new_color,tag_id:item.id,name:new_name,comment:new_comments,public:new_public},headers: { Authorization: `Token ${getUserToken()}` } }).then(res => {this.refreshTagList();});
    }

    //send tag data to server to create one
    createTag = () => {
        axios.get('/api/createatag/', { params:{tag_color:this.state.new_color,name:this.state.new_name,comment:this.state.new_comment,public:this.state.new_public},headers: { Authorization: `Token ${getUserToken()}` } }).then(res => {this.refreshTagList();
        this.state.new_name = "Default name";
        this.state.new_color = "#F0F8FF";
        this.state.new_comment = "Default comment";
        this.state.new_public = false;});
        
    }

    //on search inputfield, store the string
    onSearch = (value) => {
        this.setState({searched_value:value.target.value});
    }

    //called every time page state has changed, re render everything
    render() {
        return (
            <div>
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px'}}> Tag Management </h1>
                <br></br>

                <Search style={{float:'right',width:'30%'}}placeholder="input search text" onChange={this.onSearch} enterButton />
                <span style={{float:'right',marginRight:'2%',fontSize:18,fontWeight:'bold'}}>Filter tags : </span>
                {
                    this.state.user_tags.filter(tag =>{ return tag === undefined || this.state.searched_value === "" || this.state.searched_value === undefined || tag['name'].toLowerCase().includes(this.state.searched_value.toLowerCase());}).length > 0 &&
                    <div style={{marginLeft:'10%',marginTop:'3%'}}>



                    <br></br><br></br>
                    {/* Tag list */}
                        <List
                            style={{width:'30%'}}
                            itemLayout="horizontal"
                            dataSource={this.state.user_tags.filter(tag =>{return tag === undefined || this.state.searched_value === "" || this.state.searched_value === undefined || tag['name'].toLowerCase().includes(this.state.searched_value.toLowerCase());})}
                            renderItem={item => (
                            <div><List.Item

                            >
                                <List.Item.Meta
                                title={
                                    <div><span style={{fontSize:18,fontWeight:'bold'}}>{item.name}</span>
                                    {
                                    this.state.deployed_tags.includes(item.id)?
                                    <Button style={{marginLeft:'10px',/*float:'right'*/}} shape="circle" onClick={() => {
                                        const index = this.state.deployed_tags.indexOf(item.id);
                                        
                                        if (index > -1) {
                                            const nextFavorites = [...this.state.deployed_tags];
                                            nextFavorites.splice(index, 1);
                                            this.setState({ deployed_tags: nextFavorites });
                                        }
                                    }} > ^ </Button>
                                    :
                                    <Button style={{marginLeft:'10px',/*float:'right'*/}} shape="circle" onClick={() => {
                                        if(!this.state.deployed_tags.hasOwnProperty(item.id)){
                                        /*this.state.deployed_tags.push(item.id)*/
                                            const nextFavorites = [...this.state.deployed_tags];
                                            nextFavorites.push(item.id);
                                            this.setState({ deployed_tags: nextFavorites });}
                                    }} > v </Button>
                                        }
                                    </div>
                                }

                                description={
                                     this.state.deployed_tags.includes(item.id) &&
                                    <div style={{marginLeft:'5px',outline:'1px solid #eaeaea',paddingLeft:'3px',paddingTop:'3px',paddingBottom:'3px',paddingRight:'3px'}}>
                                    <span style={{color:'black'}}> Name :  </span> 
                                    <Input style={{marginLeft:'4px',width:'30%'}} placeholder="Default name" value={this.state.new_names[item.id] !== undefined ?this.state.new_names[item.id]:item.name} onChange={value =>{
                                        const nextFavorites = [...this.state.new_names];
                                        nextFavorites[item.id] = value.target.value;
                                        this.setState({ new_names: nextFavorites });
                                        }} />
                                    <br></br><br></br>
                                    <span style={{color:'black'}}> Comment :  </span> 
                                    <Input style={{marginLeft:'4px',width:'80%'}} placeholder="Default comment" value={this.state.new_comments[item.id] !== undefined?this.state.new_comments[item.id]:item.comment} onChange={value =>{
                                        const nextFavorites = [...this.state.new_comments];
                                        nextFavorites[item.id] = value.target.value;
                                        this.setState({ new_comments: nextFavorites });  }} />
                                       
                                    <br></br><br></br>
                                    <span style={{fontSize:18,fontWeight:'bold'}}>Share with public</span>
                                    <br></br><br></br>
                                    <Radio.Group style={{marginLeft:'10px'}} options={status_options} defaultValue={this.state.new_publics[item.id] !== undefined && this.state.new_publics[item.id] === true?"Public":item.public === true?"Public":"Private"} onChange={(value) => {
                                        const nextFavorites = [...this.state.new_publics];
                                        nextFavorites[item.id] = value.target.value === "Public";
                                        this.setState({ new_publics: nextFavorites });
                                    }} /> 
                                    <br></br><br></br>
                                    <SketchPicker
                                        color={ this.state.new_colors[item.id] !== undefined?this.state.new_colors[item.id]:item.color }
                                        onChangeComplete={(color) => {
                                            const nextFavorites = [...this.state.new_colors];
                                            nextFavorites[item.id] = color.hex;
                                            this.setState({ new_colors: nextFavorites });
                                          } }
                                    />
                                    <br></br><br></br> 
                                    <Button style={{marginLeft:'3px',borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} type="primary" onClick={() => {this.updateTag(item)}}>Update tag</Button>
                                  <br></br><br></br>
                                  <span style={{fontSize:18,fontWeight:'bold'}}>Share with a group</span>
                                  {/* Group list*/}
                                  <List
                                    style={{marginLeft:'10px'}}
                                    itemLayout="horizontal"
                                    dataSource={this.state.groups}
                                    renderItem={itemg => (

                                    <div>
                                        <List.Item>
                                            <List.Item.Meta
                                            title={
                                                <div>
                                                <span>{itemg.name} :   </span>
                                                <Radio.Group style={{marginLeft:'3px'}} value={this.state.sharing_by_tags !== undefined && this.state.sharing_by_tags.hasOwnProperty(item.id) && this.state.sharing_by_tags[item.id] !== undefined && this.state.sharing_by_tags[item.id] !== [] && this.state.sharing_by_tags[item.id].includes(itemg.id)?"Shared":"Unshared"} options={share_options} onChange={(value) => {
                                                if (value.target.value === "Shared" && !this.state.sharing_by_tags[item.id].includes(itemg.id)) {
                                                    const nextFavorites = [...this.state.sharing_by_tags];
                                                    nextFavorites[item.id].push(itemg.id);
                                                    this.setState({ sharing_by_tags: nextFavorites });

                                                    axios.get('/api/shareatag/', { params:{tag_id:item.id,base_id:itemg.id,base:"group"},headers: { Authorization: `Token ${getUserToken()}` } }).then(res => {this.refreshTagList();})
                                                }
                                                else {
                                                    if (this.state.sharing_by_tags[item.id] !== undefined && this.state.sharing_by_tags[item.id].indexOf(itemg.id) > -1) {
                                                        const nextFavorites = [...this.state.sharing_by_tags];
                                                        nextFavorites[item.id].splice(nextFavorites[item.id].indexOf(itemg.id), 1);
                                                        this.setState({ sharing_by_tags: nextFavorites });

                                                    }
                                                    axios.get('/api/unshareatag/', { params:{tag_id:item.id,base_id:itemg.id,base:"group"},headers: { Authorization: `Token ${getUserToken()}` } }).then(res => {this.refreshTagList();})
                                                }
                                                
                                              
                                            }} /> </div>
                                            }
                                            />
                                        </List.Item>

                                    </div>)}
                                 /> 
                                </div>}






                                />

                                <br></br><br></br>
                            
                            </List.Item>
                               </div> 
                            )}
                        />

                                <div style={{marginLeft:'5px',outline:'1px solid #eaeaea',paddingLeft:'3px',paddingTop:'3px',paddingBottom:'3px',paddingRight:'3px'}}>
                                    <h1 style={{marginRight:'2%',fontSize:21,fontWeight:'bold',textAlign:'center'}}>Create a new tag </h1>
                                    <span style={{color:'black'}}> Name :  </span> 
                                    <Input style={{marginLeft:'4px',width:'30%'}} placeholder="Default name" value={this.state.new_name} onChange={value =>{this.setState({new_name:value.target.value});}} />
                                    <br></br><br></br>
                                    <span style={{color:'black'}}> Comment :  </span> 
                                    <Input style={{marginLeft:'4px',width:'80%'}} placeholder="Default comment" value={this.state.new_comment} onChange={value =>{this.setState({new_comment : value.target.value});}} />
                                    <br></br><br></br>
                                    <span style={{fontSize:18,fontWeight:'bold'}}>Share with public</span>
                                    <br></br><br></br>
                                    <Radio.Group style={{marginLeft:'10px'}} options={status_options} value={this.state.new_public === true?"Public":"Private"} onChange={(value) => {this.setState({new_public : (value.target.value === "Public")});}} /> 
                                    <br></br><br></br>                                   
                                    <SketchPicker
                                        color={ this.state.new_color}
                                        onChangeComplete={(color) => {this.setState({new_color : color.hex});} }
                                    />
                                    <br></br><br></br> 
                                    <Button style={{marginLeft:'3px',borderColor:'#44D4FF',backgroundColor:'#44D4FF'}} type="primary" onClick={() => {this.createTag()}}>Create tag</Button>
                                  <br></br><br></br>
                                </div>
                    </div>
                }
            </div>
        );
    }

}

//export component to React "manager"
export default TagManagement;
