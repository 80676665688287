import cytoscape from 'cytoscape';
import React from 'react';
class CytoscapeHandler extends React.Component {
    constructor(props) {
        super(props);

    }

 showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = (e.target.result)
      alert(text)
        const json_elem = JSON.parse(text);
        const backup_elem = JSON.parse(text);
        this.state.cy = cytoscape({

                  container: document.getElementById('cy'), // container to render in
                  elements: json_elem["elements"],
                   style: [
                {
                  selector: 'node',
                  style: {
                    'label': 'data(name)',
                      'color':'red',
                  }
                }
              ],


              });
        backup_elem["elements"]["nodes"].forEach(elem => {
           this.state.cy.getElementById(elem["data"]["id"]).position(elem["position"]);
        });
          };
    reader.readAsText(e.target.files[0]);
  }

    clusterStuff = (cluster_type) => {
        if (cluster_type === "markov"){
            this.state.cy.elements().markovClustering();
        }
        else if (cluster_type === " kMeans"){
            this.state.cy.elements().kMeans();
        }
        else if (cluster_type === "KMedoids"){
            this.state.cy.elements().kMedoids();
        }
        else if (cluster_type === "fuzzyCMeans"){
            this.state.cy.elements().fuzzyCMeans();
        }
        else if (cluster_type === "hierarchical"){
            this.state.cy.elements().hierarchicalClustering();
        }
        else if (cluster_type === "affinity"){
            this.state.cy.elements().affinityPropagation();
        }
    }
    componentDidMount() {

    }

    //this store data on the webpage, change on a variable can be catch in componentDidUpdate()
    state = {
        id_user: -1,
        network_link : "localhost:3000/images/Connectome.cyjs",
        netwok_data : {},
    }

    render() {
        return (<div>
            <input style={{marginTop:'10%'}} type="file" onChange={(e) => this.showFile(e)} />
            <br></br><br></br>
            <input type="button" value="Markov Clustering" onClick={() => {this.clusterStuff("markov");}}></input>
            <input type="button" value="kMeans" onClick={() => {this.clusterStuff("kMeans");}}></input>
            <input type="button" value="KMedoids" onClick={() => {this.clusterStuff("KMedoids");}}></input>
            <input type="button" value="fuzzyCMeans" onClick={() => {this.clusterStuff("fuzzyCMeans");}}></input>
            <input type="button" value="hierarchical Clustering" onClick={() => {this.clusterStuff("hierarchical");}}></input>
            <input type="button" value="affinity Propagation" onClick={() => {this.clusterStuff("affinity");}}></input>
            <div className='cy' id='cy' style={{width:'1280px',height:'720px',display:'block'}}></div>
            </div>);
    }
}

export default CytoscapeHandler;