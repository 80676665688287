import {connect} from "react-redux";
import React from "react";
import {AutoComplete, Button, Checkbox, Form, Icon, Input, InputNumber, List, Select, Tooltip, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {ReloadDatasets, SendTracking} from "../utils/api_callbacks";
import axios from "axios";
import {getUserToken} from "../utils/CookieManagement";
import {SetRedirectedUrl} from "../store/reducers/userSlice";

const {Option} = AutoComplete;

class UploadDataset extends React.Component {

    state = {
        set_error: false,
        set_name: "",
        set_starttime: 0,
        set_endtime: 0,
        set_comment: "",
        upload_texture: false,
        set_type: 0,
        set_spf: 0,
        set_dt: 0,
        buttonClicked: false,
        set_ncbi: 0,
        mesh_time: 1,
        mesh_quality: 1,
        mesh_channel: 0,
        texture_file: null,
        material_content: null,
        is_not_obj: false,
        file_uploaded: false,
        fileList: [],
        uploading: false,
        set_serverid: -1,
    }


    componentDidMount() {
    }


    PatchDatasetChange = () => {
        this.setState({buttonClicked: true});
        if (this.state.set_name === "") {
            this.state.set_name = "New dataset not named";
        }
        if (this.state.set_comment === "") {
            this.state.set_comment = "...";
        }

        if (!this.state.file_uploaded) {
            return;
        }
        SendTracking(-1, "Web,Dataset,Upload,create");

        //console.log("Name : "+this.state.set_name+" comment : " + this.state.set_comment+" spf : "+this.state.set_spf+" dt : "+this.state.set_dt+" starttime : "+this.state.set_starttime+" endtime "+this.state.set_endtime+" type : "+this.state.set_type+ " ncbi : "+this.state.set_ncbi);
        let formData = new FormData();
        formData.append('name', this.state.set_name);
        formData.append('comments', this.state.set_comment);
        formData.append('spf', this.state.set_spf);
        formData.append('dt', this.state.set_dt);
        formData.append('starttime', this.state.set_starttime);
        formData.append('endtime', this.state.set_endtime);
        formData.append('type', this.state.set_type);
        formData.append('id_ncbi', this.state.set_ncbi);
        formData.append('file', this.state.fileList[0])
        if (this.state.set_serverid !== -1) {
            formData.append('serverid', this.state.set_serverid);
        }

        if (this.state.upload_texture === true && this.state.texture_file !== null && this.state.material_content !== null) {
            formData.append('material', this.state.material_content);
            formData.append('texture_file', this.state.texture_file);
        } else if (this.state.upload_texture === true) {
            return;
        }

        axios({
            method: 'post',
            url: `/api/createsetsite/`,
            headers: {Authorization: `Token ${getUserToken()}`},
            data: formData,
        }).then(res => {
            ReloadDatasets();
            this.props.SetRedirectedUrl("/dataset/"+res.data.id);
        }).catch(err => this.setState({set_error: err}));
    }

    render() {
        const {uploading, fileList} = this.state;

        const texture_props = {
            onRemove: file => {
                this.setState({texture_file: null});
            },
            beforeUpload: file => {
                if (file.name.endsWith('.png') || file.name.endsWith('.jpg')) {
                    //store in state
                    this.setState({
                        texture_file: file,
                    });
                }
                return false;
            }
        };

        const material_props = {
            onRemove: file => {
                this.setState({material_content: null});
            },
            beforeUpload: file => {
                if (file.name.endsWith('.mtl')) {
                    const reader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = function (e) {
                        this.setState({material_content: reader.result});
                    }.bind(this);
                    //read content
                    //store in state
                }
                return false;
            }
        };

        const props = {
            onRemove: file => {
                this.setState({file_uploaded: false});
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if (file.name.endsWith('.obj')) {
                    this.setState({is_not_obj: false});
                    this.setState({file_uploaded: true});
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                } else {
                    this.setState({is_not_obj: true});
                }
                return false;
            },
            fileList,
        };
        return (
            <div style={{marginTop:"3%"}}>
                <h1 style={{
                    color: '#292e37',
                    fontSize: '34px',
                    textAlign: 'center',
                    fontWeight: "bold",
                    minWidth: '750px'
                }}> Create a dataset </h1>
                {

                    this.state.set_error &&
                    <div><h2 style={{
                        color: '#292e37',
                        fontFamily: 'Baskerville',
                        fontSize: '34px',
                        textAlign: 'center',
                        fontWeight: "bold",
                        minWidth: '750px'
                    }}>Error during creation : {this.state.set_error}</h2>
                    </div>
                }
                <Form>
                    <Form.Item onChange={e => {
                        this.setState({set_name: e.target.value})
                    }}>
                        <Tooltip placement="right" title={<span>Enter the dataset name</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Name : </h2>
                            <TextArea
                                autoSize={{minRows: 1, maxRows: 6}}
                                style={{width: '50%', marginLeft: '10px'}}
                                prefix={<Icon type="font-colors" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder="Dataset name"
                            />
                        </Tooltip>
                    </Form.Item>

                    <Form.Item onChange={e => {
                        this.setState({set_comment: e.target.value})
                    }}>
                        <Tooltip placement="right" title={<span>Enter the dataset description</span>}>
                            <h2 style={{
                                display: 'inline',
                                color: '#44D4FF',
                                fontSize: '20px',
                                width: '80%'
                            }}>Description : </h2>
                            <TextArea
                                style={{width: '60%'}}
                                autoSize={{minRows: 2, maxRows: 6}}
                                prefix={<Icon type="font-colors" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder="Dataset description"
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right" title={<span>The starting time point of the 3D data</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Start time:</h2>
                            <InputNumber
                                style={{width: '200px', marginLeft: '10px'}}
                                onChange={e => {
                                    this.setState({set_starttime: e})
                                }}
                                value={this.state.set_starttime}
                                placeholder="Dataset start time"
                            /></Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right" title={<span>The ending time point of the 3D data</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>End time:</h2>
                            <InputNumber
                                style={{width: '200px', marginLeft: '10px'}}
                                onChange={e => {
                                    this.setState({set_endtime: e})
                                }}
                                value={this.state.set_endtime}
                                placeholder="Dataset end time"
                            /></Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right"
                                 title={<span>Base for stage calculation for a 3D embryo representation <br></br>If you're not planing to use this value, please let the default value</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Seconds post
                                fertilization:</h2>
                            <Input
                                style={{width: '200px', marginLeft: '10px'}}
                                onChange={e => {
                                    this.setState({set_spf: e.target.value})
                                }}
                                value={this.state.set_spf}
                                placeholder="Dataset spf"
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right"
                                 title={<span>Time step between 2 time points <br></br>If you're not planing to use this value, please let the default value</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Delta time:</h2>
                            <Input
                                style={{width: '200px', marginLeft: '10px'}}
                                onChange={e => {
                                    this.setState({set_dt: e.target.value})
                                }}
                                value={this.state.set_dt}
                                placeholder="Dataset delta time"
                            />
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>(in seconds)</h2>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right" title={<span>Way the 3D data has been created</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Type :</h2>
                            <Select onChange={e => {
                                this.setState({set_type: e})
                            }} defaultValue={0} style={{width: '200px', marginLeft: '10px'}}>
                                <Option value={0}>Observed</Option>
                                <Option value={1}>Simulated</Option>
                                <Option value={2}>Drew</Option>
                            </Select>
                        </Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <Tooltip placement="right" title={<span>Add a texture and material file</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Add a texture : </h2>
                            <Checkbox onChange={() => {
                                this.setState({upload_texture: !this.state.upload_texture});
                            }}></Checkbox>
                        </Tooltip>
                    </Form.Item>

                    {
                        this.state.upload_texture &&
                        <div>
                            <Form.Item>
                                <h2 style={{fontSize: '21px'}}>
                                    Choose a material file
                                </h2>
                                <Upload {...material_props}>
                                    <Button>
                                        <UploadOutlined/> Select material (mtl)
                                    </Button>
                                </Upload>
                            </Form.Item>

                            <Form.Item>
                                <h2 style={{fontSize: '21px'}}>
                                    Choose a texture file
                                </h2>
                                <Upload {...texture_props}>
                                    <Button>
                                        <UploadOutlined/> Select texture (png or jpg)
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                    }
                    <Form.Item>
                        <Tooltip placement="right"
                                 title={<span>ID of the species inside the ANISEED database <br></br>If you're not planing to use this value, please let the default value</span>}>
                            <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>NCBI category id:</h2>
                            <InputNumber
                                style={{width: '200px', marginLeft: '10px'}}
                                value={this.state.set_ncbi}
                                onChange={e => {
                                    this.setState({set_ncbi: e})
                                }}
                                placeholder="Dataset ncbi id"
                            />
                        </Tooltip>
                    </Form.Item>
                    <Form.Item>
                        <div>
                            {
                                this.props.server_list !== undefined && this.props.server_list.length > 0 &&
                                <div><p style={{color: '#44D4FF', fontSize: '21px', fontWeight: "bold"}}>Upload on a
                                    distant servers</p>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.props.server_list}
                                        renderItem={item => (
                                            <div>
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<div>
                                                            <span style={{marginLeft: '1%', fontSize: 16}}>Address
                                                                : {item.adress}</span>
                                                            <span style={{marginLeft: '15%', fontSize: 16}}> Path
                                                                : {item.path}  </span>
                                                            <Checkbox style={{marginLeft: '10%'}}
                                                                      onChange={() => this.setState({set_serverid: item.id})}
                                                                      checked={this.state.set_serverid === item.id}> Use
                                                                this server</Checkbox>
                                                        </div>}
                                                    />
                                                </List.Item>
                                            </div>
                                        )}
                                    />
                                </div>
                            }

                        </div>
                    </Form.Item>
                    {
                        !this.state.file_uploaded && this.state.buttonClicked ?
                            <h2 style={{color: 'red', fontSize: '18px'}}>You need to upload a 3D file (that contains an
                                OBJ) to create a dataset</h2>
                            :
                            this.state.is_not_obj ?
                                <h2 style={{color: 'red', fontSize: '18px'}}>The 3D file that you upload MUST be a .obj
                                    file !</h2>
                                :
                                this.state.upload_texture === true && (this.state.material_content === null || this.state.texture_file === null) &&
                                <h2 style={{color: 'red', fontSize: '18px'}}>To upload a textured dataset, please be
                                    sure to join a MTL file and a Texture file !</h2>

                    }
                    <div><h2 style={{fontSize: '21px'}}>
                        Choose a mesh file
                    </h2>
                        <Upload {...props}>
                            <Button>
                                <UploadOutlined/> Select File (OBJ)
                            </Button>
                        </Upload>
                    </div>
                    <br></br><br></br>
                    <Form.Item>
                        <Button type="primary" onClick={this.PatchDatasetChange}
                                style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                            Create dataset
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        connected: state.user.is_connected,
        server_list: state.user.server_list,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadDataset);