import {connect} from "react-redux";
import React from "react";
import GroupInstance from "./groupInstance";
import {NavLink} from "react-router-dom";
import {SendTracking} from "../utils/api_callbacks";

class GroupListView extends React.Component {
    state = {}

    componentDidMount() {
    }


    render() {

        const group_list = Object.values(this.props.user.group_list);
        return <div>
            <h1 style={{textAlign:"center",color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>Group list</h1>
            <div style={{textAlign:"unset",marginTop:"5%"}}><NavLink to="/group-registration">
                <h1 style={{color:'#292e37',fontSize:'25px',display:"inline"}}>Create Group</h1>
                <img style={{marginLeft:"10px",paddingBottom:"5px"}} alt="Group creation" onClick={() => {
                    SendTracking(-1, "Web,Group,Create,view");}}  width={50} src="/images/refonte/newgroup.png"></img>
            </NavLink></div>
            <div style={{marginTop:"2%",width:"100%"}}>{this.props.user.is_connected && group_list.map(group => <div key={"group_"+group.id} style={{marginTop:"1%"}}><GroupInstance
                key={"group_" + group.id} group={group}></GroupInstance></div>)}</div>
            </div>
    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset,
        user: state.user,
        tags: state.tag,
        user_is_admin: state.user.is_staff,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupListView);