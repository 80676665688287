// noinspection ConstantConditionalExpressionJS

import React from 'react';
import {Tooltip,List} from 'antd';
import '../App.css';
import axios from 'axios';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import * as UnityInfos from './UnityInfos';
import {encryptString,decryptString,getUserToken,getUserID,isOnStandalone} from '../store/utility';
import ReactDOM from 'react-dom';


function textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
  if (str.length > maxLength) {
    switch (side) {
      case "start":
        return ellipsis + str.slice(-(maxLength - ellipsis.length));
      case "end":
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
  }
  return str;
}


class Simulations_Mobile extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    //this store data on the webpage, change on a variable can be catch in componentDidUpdate()
    state = {
        user_tags: [],
        simulations: [],
        filtered_simulations: [],
        sessions: [],
        loading: false,
        sortCount: 0,
        buttons_state: new Map(),
        clicked_simulation_name: "",
        clicked_simulation: {},
        resetSimulations: false,
        display_mode: "",
        sessions_list: [],
        selected_session_id: -1,
        selected_session: {},
        connected_user_id: -1,
        user_contact: [],
        simulation_size: -1,
        refreshSimulation: false,

        //update simulation parameters
        update_simulation_name: "",
        update_simulation_description: "",

        //newsession parameters
        newsession_name: "",
        newsession_url: "",
        newsession_idpeople: -1,
        newsession_default: 0,
        corCount: 0,
        bttnCount: 0,
        simulations_list: "",
        //updatesession parameters
        updatesession_name: "",
        updatesession_url: "",
        updatesession_idpeople: -1,
        updatesession_date: "",
        updatesession_corCount: 0,
        updatesession_bttnCount: 0,
        updatesession_defaut: 0,

        encrypted_message: "",
        decrypted_message: "",
        token: "",
        staff: false,
        lastSimulationCliked: "",

        simulations_loaded: false,
        owner_name: "",
        user_settings: [],
        filterlock: false,
        unity_loaded: false,
        updated_once: false,
        tutorial_ready: false,
        gif_by_simulation: []
    }

    loadedRowsMap = {}

    //executed before first render
    componentDidMount() {
        this.state.connected_user_id = getUserID();
        this.setState({ token: getUserToken() });
        const token_user = getUserToken();
        if (token_user !== "" && token_user !== null) {
            axios.get(`/api/settings/`, { headers: { Authorization: `Token ${token_user}` } })
                .then(res => {
                    this.setState({
                        user_settings: res.data,
                    });
                });


            axios.get('/api/usertags/', { headers: { Authorization: `Token ${token_user}` } })
                .then(res => {
                    this.setState({ user_tags: res.data });
                });
        }

        window.addEventListener('disconnect', () => { this.componentDidMount(); })
        this.setState({ unity_loaded: this.props.unityLoaded ? this.props.unityLoaded : false });
        this.setState({ encrypted_message: encryptString("themessage") });

        //Unity buttons for sessions
        UnityInfos.unity_buttons.map(button => {
            this.state.buttons_state[button] = false;
        });
    }

    //When receiving data, do something
    componentDidUpdate(prevProps, prevState) {
        if (this.props.refreshSimulation !== this.state.refreshSimulation) {
            this.setState({ refreshSimulation: this.props.refreshSimulation });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }
        if (this.props.tutorialReady !== this.state.tutorial_ready && this.props.tutorialReady === true && !this.state.tutorial_ready && this.state.sets.length > 0) {
            this.setState({ tutorial_ready: true });

            setTimeout(() => {
                const new_node = ReactDOM.findDOMNode(this.refs.item_div);
                this.props.displayTutorial(new_node, 'left', 'bottom', `Here is the list of the simulation \n you can access`, 'tutorial-simulation', true);
            }, 1000);
        }

        if (this.props.simulationClicked !== prevProps.simulationClicked) {

            this.handleClickSessionParameters(-1);
            this.handleClick("");

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (this.props.nameLoaded !== prevProps.nameLoaded) {

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (!this.state.updated_once) {

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
            this.setState({ updated_once: true });
        }
        //when unity is loaded -> store it 
        if (this.props.unityLoaded !== prevProps.unityLoaded) {

            this.setState({ unity_loaded: this.props.unityLoaded });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        //when you click somewhere to reset this component layout
        if (prevProps.layoutReset !== this.props.layoutReset) {

            this.handleClickSessionParameters(-1); this.handleClick("");
        }

        if (prevState.encrypted_message !== this.state.encrypted_message) {
            this.setState({ decrypted_message: decryptString(this.state.encrypted_message) });
        }

        if (this.state.clicked_simulation_name !== "" && (prevState.clicked_simulation_name !== "" || this.state.loaded_from_props === true) !== -1 && prevState.clicked_simulation !== this.state.clicked_simulation) {

            axios.get('/api/simulationsize/', { params: { name_simulation: this.state.clicked_simulation_name }, headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ simulation_size: res.data });
                    window.scrollTo(window.scrollX + 1, window.scrollY + 1);
                    window.scrollTo(window.scrollX - 1, window.scrollY - 1);
                })
        }

        //used when clicking on layout on simulation list (go back to list view)
        if (prevProps.resetSimulations  !== this.state.resetSimulations) {

            this.setState({ resetSimulations: prevProps.resetSimulations });
            this.setState({ clicked_simulation_name: "" });
            this.setState({ clicked_simulation: null });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }


        //Remove simulation test here , and find  away to check if simulations if different or no
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            const simulations_copy = [];

            Object.assign(simulations_copy, this.props.data);

            this.state.simulations_list = ""
            simulations_copy.map(simulation => {
                if (simulation !== undefined) {
                    this.state.simulations_list += simulation.name + ","

                    if (this.state.gif_by_simulation[simulation.name] === null) {
                        axios.get("/GIF/SIMULATION_" + simulation.name + "-50.gif")
                            .then(resb => this.state.gif_by_simulation[simulation.name] = false);
                    }
                }
            }
            );

            this.setState({ simulations: simulations_copy });
            if (this.props.location !== undefined && this.props.location.preChoosenSimulation !== undefined) {
                const simulation_name = this.props.location.preChoosenSimulation;
                this.setState({ loaded_from_props: true });
                this.loadData(simulation_name);

                this.setState({ clicked_simulation_name: simulation_name });

                this.setState({ clicked_simulation: simulations_copy.filter(simulation => simulation.name === simulation_name)[0] });
                this.setState({ display_mode: "description" })
                this.props.onSelectedSimulation(simulation_name);
                this.props.location.preChoosenSimulation = undefined;
                window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1);
            }
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (prevState.display_mode !== this.state.display_mode) {
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (JSON.stringify(prevProps.usettings)  !== JSON.stringify(this.props.usettings)) {
            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
            this.setState({ user_settings: this.props.usettings });
        }

        if (prevProps.sortCount  !== this.state.sortCount) {
            this.renderItem = this.renderItem.bind(this);
            this.setState({ sortCount: prevProps.sortCount });
            window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }

        if (prevState.selected_session_id !== this.state.selected_session_id) {
            if (this.state.selected_session_id !== -1) {
                this.setState({
                    //updatesession parameters
                    updatesession_name: this.state.selected_session.name,
                    updatesession_defaut: this.state.selected_session.defaut,
                    updatesession_url: this.state.selected_session.url,
                    updatesession_idpeople: this.state.selected_session.id_people,
                    updatesession_date: this.state.selected_session.date,
                    updatesession_corCount: (this.state.selected_session.url.match(/cor/g) || []).length,
                    updatesession_bttnCount: (this.state.selected_session.url.match(/bttn/g) || []).length,
                });
            }
            else {
                this.setState({
                    //updatesession parameters
                    updatesession_name: "",
                    updatesession_defaut: 0,
                    updatesession_url: "",
                    updatesession_idpeople: -1,
                    updatesession_corCount: 0,
                    updatesession_bttnCount: 0,
                });
            }
        }

        if (prevState.clicked_simulation  !== this.state.clicked_simulation) {
            if (this.state.clicked_simulation_name !== "") {
                this.setState({
                    newsession_idpeople: this.state.connected_user_id,
                    update_simulation_name: this.state.clicked_simulation.name,
                    update_simulation_description: this.state.clicked_simulation.comments,
                });
            }
            else {
                this.setState({
                    newsession_idpeople: -1,
                    update_simulation_name: "",
                    update_simulation_description: "",
                });
            }
        }
    }


    getUserSettingsValue(p_setting) {
        let value = -1;
        if (this.state.user_settings !== undefined) {
            this.state.user_settings.map(sett => {
                if (sett.setting === p_setting) {
                    value = sett.value;
                }
            });
        }
        return value;
    }

    // if a simulation is selected > clear values to go back to the simulation list
    // if not, choose the clicked simulation as the selected simulation
    handleClick = (simulationName) => {
        if (this.state.clicked_simulation_name === "") {
            this.state.clicked_simulation_name = simulationName;
            this.setState({ clicked_simulation: this.state.simulations.filter(simulation => simulation.name === simulationName)[0] });
            this.setState({ display_mode: "description" });
            this.props.onSelectedSimulation(simulationName);
        }
        else {
            this.setState({ clicked_simulation_name: "" });
            this.setState({ clicked_simulation: null });
            this.setState({ display_mode: "" });
            this.setState({ sessions_list: [] });
            this.props.onSelectedSimulation("");
        }
    }

    // if a session is selected > clear values to go back to the main session view
    // if not, choose the clicked session as the selected session
    handleClickSessionParameters = (sessionID) => {
        if (this.state.selected_session_id === -1) {
            this.setState({ selected_session_id: sessionID });
            this.state.sessions_list.map(session => {
                if (session.id === sessionID) {
                    this.state.selected_session = session;
                }
            });
        }
        else {
            this.state.selected_session_id = -1;
            this.state.selected_session = {};
        }
    }

    //Status for a button in the selected session (before we start to store update parameters)
    getButtonFromFirstUrl = (buttn_name) => {
        const parameters = this.state.selected_session.url.split('&');
        let parameter = null;
        parameters.map(param => { if (param.includes("bttn") && param.includes("_" + buttn_name)) { parameter = param.split('=')[1]; } });
        let type = "show";
        if (parameter !== null)
            type = parameter.split('_')[0];
        //console.log(type);
        return type;
    }

    //Status for a specific button (show/hide) in the session being updated (update session page)
    getButtonFromUrl = (buttn_name) => {
        const parameters = this.state.updatesession_url.split('&');
        let parameter = null;
        parameters.map(param => { if (param.includes("bttn") && param.includes("_" + buttn_name)) { parameter = param.split('=')[1]; } });
        let type = "show";
        if (parameter !== null)
            type = parameter.split('_')[0];
        return type;
    }

    //Status for a specific button (show/hide) in the session being updated (update session page)
    getButtonFromNewUrl = (buttn_name) => {
        const parameters = this.state.newsession_url.split('&');
        let parameter = null;
        parameters.map(param => { if (param.includes("bttn") && param.includes("_" + buttn_name)) { parameter = param.split('=')[1]; } });
        let type = "show";
        if (parameter !== null)
            type = parameter.split('_')[0];
        return type;
    }

    handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
        const data = this.state.filtered_simulations;
        this.setState({
            loading: true,
        });
        for (let i = startIndex; i <= stopIndex; i++) {
            // 1 means loading
            this.loadedRowsMap[i] = 1;
        }
        if (data.length > 19) {
            //  message.warning('Virtualized List loaded all');
            this.setState({
                loading: false,
            });

        }
    };


    //Get specific parameter value in the selected session url
    getValueFromUrl = (parameter) => {
        const parameters = this.state.selected_session.url.split('&');
        let result = null;
        parameters.map(param => { if (param.includes(parameter)) { result = param.split('=')[1] } });
        return result;
    }

    //User status for a specific simulation, using sharings data
    getUserStatus = (user_id) => {
        const status = "norights";
        if (this.state.clicked_simulation.id_people === user_id) {
            return "manager";
        }
        return status;
    }

    // ----------------------------- On simulation update Inputs value change 

    onChangeUpdateSimulationname = ({ target: { value } }) => {
        this.setState({ update_simulation_name: value });
    };

    onChangeUpdateSimulationdescription = ({ target: { value } }) => {
        this.setState({ update_simulation_description: value });
    }

    DeleteSelectedSimulation = () => {
        if (window.confirm('Are you sure you wish to delete this simulation ?')) {
            this.props.sendTrackingSimulation(this.state.clicked_simulation_name, "Web,Simulation,Settings,delete");
            axios({
                method: 'get',
                url: `/api/deletesimulationweb/`,
                params: { name_simulation: this.state.clicked_simulation_name },
                headers: { Authorization: `Token ${this.state.token}` }
            }).then(res => {
                window.location.reload(false);
            });
        }
    }

    PatchSimulationChange = () => {
        const error = false;
        if (this.state.update_simulation_name === "" || this.state.update_simulation_name === undefined) {
            this.state.update_simulation_name = "New simulation not named";
        }
        if (this.state.update_simulation_description === undefined) {
            this.state.update_simulation_description = "";
        }

        if (!error) {
            this.props.sendTrackingSimulation(this.state.clicked_simulation_name, "Web,Simulation,Settings,update");
            //post new group to django
            axios({
                method: 'post',
                url: `/api/updatesimulationwebsite/`,
                data: {
                    name: this.state.update_simulation_name,
                    name_simulation: this.state.clicked_simulation_name,
                    comments: this.state.update_simulation_description,
                },
                headers: { Authorization: `Token ${this.state.token}` }
            }).then(res => {
                this.setState({ display_mode: "description" });
                this.state.clicked_simulation.name = this.state.update_simulation_name;
                this.state.clicked_simulation.comments = this.state.update_simulation_description;
            });
        }
    }

    //------------------------------------
    // ----------------------------- On new session Inputs value change 

    onChangeNewSessionname = ({ target: { value } }) => {
        this.setState({ newsession_name: value });
        this.setState({ newssession_url_prefix: "name_simulation=" + this.state.clicked_simulation_name });
    };


    UpgradeUrlNewSession = (type, p_value, obj_id) => {
        switch (type) {
            case "cor":
                var parameters = this.state.newsession_url.split('&');
                var alreadyexist = false;
                var oldvalue = "";
                parameters = parameters.map(param => {
                    if (param.includes("cor") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        oldvalue = param.split('=')[1];
                        return oldparam + "=" + p_value + "_" + obj_id;
                    }
                    return param;
                });

                this.state.newsession_url = parameters.join('&')

                if (!alreadyexist) {
                    this.state.newsession_url += "&cor" + this.state.corCount + "=" + p_value + "_" + obj_id;
                    this.state.corCount++;
                }
                break;

            case "bttn":
                var parameters = this.state.newsession_url.split('&');
                var alreadyexist = false;
                var oldvalue = "";
                parameters = parameters.map(param => {
                    if (param.includes("bttn") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        oldvalue = param.split('=')[1];
                        return oldparam + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    }
                    return param;
                });
                this.state.newsession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.newsession_url += "&bttn" + this.state.bttnCount + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    this.state.bttnCount++;
                }
                break;
        }
    }

    makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // ------------------------------------------

    // ----------------------------- On new session Inputs value change 

    onChangeUpdateSessionname = ({ target: { value } }) => {
        this.setState({ updatesession_name: value });
    };

    changeSessionDefault = (checked) => {
        // console.log("Update session defaut");
        this.setState({ updatesession_defaut: checked ? 1 : 0 });
        //axios request
        axios.get('/api/sessiondefaut/', { params: { id_session: this.state.selected_session_id, defaut: checked ? 1 : 0 }, headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
            });
    }

    UpgradeUrlUpdateSession = (type, p_value, obj_id) => {
        switch (type) {
            case "cor":
                var parameters = this.state.updatesession_url.split('&');
                var alreadyexist = false;
                var oldvalue = "";
                parameters = parameters.map(param => {
                    if (param.includes("cor") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        oldvalue = param.split('=')[1];
                        return oldparam + "=" + p_value + "_" + obj_id;
                    }
                    return param;
                });

                this.state.updatesession_url = parameters.join('&')

                if (!alreadyexist) {
                    this.state.updatesession_url += "&cor" + this.state.updatesession_corCount + "=" + p_value + "_" + obj_id;
                    this.state.updatesession_corCount++;
                }
                break;

            case "bttn":
                var parameters = this.state.updatesession_url.split('&');
                var alreadyexist = false;
                var oldvalue = "";
                parameters = parameters.map(param => {
                    if (param.includes("bttn") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        oldvalue = param.split('=')[1];
                        return oldparam + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    }
                    return param;
                });
                this.state.updatesession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.updatesession_url += "&bttn" + this.state.updatesession_bttnCount + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    this.state.updatesession_bttnCount++;
                }
                break;
        }
    }

    // ---------------------------
    ValidateEmail = param_mail => {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(param_mail));
    }


    bytesToSize = bytes => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0 || bytes === -1) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }


    isRowLoaded = ({ index }) => !!this.loadedRowsMap[index];

    renderItem = ({ index, key, style }) => {
        const data = this.state.filtered_simulations;
        const item = data[index];

        return (
            <div >
                <div >
                    <List.Item key={key} style={style}>

                        <Tooltip placement="bottom" title={this.state.unity_loaded || this.getUserSettingsValue("standalone_start") === "true" ? <span>Load this simulation in the visualizer</span> : <span>Viewer is still loading ...</span>}>
                            <img alt="viewer eye" onContextMenu={(evt) => { evt.preventDefault(); localStorage.setItem('unityProps', encryptString(JSON.stringify(item))); window.open(window.location.href, '_blank'); return false; }}
                                onClick={() => {
                                    if (this.state.unity_loaded || isOnStandalone() || this.getUserSettingsValue("standalone_start") === "true") {
                                        this.props.openUnityFromAway(); this.props.sendTrackingSimulation(item.name, "Web,Simulation,visualize");
                                        this.props.unityLoadFunc(item);
                                    }
                                }} style={{ cursor: 'pointer', marginLeft: '0px', borderRadius: '10px', marginRight: '2px' }} width={this.state.clicked_simulation !== undefined && this.state.display_mode === "description" ? 50 : 50} src={(this.state.clicked_simulation !== undefined && this.state.display_mode === "description") || this.state.gif_by_simulation[item.name] === false ? this.state.unity_loaded || isOnStandalone() || this.getUserSettingsValue("standalone_start") === "true" ? "/images/refonte/load_small.png" : "/images/refonte/greyload_small.png" : "/GIF/SIMULATION_" + item.name + "-50.gif"}></img>
                        </Tooltip>

                        <List.Item.Meta
                            title={
                                this.state.clicked_simulation_name === "" ?
                                    <div>
                                        <span style={{ cursor: 'pointer', paddingBottom: '0px' }} className='content-text'>{window.innerWidth < 1200 ? textEllipsis(item.name, 30) : window.innerWidth < 600 ? textEllipsis(item.name, 10) : item.name} </span></div>
                                    :
                                    <span></span>
                            }

                        />
                    </List.Item></div>
            </div>

        );
    };

    render() {
        this.state.filtered_simulations = this.state.simulations.filter(simulation => this.state.clicked_simulation_name === "" || simulation.name === this.state.clicked_simulation_name);
        const data = this.state.filtered_simulations;

        const vlist = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered, width }) => (
            <VList
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                overscanRowCount={2}
                rowCount={data.length}
                rowHeight={73}
                rowRenderer={this.renderItem}
                onRowsRendered={onRowsRendered}
                scrollTop={scrollTop}
                width={width}
            />
        );
        const autoSize = ({ height, isScrolling, onChildScroll, scrollTop, onRowsRendered }) => (
            <AutoSizer disableHeight>
                {({ width }) =>
                    vlist({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                        width,
                    })
                }
            </AutoSizer>
        );
        const infiniteLoader = ({ height, isScrolling, onChildScroll, scrollTop }) => (
            <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.handleInfiniteOnLoad}
                rowCount={data.length}
            >
                {({ onRowsRendered }) =>
                    autoSize({
                        height,
                        isScrolling,
                        onChildScroll,
                        scrollTop,
                        onRowsRendered,
                    })
                }
            </InfiniteLoader>
        );

        return (
            <div style={{ paddingTop: '1%' }}>
                <List ref='item_div'>
                    {data.length > 0 && <WindowScroller>{infiniteLoader}</WindowScroller>}
                </List>
            </div>
        );
    }


}

export default Simulations_Mobile;