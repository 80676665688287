import React from 'react';
import '../App.css';
import axios from 'axios';
import Phylocanvas from 'phylocanvas';
import {connect} from "react-redux";
import {SetTreeFilter} from "../store/reducers/datasetSlice";
import {SendTracking} from "../utils/api_callbacks";


class PhyloTree extends React.Component {
    phylocanvas_retracted_ratio = 0.5;
    phylocanvas_moving_ratio = 0.5;
    phylocanvas_starting_ratio = 0.4;
    phylocanvas_y_offset = 50;
    state = {
        clicked_cat_name: "",
        sets: [],
        previous_phylotree : "",
        categories: [],
        cat_length: -1,
        tree: new Map(),
        last_node_created: {},
        tree_created: false,
        source_string_created: false,
        sets_found: false,
        hidden_tree: false,
        initWidth: -1,
        initHeight: -1,
        currentWidth: -1,
        currentHeight: -1,
        initXoffset: -1,
        reset_tree: false,
        tree_loaded: false,
    }

    constructor(props) {
        super(props);
        this.phylotreecontainer = React.createRef();

    }

    //hide div with tree inside
    hideTree = () => {
        this.setState({hidden_tree: !this.state.hidden_tree});
    }


    createTree = (id_ncbi, n) => {
        if (this.state.tree.has(id_ncbi) || id_ncbi === 1) {
            const node = this.state.tree.get(id_ncbi);
            const childs = [];
            for (let [key, value] of this.state.tree.entries()) {
                if (value !== undefined && value !== null && value.id_parent === id_ncbi && key !== id_ncbi) {
                    childs.push(value);
                }
            }

            if (childs.length === 0 && id_ncbi !== 1) {
                return node.name + ":" + n;
            }

            if (childs.length === 1) {
                return this.createTree(childs[0].id, n++);
            }

            if (childs.length > 1) {
                let result = "(";
                let ll = 0;
                childs.map(child => {
                    result = result + this.createTree(child.id, 1);
                    if (ll < childs.length - 1) {
                        result = result + ",";
                        ll++;
                    }
                });
                result = result + '):' + n;
                return result;
            }
        }
    }

    LoadMorphoTree = () => {
        if (this.props.morpho_tree !== undefined && this.props.morpho_tree !== "")
            this.state.morphoPhyloTree.load(this.props.morpho_tree);
    }

    //return to parent (DatasetListView), the id to filter
    filterByCategory(id, name) {
        this.props.selectTreeBranch(id);
    }

    componentDidMount() {

        this.setState({initWidth: window.innerWidth});
        this.setState({initHeight: window.innerHeight});
        this.setState({currentWidth: window.innerWidth});
        this.setState({currentHeight: window.innerHeight});
        //create the tree, the container is referenced by "ref" and created in constructor
        this.state.morphoPhyloTree = Phylocanvas.createTree(this.phylotreecontainer.current, {
            history: false,
            scalebar: {active: false},
            disableZoom: true,
            hoverLabel: true,
            fillCanvas: true,
            internalNodesSelectable: false,
            showLabels: true,
            textSize: 25,
            baseNodeSize: 10,
            highlightColour: '#62B2E0',
            selectedColour: '#62B2E0',
            tree_loaded: false,

        });

        window.addEventListener("resize", this.resize.bind(this));
        this.state.morphoPhyloTree.on('loaded', () => {
            this.setState({tree_loaded: true});
        })

        const nb_branches = this.state.cat_length;
        //this.state.morphoPhyloTree.setSize(this.phylotreecontainer.current.offsetWidth * (window.screen.width / window.innerWidth), 50 * nb_branches);

        //this.state.morphoPhyloTree.setNodeSize(10);
        this.state.morphoPhyloTree.setTreeType('diagonal');
        // console.log(this.state.morpho_tree);
        if (this.props.morpho_tree != this.state.previous_phylotree) {
            this.drawTree();
            this.setState({tree_loaded: true});
            this.setState({initXoffset: this.state.morphoPhyloTree.offsetx});
            this.redrawTree();
        }
        window.addEventListener('updateTree', () => {
            setTimeout(
                this.redrawTree, 500);


        });
    }

    drawTree = () => {
        this.LoadMorphoTree();
        this.state.morphoPhyloTree.branchLengthLabelPredicate();
        this.state.morphoPhyloTree.setNodeSize(10);
        this.state.morphoPhyloTree.setTextSize(20/**(window.screen.width/window.innerWidth)*1.2*/);
        this.state.morphoPhyloTree.highlightColour = '#44D4FF';
        this.state.morphoPhyloTree.selectedColour = '#44D4FF';
        //tree.branches.E.collapsed = true;
        this.state.morphoPhyloTree.offsetx = this.phylotreecontainer.current.offsetWidth * this.phylocanvas_starting_ratio;
        this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
        this.state.morphoPhyloTree.resizeToContainer();
        this.state.morphoPhyloTree.draw();
        this.state.morphoPhyloTree.getBounds(this.state.morphoPhyloTree.leaves);
        this.select_branch();
    }

    redrawTree = () => {
        if (this.props.morpho_tree != this.state.previous_phylotree){
            this.phylocanvas_starting_ratio = 0.4;
            this.setState({previous_phylotree:this.props.morpho_tree});
            this.drawTree();
        }
    }

    resize = () => {
        this.setState({currentWidth: window.innerWidth});
        this.setState({currentHeight: window.innerHeight});
        var transition = (this.state.initWidth - this.state.currentWidth);
        if (window.innerWidth >= this.state.initWidth) {
            this.state.morphoPhyloTree.offsetx = this.state.initXoffset * (this.state.currentWidth / this.state.initWidth);
            this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
        } else {
            if ((this.state.currentWidth / this.state.initWidth) <= this.phylocanvas_retracted_ratio) {
                this.state.morphoPhyloTree.offsetx = 0;
                this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
            } else {
                this.state.morphoPhyloTree.offsetx = this.state.initXoffset * (this.state.currentWidth / this.state.initWidth) * this.phylocanvas_moving_ratio < 0 ? 0 : this.state.initXoffset * (this.state.currentWidth / this.state.initWidth) * this.phylocanvas_moving_ratio;
                this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
            }
        }
        /*this.state.morphoPhyloTree.offsetx = this.state.morphoPhyloTree.offsetx - transition < 0?0:this.state.morphoPhyloTree.offsetx - transition;
        this.state.morphoPhyloTree.offsetx = this.state.morphoPhyloTree.offsetx >= this.state.initXoffset ? this.state.initXoffset : this.state.morphoPhyloTree.offsetx;*/
        this.state.morphoPhyloTree.resizeToContainer();
        this.state.morphoPhyloTree.draw();

    }
        resize_backup = () => {
        this.setState({currentWidth: window.innerWidth});
        this.setState({currentHeight: window.innerHeight});
        const transition = (this.state.initWidth - this.state.currentWidth);
        if (window.innerWidth >= this.state.initWidth) {
            this.state.morphoPhyloTree.offsetx = this.state.initXoffset * (this.state.currentWidth / this.state.initWidth);
            this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
        } else {
            if ((this.state.currentWidth / this.state.initWidth) <= this.phylocanvas_retracted_ratio) {
                this.state.morphoPhyloTree.offsetx = 0;
                this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
            } else {
                this.state.morphoPhyloTree.offsetx = this.state.initXoffset * (this.state.currentWidth / this.state.initWidth) * this.phylocanvas_moving_ratio < 0 ? 0 : this.state.initXoffset * (this.state.currentWidth / this.state.initWidth) * this.phylocanvas_moving_ratio;
                this.state.morphoPhyloTree.offsety = this.phylocanvas_y_offset;
            }
        }
        /*this.state.morphoPhyloTree.offsetx = this.state.morphoPhyloTree.offsetx - transition < 0?0:this.state.morphoPhyloTree.offsetx - transition;
        this.state.morphoPhyloTree.offsetx = this.state.morphoPhyloTree.offsetx >= this.state.initXoffset ? this.state.initXoffset : this.state.morphoPhyloTree.offsetx;*/
        this.state.morphoPhyloTree.resizeToContainer();
        this.state.morphoPhyloTree.draw();
        this.select_branch();
    }

    select_branch = () => {
        if (this.props.current_filtered_tree_branch !== null && this.props.current_filtered_tree_branch !== -1){
            axios({
                    method: 'get',
                    url: `/api/ncbinamebyid/`,
                    params: {id: this.props.current_filtered_tree_branch},
                }
            ).then(res => {
                this.state.morphoPhyloTree.leaves.map((l) => {
                    if (l.id === res.data){
                        l.selected = true;
                        this.state.morphoPhyloTree.nodesUpdated([l], "selected")
                    }
                });
            });
    }}
    componentDidUpdate = (prevProps, prevState) => {


        if (this.state.tree_loaded !== prevState.tree_loaded && this.state.tree_loaded === true) {
            this.state.morphoPhyloTree.on('click', (e) => {
                const node = this.state.morphoPhyloTree.getNodeAtMousePosition(e);
                if (node !== undefined) {
                    SendTracking(-1, "Web,Dataset,Phylotree,click");

                    axios({
                            method: 'get',
                            url: `/api/ncbiidbyname/`,
                            params: {name: node.id},
                        }
                    ).then(res => {
                        this.filterByCategory(res.data, node.id);
                    })
                } else {
                    this.filterByCategory(-1, "");
                }
            });
        }
    };

    resetTreeCategorySelection = () => {
        if (this.state.tree_loaded) {
            SendTracking(-1, "Web,Dataset,Phylotree,reset");
            this.state.morphoPhyloTree.clearSelect();
            this.filterByCategory(-1, "");
        }
    }


    render() {

        return (
            <div>
                <h3 style={{fontSize:"20px",marginLeft:"5px",marginTop:"10px",textAlign:"unset"}}> Filter by taxonomy</h3>
                <div id="phylocanvas" style={{width: "100%", height: "100%", resize: 'none'}}
                     hidden={this.state.hidden_tree} ref={this.phylotreecontainer}></div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        datasets: state.dataset.entities,
        current_filtered_tree_branch:state.dataset.current_filtered_tree_branch,
        morpho_tree: state.dataset.current_phylotree,
        connected : state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        selectTreeBranch: (tree_branch) => dispatch(SetTreeFilter(tree_branch)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhyloTree);