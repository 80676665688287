import React from 'react';
import axios from 'axios';
import { Menu, Tooltip } from 'antd';
import Simulations from '../components/Simulations';
import SimulationDescription from '../components/SimulationDescription';
import SimulationSettings from '../components/SimulationSettings';
import {encryptString,getUserToken,getUserID,postNotification, isUserStaff} from '../store/utility';
import { Link, NavLink } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
const { SubMenu } = Menu;


class SimulationList extends React.Component {
    constructor(props) {
        super(props);
        this.handleSimulationClick = this.handleSimulationClick.bind(this);
    }
    state = {
        display_mode: "",
        refresh: false,
        clicked_simulation: {},
        simulation_size: -1,
        simulation_version: "",
        users_tag: [],
        fullsimulations: [],
        privatesimulations: [],
        publicsimulations: [],
        simulations: [],
        filtersimulations: [],
        updater: false,
        sortCount: 0,
        private_updated: false,
        public_updated: false,
        clicked_date: false,
        clicked_name: false,
        clicked_privacy: false,
        selected_simulation: "",
        filter_type: "",
        filter_privacy: "",
        sort_method: "",
        reset_simulations: false,
        token: "",
        simulations_list: "",
        source_string_created: false,
        copy_created: false,

        user_contact: null,

        user_settings: [],
        reset_simulation_layout_child: false,
        name_loaded: false,
        tutorial_ready: false,
        selected_session: -1,
        selected_session_id: -1,
        ask_clear: false,
        beta: false,
        duplicate: false,
    }

    getUserSettingsValue(p_setting) {
        let value = -1;
        if (this.state.user_settings !== undefined) {
            this.state.user_settings.map(sett => {
                if (sett.setting === p_setting) {
                    value = sett.value;
                }
            });
        }
        return value;
    }

    async componentDidUpdate(nextProps, nextState) {
        if (this.props.unityLoaded !== nextProps.unityLoaded) {
            this.setState({ unity_loaded: this.props.unityLoaded });
        }

        if (this.props.tutorialReady !== this.state.tutorial_ready && this.props.tutorialReady === true && !this.state.tutorial_ready) {
            this.setState({ tutorial_ready: true });
            setTimeout(() => {
                const new_node = ReactDOM.findDOMNode(this.refs.resetsortMenu);
                const new_node2 = ReactDOM.findDOMNode(this.refs.sortDateMenu);
                const new_node3 = ReactDOM.findDOMNode(this.refs.sortNameMenu);
                const new_node4 = ReactDOM.findDOMNode(this.refs.typeFilterMenu);

                this.props.displayTutorial(undefined, 'center', 'center', `This page is the list of the simulations`, 'tutorial-simulation', false);
                this.props.displayTutorial(new_node2, 'center', 'top', `You can sort using dates!`, 'tutorial-simulation', false);

                this.props.displayTutorial(new_node, 'center', 'top', `You can reset filter and sort by click this!`, 'tutorial-simulation', false);

                if (this.state.token !== "" && this.state.token !== null) {
                    const new_node9 = ReactDOM.findDOMNode(this.refs.upload);
                    this.props.displayTutorial(new_node9, 'center', 'center', `Access simulation upload page`, 'tutorial-simulation', true);
                }
            }, 1000);
        }

        if (this.props.simulationClicked !== nextProps.simulationClicked) {
            this.setState({ filter_privacy: "All" });
            this.state.filter_type = "all";
            this.setState({ sort_method: "DateAsc" });
            this.ApplySimulationsSorting();
        }

        if (nextState.fullsimulations !== this.state.fullsimulations) {
            const simulation_name_list = [];
            this.state.fullsimulations.map(simulation => {
                if (!simulation_name_list.includes(simulation.name)) {
                    simulation_name_list.push(simulation.name);
                }
            });

            this.state.simulations_list = ""
            this.state.fullsimulations.map(simulation => {
                if (simulation !== undefined) {
                    this.state.simulations_list += simulation.name + ","
                }
            });
        }

        if (this.state.source_string_created !== nextState.source_string_created && this.state.source_string_created) {
            this.setState({ copy_created: true });
        }

        if (nextState.fullsimulations !== this.state.fullsimulations) {
            const simulationNames = [];
            this.state.fullsimulations.map(simulation => {
                simulationNames.push(simulation.name);
            });
        }

        if (nextState.public_updated !== this.state.public_updated || nextState.private_updated !== this.state.private_updated) {
            if ((this.state.private_updated && this.state.public_updated) || ((this.state.token === null || this.state.token === "") && this.state.public_updated)) {
                this.state.simulations = this.state.publicsimulations.concat(this.state.privatesimulations);
                this.state.simulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                this.setState({ fullsimulations: this.state.simulations });
                this.state.simulations.map(simulation => {
                    if (this.props.location !== undefined && this.props.location.preChoosenSimulation !== undefined) {
                        const simulation_name = this.props.location.preChoosenSimulation;
                        if (simulation.name === simulation_name) {
                            this.setState({ clicked_simulation: simulation });
                        }
                    }
                });

                if (this.props.location !== undefined && this.props.location.preChoosenSimulation !== undefined) {
                    var simulation_name = this.props.location.preChoosenSimulation;
                    this.setState({ loaded_from_props: true });
                    this.setState({ display_mode: "description" });
                    this.setState({ refresh: !this.state.refresh });
                    this.handleSimulationClick(simulation_name);
                    this.props.location.preChoosenSimulation = undefined;

                    window.scrollTo(window.scrollX + 1, window.scrollY + 1); window.scrollTo(window.scrollX - 1, window.scrollY - 1);
                }
            }
        }
    }

    updateASimulation = (new_simulation_values, name_simulation) => {
        this.state.fullsimulations = this.state.fullsimulations.map(simulation => {
            let simulation_cpy = simulation;
            if (simulation.name === name_simulation) {
                simulation_cpy = new_simulation_values;
                this.selectASimulation(simulation_cpy);
                return simulation_cpy;
            }
            else
                return simulation;
        });
    }

    componentDidMount() {
        this.state.token = getUserToken();
        this.state.connected_user_id = getUserID();
        this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
        window.addEventListener('disconnect', () => { this.componentDidMount(); })
        if (this.state.token !== undefined && this.state.token !== "")
            axios.get(`/api/usercontacts/`, { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        user_contact: res.data
                    });
                });

        if (this.state.token !== null && this.state.token !== "") {
            axios.get('/api/people-betatest/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ beta: res.data['beta'] });
                });

            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ staff: res.data['staff'] });
                });
        }

        if (this.state.token !== undefined && this.state.token !== "") {

            axios.get('/api/usersimulations/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    let the_simulations = [];
                    the_simulations = res.data['list'];
                    this.setState({
                        privatesimulations: the_simulations,
                        private_updated: true,
                    });
                });

            axios.get('/api/usertags/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({ users_tag: res.data });
                });
        }

        axios.get('/api/publicsimulations/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                let the_simulations = [];
                the_simulations = res.data['list'];
                this.setState({
                    publicsimulations: the_simulations,
                    public_updated: true,
                });
            });

        if (this.state.token !== "" && this.state.token !== null) {
            axios.get(`/api/settings/`, { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({
                        user_settings: res.data,
                    });
                });
        }

        if (this.props.data !== null) {
            const simulations_copy = [];

            Object.assign(simulations_copy, this.props.data);

            this.state.simulations_list = ""
            this.setState({ create_session_opened: false });
            this.setState({ simulations: simulations_copy });

            window.scrollTo(window.scrollX + 1, window.scrollY + 1);
            window.scrollTo(window.scrollX - 1, window.scrollY - 1);
        }
    }

    selectASimulation = (simulation) => {
        this.setState({ display_mode: "description" });
        this.setState({ clicked_simulation: simulation });
        this.setState({ refresh: !this.state.refresh });
    }

    unSelectSimulation = () => {
        this.setState({ clicked_simulation: null });
        this.setState({ display_mode: "" });
        this.setState({ refresh: !this.state.refresh });
    }

    Duplicate = (simulation) => {
        let formData = new FormData();
        formData.append('name', simulation.name);

        axios({
            method: 'post',
            url: `/api/duplicatesimulation/`,
            headers: { Authorization: `Token ${this.state.token}` },
            data: formData,
        }).then(res => {
            const the_simulations = [];
            simulation = res.data['simulation'];
            this.setState({ fullsimulations: simulation.concat(this.state.fullsimulations)});
            postNotification(<h1 style={{ color: '#44D4FF', fontSize: '18px' }}>Simulation duplicated.</h1>, 'The  simulation "' + this.state.clicked_simulation.name + '" has been duplicate ');
        }).catch(err => this.setState({ simulation_error: err }));
    }

    ShareWithPublic = (simulation) => {
        let formData = new FormData();
        formData.append('name', simulation.name);
        formData.append('version', simulation.version);

        axios({
            method: 'post',
            url: `/api/sharesimulation/`,
            headers: { Authorization: `Token ${this.state.token}` },
            data: formData,
        }).then(res => {
            const the_simulations = [];
            simulation = res.data['simulation'];
            this.setState({ fullsimulations: simulation.concat(this.state.fullsimulations) });
            postNotification(<h1 style={{ color: '#44D4FF', fontSize: '18px' }}>Simulation shared.</h1>, 'The  simulation "' + this.state.clicked_simulation.name + '" has been shared with public ');
        }).catch(err => this.setState({ simulation_error: err }));
    }

    applyFilterSimulation() {
        this.state.filtersimulations = this.state.fullsimulations;

        switch (this.state.sort_method) {
            case "DateAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "DateDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate < bdate ? -1 : adate > bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "NameAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
            case "NameDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
        }

        switch (this.state.filter_privacy) {
            case "Public":
                this.state.simulations = this.state.filtersimulations.filter(
                    simulation => this.SimulationIsPublic(simulation.lpy));
                break;

            case "User":
                this.state.simulations = this.state.filtersimulations.filter(
                    simulation => !this.SimulationIsPublic(simulation.lpy)
                )
                break;
            default:
                this.state.simulations = this.state.filtersimulations;
                break;
        }


        return this.state.simulations;
    }

    filterSimulation() {
        let result = undefined;
        result = this.applyFilterSimulation();
        return result;
    }

    SimulationIsPublic = (lpy) => {
        const path = lpy;
        if (path !== null && path !== undefined) {
            if (path.startsWith('simulations_public/'))
                return true;
        }
        return false;
    }


    simulationSorting() {
        this.state.filtersimulations = this.state.fullsimulations;

        switch (this.state.sort_method) {
            case "DateAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "DateDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        const adate = new Date(a.date);
                        const bdate = new Date(b.date);
                        return adate < bdate ? -1 : adate > bdate ? 1 : 0;
                    }.bind(this));
                break;
            case "NameAsc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
            case "NameDesc":
                this.state.filtersimulations.sort(
                    function (a, b) {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0;
                    }.bind(this));
                break;
        }

        switch (this.state.filter_privacy) {
            case "Public":
                this.state.simulations = this.state.filtersimulations.filter(
                    simulation => this.SimulationIsPublic(simulation.lpy));
                break;

            case "User":
                this.state.simulations = this.state.filtersimulations.filter(
                    simulation => !this.SimulationIsPublic(simulation.lpy));
                break;
            default:
                this.state.simulations = this.state.filtersimulations;
                break;
        }

        this.setState({ reset_simulations: !this.state.reset_simulations });
    }

    backToDescription = () => {
        this.setState({ display_mode: "description" });
    }

    ApplySimulationsSorting() {
        this.simulationSorting();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleSimulationClick(name) {
        this.setState({ selected_simulation: name });
    }

    SortKeys() {
        const result = [];
        switch (this.state.sort_method) {
            case 'DateAsc':
                result.push('1');
                break;
            case 'DateDesc':
                result.push('2');
                break;
            case 'NameAsc':
                result.push('3');
                break;
            case 'NameDesc':
                result.push('4');
                break;
            default:
                break;
        }
        return result;
    }

    PrivacyKeys() {
        switch (this.state.filter_privacy) {
            case 'User':
                return '2';
            case 'Public':
                return '3';
            default:
                return '1';
        }
    }

    getUserStatus = (user_id) => {
        const status = "norights";
        if (this.state.clicked_simulation.id_people === user_id) {
            return "manager";
        }
        return status;
    }
    
    isUserStaff = () => {
        return (this.state.staff);
    }


    render() {
        let search_input = true;
        if (document.getElementById('custom_input_text') !== null) {
            search_input = (this.props.searched_text === "");
        }
        return (
            <div>
                {
                    (this.state.clicked_simulation === null || this.state.clicked_simulation === {} || this.state.display_mode === "") &&
                    <div style={{ display: 'table-row', height: this.state.selected_simulation === "" || this.state.clicked_simulation === {} || this.state.clicked_simulation === null ? '15vh' : '0vh', width: '75%', maxHeight: '60px', float: 'right' }}>

                        <div style={{ float: "right", width: '100%', alignContent: 'center' }}>
                            <Menu selectedKeys={this.SortKeys()} mode="horizontal" style={{ width: '50%', float: 'left' }}>
                                <Menu.Item ref='resetsortMenu' className="removeSelectedBorder" style={{ marginLeft: '2px', marginRight: '2px' }} key="9" onClick={() => {
                                   /* this.props.sendTrackingSimulation(-1, "Web,SimulationList,Filter,reset");*/ this.props.updateClearAsk(); this.props.updateLastSearched("search"); this.setState({ filter_privacy: "All" }); this.state.filter_type = "all"; this.setState({ sort_method: "" }); this.ApplySimulationsSorting();
                                }}>
                                    <Tooltip placement="bottom" title={<span>Reset filter and sort</span>}><img alt="Login" width='27' src="/images/refonte/oload.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>
                                <Menu.Item ref='sortDateMenu' style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="1" onClick={() => {
                                    /*this.props.sendTrackingSimulation(-1, "Web,SimulationList,Sort,DateAsc");*/this.state.sort_method = "DateAsc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_date: !this.state.clicked_date }); this.ApplySimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by date (new first)</span>}><img alt="Login" width='30' src="/images/refonte/date.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="2" onClick={() => {
                                    /*this.props.sendTrackingSimulation(-1, "Web,SimulationList,Sort,DateDesc");*/ this.state.sort_method = "DateDesc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_date: !this.state.clicked_date }); this.ApplySimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by date (old first)</span>}><img alt="Login" width='30' src="/images/refonte/invdate.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item ref='sortNameMenu' style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="3" onClick={() => {
                                   /* this.props.sendTrackingSimulation(-1, "Web,SimulationList,Sort,NameAsc");*/ this.state.sort_method = "NameAsc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_name: !this.state.clicked_name }); this.ApplySimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by alphabetic order</span>}><img alt="Login" width='30' src="/images/refonte/sort-by-alphabet.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>

                                <Menu.Item style={{ marginLeft: '2px', marginRight: '2px' }} className="removeSelectedBorder" key="4" onClick={() => {
                                    /*this.props.sendTrackingSimulation(-1, "Web,SimulationList,Sort,NameDesc");*/ this.state.sort_method = "NameDesc"; this.setState({ sortCount: this.state.sortCount + 1 }); this.setState({ clicked_name: !this.state.clicked_name }); this.ApplySimulationsSorting()
                                }}>
                                    <Tooltip placement="bottom" title={<span>Sort by reverse alphabetic order</span>}><img alt="Login" width='30' src="/images/refonte/sort-reverse-alphabetical-order.png" style={{ mixBlendMode: 'multiply' }}></img></Tooltip>
                                </Menu.Item>
                            </Menu>
                            {
                                this.state.token !== "" && this.state.token !== null &&
                                    <Menu mode="horizontal" selectedKeys={this.PrivacyKeys()} defaultSelectedKeys={'1'} style={{ width: '15%', float: 'right' }}>
                                        <SubMenu ref='rightsFilterMenu' style={{ borderBottom: 'none' }} icon={<SettingOutlined />} title={<div><img alt="Login" width='35' src={this.state.filter_privacy === "All" || this.state.filter_privacy === "" ? "/images/refonte/users-group.png" : this.state.filter_privacy === "User" ? "/images/refonte/man-user.png" : "/images/refonte/opendata.png"} style={{ mixBlendMode: 'multiply' }}></img><span style={{ marginLeft: '4px', color: '#44D4FF', fontSize: '17px' }}>{this.state.filter_privacy === "All" || this.state.filter_privacy === "" ? "All" : this.state.filter_privacy === "User" ? "User" : "Public"}</span></div>}>
                                            <Menu.Item className="removeSelectedBorder" key="1" onClick={() => {
                                                /*this.props.sendTracking(-1, "Web,SimulationList,Filter,Privacy,all");*/ this.state.filter_privacy = "All"; this.setState({ clicked_privacy: !this.state.clicked_privacy }); this.ApplySimulationsSorting()
                                            }}>
                                                <Tooltip placement="bottom" title={<span>All simulations</span>}><img alt="Login" width='30' src="/images/refonte/users-group.png" style={{ mixBlendMode: 'multiply' }}></img><span style={{ color: '#44D4FF', fontSize: '17px' }}> All simulations</span></Tooltip>
                                            </Menu.Item>

                                            <Menu.Item className="removeSelectedBorder" key="2" onClick={() => {
                                                /*this.props.sendTracking(-1, "Web,SimulationList,Filter,Privacy,user");*/ this.state.filter_privacy = "User"; this.setState({ clicked_privacy: !this.state.clicked_privacy }); this.ApplySimulationsSorting()
                                            }}>
                                                <Tooltip placement="bottom" title={<span>User simulations only</span>}><img alt="Login" width='30' src="/images/refonte/man-user.png" style={{ mixBlendMode: 'multiply' }}></img><span style={{ color: '#44D4FF', fontSize: '17px' }}> User simulations</span></Tooltip>
                                            </Menu.Item>

                                            <Menu.Item className="removeSelectedBorder" key="3" onClick={() => {
                                                /*this.props.sendTracking(-1, "Web,SimulationList,Filter,Privacy,public");*/ this.state.filter_privacy = "Public"; this.setState({ clicked_privacy: !this.state.clicked_privacy }); this.ApplySimulationsSorting()
                                            }}>
                                                <Tooltip placement="bottom" title={<span>Public simulations only</span>}><img alt="Login" width='30' src="/images/refonte/opendata.png" style={{ mixBlendMode: 'multiply' }}></img><span style={{ color: '#44D4FF', fontSize: '17px' }}> Public simulations</span></Tooltip>
                                            </Menu.Item>

                                        </SubMenu>
                                    </Menu>
                            }

                        </div>

                    </div>
                }
                <div>
                    <div style={{ display: 'table-row', height: '15vh', marginTop: '13%', width: '25%', maxHeight: '100px', float: 'left', position: 'relative' }}>
                        {
                            this.state.token !== "" && this.state.token !== null &&
                            <div ref='upload'><h1 className='sub-titles-text' style={{ marginTop: '20px', fontSize: '34px' }}> Simulation </h1>
                                <Tooltip placement="bottom" title={<span>Upload simulation</span>}>
                                    <Link exact to={{ pathname: `/simulation-upload` }}><img alt="Logout" onClick={() => {
                                        /*this.props.sendTrackingSimulation(-1, "Web,Simulation,Upload,view");*/
                                    }} height='50' src="/images/refonte/add.png" style={{ paddingBottom: '10px', alignItems: 'center', marginLeft: '10px', mixBlendMode: 'multiply', cursor: 'pointer', display: 'inline' }} /></Link>
                                </Tooltip>
                                <br></br>
                                <br></br>
                                <br></br></div>
                        }
                    </div>

                </div>
                <div style={{ display: 'table-row', width: '73%', float: 'right', position: 'relative' }}>
                    {
                        this.state.clicked_simulation !== null && this.state.clicked_simulation !== {} && this.state.display_mode !== "" &&
                        <Tooltip placement="bottom" title={<span>Back to simulation list</span>}>
                            <img alt="back" onClick={() => { this.unSelectSimulation(); }} style={{ marginRight: '20px', cursor: 'pointer' }} width={30} src="/images/refonte/back.png"></img>
                        </Tooltip>
                    }
                    {
                        this.state.clicked_simulation !== null && this.state.clicked_simulation !== {} && this.state.display_mode !== "" &&
                        <div>

                            {<NavLink exact to={{ pathname: `/morphosimul`, preChoosenSimulation: this.state.clicked_simulation }}>
                                <img alt={"simulation load"} onContextMenu={(evt) => { evt.preventDefault(); localStorage.setItem('unityProps', encryptString(JSON.stringify(this.state.clicked_simulation))); window.open(window.location.href, '_blank'); return false; }}
                                    onClick={() => {
                                        localStorage.setItem('unityProps', encryptString(JSON.stringify(this.state.clicked_simulation))); window.location.href = "/morphosimul"; /*window.open(window.location.href, '_blank');*/ return false;
                                    }} style={{ cursor: 'pointer', marginLeft: '0px', borderRadius: '10px', marginRight: '2px' }} width={50} src={"/images/refonte/load_small.png"}></img>
                            </NavLink>
                            }

                            {
                                this.state.token !== "" && this.state.token !== undefined && <Tooltip placement="bottom" title={<span>Display description for this simulation</span>}><img alt="Description" onClick={() => {
                                    /* this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Description,view");*/
                                    this.setState({ display_mode: "description" })
                                }} style={{ paddingBottom: '1px', marginLeft: '10px', marginRight: '10px', cursor: 'pointer', borderBottom: this.state.display_mode === "description" ? '2px solid  #44D4FF' : 'unset' }} width={30} src="/images/refonte/session.png"></img></Tooltip>
                            }
                            {(!this.SimulationIsPublic(this.state.clicked_simulation.lpy) || (this.SimulationIsPublic(this.state.clicked_simulation.lpy) && isUserStaff())) &&
                                <Tooltip placement="bottom" title={<span>Display settings for this simulation</span>}><img alt="Settings" onClick={() => {
                                    /*this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Settings,view");*/
                                    this.setState({ display_mode: "settings" })
                                }} style={{ marginRight: '10px', cursor: 'pointer', borderBottom: this.state.display_mode === "settings" ? '2px solid  #44D4FF' : 'unset' }} width={30} src="/images/refonte/parameters.png"></img></Tooltip>
                            }
                            {
                                this.SimulationIsPublic(this.state.clicked_simulation.lpy) &&
                                <Tooltip placement="bottom" title={<span>Duplicate this simulation</span>}>
                                    <img alt="Duplicate" onClick={() => {
                                        /*this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Settings,view");*/
                                        this.Duplicate(this.state.clicked_simulation); this.props.updateClearAsk(); this.props.updateLastSearched("search"); this.setState({ filter_privacy: "All" }); this.state.filter_type = "all"; this.setState({ sort_method: "" }); this.ApplySimulationsSorting(); this.setState({ display_mode: "" });
                                    }} style={{ marginRight: '10px', cursor: 'pointer', borderBottom: 'unset' }} width={30} src="/images/converters.png"></img>
                                </Tooltip>
                            }

                            {
                                !this.SimulationIsPublic(this.state.clicked_simulation.lpy) &&
                                <Tooltip placement="bottom" title={<span>Share with public</span>}>
                                    <img alt="Share public" onClick={() => {
                                        /*this.props.sendTrackingSimulation(this.state.clicked_simulation.name, "Web,Simulation,Settings,view");*/
                                        this.ShareWithPublic(this.state.clicked_simulation); this.props.updateClearAsk(); this.props.updateLastSearched("search"); this.setState({ filter_privacy: "All" }); this.state.filter_type = "all"; this.setState({ sort_method: "" }); this.ApplySimulationsSorting(); this.setState({ display_mode: "" });
                                    }} style={{ marginRight: '10px', cursor: 'pointer', borderBottom: 'unset' }} width={30} src="/images/Share.png"></img>
                                </Tooltip>
                            }

                        </div>
                    }
                    {
                        this.clicked_simulation !== null && this.clicked_simulation !== undefined && this.clicked_simulation.path !== null && this.clicked_simulation.path !== undefined && this.SimulationIsPublic(this.state.clicked_simulation.lpy) &&
                        <Tooltip placement="bottom" title={<span>This simulation is public</span>}><img alt="Public simulation" style={{ float: 'right', marginRight: '4px' }} width={35} src="/images/refonte/opendata.png" /></Tooltip>

                    }
                    { //Describe the simulation when you click on the name
                        this.state.display_mode === "description" ?
                            <SimulationDescription {...this.props} clicked_simulation={this.state.clicked_simulation} simulation_size={this.state.simulation_size} public_simulation={this.state.public_simulaiton === {} || this.state.public_simulation === null ? null : this.SimulationIsPublic(this.state.clicked_simulation.lpy)}></SimulationDescription>
                            :
                            this.state.display_mode === "settings" ?
                                <SimulationSettings {...this.props} updateASimulation={this.updateASimulation} selectASimulation={this.selectASimulation} users_tag={this.state.users_tag} backToDescription={this.backToDescription} clicked_simulation={this.state.clicked_simulation} simulation_size={this.state.simulation_size}></SimulationSettings>
                                :
                                <Simulations{...this.props}
                                            refreshSimulation={this.state.refresh} user_contact={this.state.user_contact} selectASimulation={this.selectASimulation} unSelectSimulation={this.unSelectSimulation} nameLoaded={this.state.name_loaded} layoutReset={this.state.reset_simulation_layout_child} usettings={this.state.user_settings} resetSimulations={this.state.reset_simulations} onSelectedSimulation={this.handleSimulationClick} sortCount={this.state.sortCount} data={this.state.display_mode !== "" ? null : this.filterSimulation()} simulationClicked={this.state.clicked_simulation}> </Simulations>
                    }

                </div>
            </div>
        );
    }
}

export default SimulationList;