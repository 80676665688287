import {createEntityAdapter, createSlice,} from '@reduxjs/toolkit'

const initialState = {}

const sharingAdapter = createEntityAdapter({})

const sharingSlice = createSlice({
    name: 'sharing', initialState: sharingAdapter.getInitialState(initialState), reducers: {
        sharingAdded: sharingAdapter.addOne, // dispatch(datasetReceived({ id: '', ... }))
        sharingListAdded: sharingAdapter.addMany, //dispatch(datasetUpdated({ id: '', changes: {... } }))
        sharingUpdated: sharingAdapter.updateOne,
        sharingListUpdated: sharingAdapter.setAll, // How to use each action of the reducer
        // dispatch(datasetAdded({ id: '', ... }))

    },
})

export const {sharingAdded, sharingListAdded, sharingUpdated, sharingListUpdated} = sharingSlice.actions
// ??
export default sharingSlice.reducer