import React from "react";
import {
    ChangeSessionDefault,
    ChooseSetFromUrl,
    DeleteSelectedSession,
    UpdateExistingSession
} from "../utils/api_callbacks";
import {connect} from "react-redux";
import {Button, Checkbox, Icon, InputNumber, List, Radio, Slider, Switch, Tooltip} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {IsAdminOrCreator, IsAtLeastManager, qualityformatter} from "../utils/tools";
import TextArea from "antd/es/input/TextArea";
import * as UnityInfos from "./UnityInfos";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";

function ApplyFiltering(data, filtertype, chosen_set) {
    if (filtertype === "choosenset") {
        return data.filter(key => key.id === chosen_set);
    }

    return data;
}

class SessionUpdateSession extends React.Component {

    state = {
        session_id: -1,
        updatesession_defaut: -1,
        updatesession_name: "",
        updatesession_corCount: 0,
        updatesession_bttnCount: 0,
        updatesession_url: "",
        updatesession_mintime : undefined,
        updatesession_maxtime : undefined,
    }

    getSessionId = (url) => {
        return parseInt(url.match(/^\d+|\d+\b|\d+(?=\w)/g)[1])
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
        this.setState({session_id: this.getSessionId(window.location.pathname)})
    }


    UpgradeUrlUpdateSession = (type, p_value, obj_id) => {
        let alreadyexist = false;
        switch (type) {
            case "cor":

                var parameters = this.state.updatesession_url.split('&').map(param => {
                    if (param.includes("cor") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        return oldparam + "=" + p_value + "_" + obj_id;
                    }
                    return param;
                });

                this.state.updatesession_url = parameters.join('&')

                if (!alreadyexist) {
                    this.state.updatesession_url += "&cor" + this.state.updatesession_corCount + "=" + p_value + "_" + obj_id;
                    this.state.updatesession_corCount++;
                }
                break;

            case "bttn":
                var parameters = this.state.updatesession_url.split('&').map(param => {
                    if (param.includes("bttn") && param.includes("_" + obj_id)) {
                        alreadyexist = true;
                        const oldparam = param.split('=')[0];
                        return oldparam + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    }
                    return param;
                });
                this.state.updatesession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.updatesession_url += "&bttn" + this.state.updatesession_bttnCount + "=" + (p_value === true ? "show" : "hide") + "_" + obj_id;
                    this.state.updatesession_bttnCount++;
                }
                break;

            case "quality":
                // console.log("update to : "+p_value);
                var parameters = this.state.updatesession_url.split('&').map(param => {
                    if (param.includes("quality")) {
                        alreadyexist = true;
                        return "quality=" + p_value;
                    }
                    return param;
                });
                this.state.updatesession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.updatesession_url += "&quality=" + p_value;
                }
                this.setState({selected_quality: p_value});
                break;

            case "deftime":
                var parameters = this.state.updatesession_url.split('&').map(param => {
                    if (param.includes("time")) {
                        alreadyexist = true;
                        return "time=" + p_value;
                    }
                    return param;
                });
                this.state.updatesession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.updatesession_url += "&time=" + p_value;
                }
                break;

            case "minmax":
                this.setState({updatesession_mintime:p_value[0]});
                this.setState({updatesession_maxtime:p_value[1]});
                var parameters = this.state.updatesession_url.split('&').map(param => {
                    if (param.includes("minTime")) {
                        alreadyexist = true;
                        return "minTime=" + p_value[0];
                    }
                    if (param.includes("maxTime")) {
                        alreadyexist = true;
                        return "maxTime=" + p_value[1];
                    }
                    return param;
                });
                this.state.updatesession_url = parameters.join('&')
                if (!alreadyexist) {
                    this.state.updatesession_url += "&minTime=" + p_value[0] + "&maxTime=" + p_value[1];
                }
                break;
        }
    }

    IsButtonInUrl = (url, button) => {
        let flag = false;
        const parameters = url.split('&');
        parameters.map(param => {
            if (param.includes("bttn") && param.includes("_" + button)) {
                flag = true
            }
        });
        return flag;
    }

    getValueFromUrl = (url, parameter) => {
        if (url === undefined) {
            return null;
        }
        const parameters = url.split('&');
        let result = null;
        parameters.map(param => {
            if (param.includes(parameter)) {
                result = param.split('=')[1]
            }
        });
        return result;
    }

    getButtonFromUrl = (url, buttn_name) => {
        const parameters = url.split('&');
        let parameter = null;
        parameters.map(param => {
            if (param.includes("bttn") && param.includes("_" + buttn_name)) {
                parameter = param.split('=')[1];
            }
        });
        let type = "show";
        if (parameter !== null)
            type = parameter.split('_')[0];
        return type;
    }

    getValueForParameter = (session, parameter) => {
        return this.getValueFromUrl(this.state.updatesession_url, parameter) !== null ?
            this.getValueFromUrl(this.state.updatesession_url, parameter) :
            this.getValueFromUrl(session.url, parameter) !== null ?
                this.getValueFromUrl(session.url, parameter) : null;
    }


    getCorrespondenceFromFirstUrl = (session, id_cor) => {
        let type = "show";
        var parameter = null;
        this.state.updatesession_url.split('&').map(param => {
            if (param.includes("cor") && param.includes("_" + id_cor)) {
                parameter = param.split('=')[1];
            }
        });

        if (parameter !== null) {
            type = parameter.split('_')[0];
        } else {
            const parameters = session.url.split('&');
            var parameter = null;
            parameters.map(param => {
                if (param.includes("cor") && param.includes("_" + id_cor)) {
                    parameter = param.split('=')[1];
                }
            });

            if (parameter !== null)
                type = parameter.split('_')[0];
        }
        return type;
    }

    render() {
        if (this.state.session_id === -1) {
            return <div></div>
        }
        const datasets = this.props.datasets;
        if (datasets === undefined) return <div></div>
        const dataset = datasets[this.props.chosen_set]
        if (dataset === undefined) return <div></div>
        const quality_count = this.props.set_quality_by_id[dataset.id];
        const correspondence_list = Object.values(this.props.infos).filter(info => info.id_dataset === this.props.chosen_set);
        let session = undefined;
        if (this.props.sessions !== undefined && this.props.sessions !== []){
            session = ApplyFiltering(Object.values(this.props.sessions), "choosenset", this.state.session_id)[0];
        }
        if (session === undefined){return <div></div>}
        return (
            <div>
                <ChosenSetTopPanel {...this.props} set={dataset}></ChosenSetTopPanel>
                <br></br>
                {dataset!==undefined &&<p className={"DatasetDescriptionName"}>{dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${dataset.id})` : ""}</p>}
                <span style={{marginTop:"1%",fontSize: '21px', color: 'black'}}>Update a session</span>
                {this.props.connected && IsAtLeastManager(this.props.chosen_set) &&
                    <div><br></br>
                        <br></br>
                        <div>
                            <div>
                                <span style={{marginLeft: '1%', fontSize: '17px'}}> Set as default</span>
                                <Tooltip placement="top" title={this.state.updatesession_defaut === 1 ?
                                    <span>The session is the default dataset parameters</span> :
                                    <span>Choose this session as default dataset parameters</span>}><Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    checked={this.state.updatesession_defaut === -1 ? session.default === 1 : this.state.updatesession_defaut === 1}
                                    onChange={(checked) => {
                                        this.setState({updatesession_defaut: checked ? 1 : 0});
                                        ChangeSessionDefault(checked, session.id, this.props.chosen_set);
                                    }}
                                    style={{marginLeft: '25px'}}
                                /></Tooltip>
                            </div>
                            <div style={{marginTop:"1%"}}>
                                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Name</h2> <br></br>
                                <TextArea
                                    value={this.state.updatesession_name !== "" ? this.state.updatesession_name : session.name}
                                    autoSize={{minRows: 1, maxRows: 6}}
                                    onChange={(value) => {
                                        this.setState({updatesession_name: value.target.value});
                                    }}
                                    style={{width: '50%', marginLeft: '10px'}}
                                    prefix={<Icon type="font-colors" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Session name"
                                />
                            </div>
                            <div style={{marginTop:"1%"}}>
                                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Buttons</h2>
                                <List
                                    itemLayout="horizontal"
                                    style={{width: '20%'}}
                                    dataSource={UnityInfos.unity_buttons}
                                    renderItem={item => (
                                        <List.Item>
                                            <Checkbox
                                                checked={this.IsButtonInUrl(this.state.updatesession_url, item.toLowerCase()) ? this.getButtonFromUrl(this.state.updatesession_url, item.toLowerCase()) === "show" : this.IsButtonInUrl(session.url, item.toLowerCase()) ? this.getButtonFromUrl(session.url, item.toLowerCase()) === "show" : true}
                                                onChange={(e) => this.UpgradeUrlUpdateSession("bttn", e.target.checked, item.toLowerCase())}></Checkbox>
                                            <List.Item.Meta
                                                title={<span style={{marginLeft: '10px'}}>{item === "Infos"?"Annotations":item==="Objects"?"Labels":item}  </span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <div style={{marginTop:"1%"}}>
                                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Time steps</h2>
                                <Slider style={{width: '80%'}} range
                                        onChange={value => this.UpgradeUrlUpdateSession("minmax", value, -1)}
                                        min={dataset.mintime} max={dataset.maxtime}
                                        value={[this.state.updatesession_mintime === undefined? this.getValueForParameter(session, "minTime") !== null ? this.getValueForParameter(session, "minTime") : dataset.mintime : this.state.updatesession_mintime, this.state.updatesession_maxtime === undefined? this.getValueForParameter(session, "maxTime") !== null ? this.getValueForParameter(session, "maxTime") : dataset.maxtime:this.state.updatesession_maxtime]}/>
                                <span className='content-text'> Default time point</span>
                                <InputNumber
                                    min={this.getValueForParameter(session, "minTime") !== null? this.getValueForParameter(session, "minTime"):dataset.mintime}
                                    max={this.getValueForParameter(session, "maxTime") !== null? this.getValueForParameter(session, "maxTime"):dataset.maxtime}
                                    onChange={value => this.UpgradeUrlUpdateSession("time", value, -1)}
                                    value={this.getValueForParameter(session, "time") !== null ? this.getValueForParameter(session, "time") : dataset.mintime}
                                    style={{width: '200px', marginLeft: '10px'}}
                                    placeholder="Default time step"
                                />
                            </div>
                            <div style={{marginTop:"1%"}}>
                                {
                                    quality_count > 1 ?
                                        <div>
                                            <h2 style={{
                                                display: 'inline',
                                                color: '#44D4FF',
                                                fontSize: '20px'
                                            }}>Quality</h2>
                                            <Slider tipFormatter={qualityformatter} style={{width: '20%'}} min={0}
                                                    onChange={value => this.UpgradeUrlUpdateSession("quality", value, -1)}
                                                    max={quality_count - 1}
                                                    defaultValue={this.getValueForParameter(session, "quality") !== null ? this.getValueForParameter(session, "quality") : 0}/>
                                            <span className='content-text'> {this.getValueForParameter(session, "quality") !== null ? qualityformatter(this.getValueForParameter(session, "quality")) : qualityformatter(0)}</span>
                                        </div>
                                        :
                                        <span></span>
                                }
                            </div>
                            <div style={{marginTop:"1%"}}>
                                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '20px'}}>Parameters</h2>
                                <List
                                    itemLayout="horizontal"
                                    style={{width: '60%'}}
                                    dataSource={correspondence_list}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<span style={{
                                                    color: 'grey',
                                                    marginLeft: '10px'
                                                }}>{item.infos} ({item.datatype})</span>}
                                            />
                                            <Radio.Group
                                                onChange={(e) => this.UpgradeUrlUpdateSession("cor", e.target.value, item.id)}
                                                defaultValue={this.getCorrespondenceFromFirstUrl(session, item.id)}>
                                                <Radio value={"show"}>Show</Radio>
                                                <Radio value={"hide"}>Hide</Radio>
                                                <Radio value={"load"}>Load</Radio>
                                                <Radio value={"apply"}>Apply</Radio>
                                            </Radio.Group>
                                        </List.Item>
                                    )}
                                />
                            </div>
                            <div style={{marginTop:"1%"}}>
                                <Button type="primary" onClick={() => {
                                    UpdateExistingSession(this.state.updatesession_name, this.state.updatesession_url, session, this.props.chosen_set)
                                }} style={{
                                    borderColor: '#44D4FF',
                                    backgroundColor: '#44D4FF',
                                    marginRight: '10px'
                                }}>
                                    Update session
                                </Button>

                                <Button type="danger" disabled={!IsAtLeastManager(this.props.chosen_set)}
                                        onClick={() => {
                                            DeleteSelectedSession(session, this.props.chosen_set)
                                        }}
                                        style={{marginRight: '10px'}}>
                                    Delete Session
                                </Button>
                            </div>
                        </div></div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        sessions: state.session.entities,
        datasets: state.dataset.entities,
        infos: state.infos.entities,
        connected: state.user.is_connected,
        sharings: state.sharing.entities,
        set_quality_by_id: state.dataset.sets_quality_by_id,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionUpdateSession);