import React from 'react';
import axios from 'axios';
import { List } from 'antd';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../store/actions/auth';
import {getUserToken,getUserID} from '../store/utility'


function msToTime(s) {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return hrs + 'h' + mins + 'm' + secs + 's';
}

class UserList extends React.Component {

    state = {
      //all user managed by this user
        all_users : [],
        user : {},
        staff : false,
        token : "",
        connected_user_id : -1,
        loginbyid : {},
        logoutbyid : {},
        connexionbyid : {},
        activeByUser : {},
    }
    user_id = -1;
    

    sessionDuration = (date1,date2) => {
        const login = Date.parse(date1);
        const logout = Date.parse(date2);

        if (logout < login)
      {
        return 0;
      }

        const duration = parseInt(logout) - parseInt(login);
        return msToTime(duration);
    }

    //retrieve user + list of users managed by this user
    componentDidMount() {
      //is user admin from cache ? 
      this.setState({token : getUserToken()});
      this.state.connected_user_id = getUserID();
      //timer for display
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);

    }


    componentDidUpdate(prevProps, prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });

        if (this.state.token !== "" && this.state.token !== null){
          axios.get(`/api/people/${this.state.connected_user_id}`,{ headers: { Authorization: `Token ${this.state.token}` } })
          .then(res => {
            res.data.password = "";
              this.setState({
                  user: res.data,
              });
          });
      }
  
        axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
              all_users: res.data,
            });
            //Sort on name + surname, if not use item login 
            this.state.all_users.sort(function (a,b){
              if (a.name === "" && b.name === ""){
                return a.login.toLowerCase()<b.login.toLowerCase()? -1 : a.login.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
              }
              else if (a.name === ""){
                return a.login.toLowerCase()<b.name.toLowerCase()? -1 : a.login.toLowerCase()>b.name.toLowerCase() ? 1 : 0;
              }
              else if (b.name === ""){
                return a.name.toLowerCase()<b.login.toLowerCase()? -1 : a.name.toLowerCase()>b.login.toLowerCase() ? 1 : 0;
              }
              else {
              return a.name.toLowerCase()<b.name.toLowerCase()? -1 : a.name.toLowerCase()>b.name.toLowerCase() ? 1 : 0;}
            });
        });
    }

      if (this.state.all_users !== prevState.all_users){
          const user_id_list = [];
          this.state.all_users.map(user => {
            if (!user_id_list.includes(user.id)){
              user_id_list.push(user.id);
            }
        });

        axios.get('/api/userlogin/',{ params : {id_users: JSON.stringify(user_id_list)}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          this.setState({loginbyid:res.data})
        });

        axios.get('/api/userlogout/',{ params : {id_users: JSON.stringify(user_id_list)}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          this.setState({logoutbyid:res.data})
        });

        axios.get('/api/connexionsnumber/',{ params : {id_users: JSON.stringify(user_id_list)}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          this.setState({connexionbyid:res.data})
        });

        axios.get('/api/multipleconnexionlist/',{ params : {id_users: JSON.stringify(user_id_list),maxLimit:1}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          //console.log(res.data);
          for (let obj in res.data){
            this.state.activeByUser[obj] = res.data[obj].active;
          }
        });
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval);
  }

  bytesToSize = bytes => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0 || bytes === -1) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }


    //called everytime the DOM has changed
    render() {
        return (
          !this.state.staff?
          <div>You need to be staff to access this</div>
          :
          <div style={{marginLeft:'5%',marginTop:'4%'}}>
            <h1 style={{marginLeft:'47%',color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>User list</h1>
            <List
                itemLayout="horizontal"
                dataSource={this.state.all_users}
                renderItem={item => (
                <List.Item>
                        <List.Item.Meta
                    title={
                    <NavLink exact to={{pathname:`/user-sessions/`,aboutProps:item}}>           
                     {
                        item.name || item.surname?
                     <span style={{color:this.state.activeByUser[item.id]?'green':'red'}}>{item.name} {item.surname} - Last login : {this.state.loginbyid[item.id] !== undefined?this.state.loginbyid[item.id]:"Not defined"} - Last logout : {this.state.logoutbyid[item.id] !== undefined?this.state.logoutbyid[item.id]:"Not defined"}  - Sessions : {this.state.connexionbyid[item.id] !== undefined?this.state.connexionbyid[item.id]:"Not defined"} - Session duration : {this.sessionDuration(this.state.loginbyid[item.id],this.state.logoutbyid[item.id])}</span>
                        :
                        <span >{item.login}</span>
                      } </NavLink>
                    }
                    />
                </List.Item>
                )}
                />
          </div>
      );
    }
}

//allow the compoennt to be accessed everywhere else
const mapStateToProps = state => {
  return {
      isAuthenticated: state.token !== null,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps) (UserList);
