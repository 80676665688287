import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {getUserToken} from '../store/utility'
import * as actions from '../store/actions/auth';

import {
    Form,
    Input,
    Icon,
    Button,
    Typography
  } from 'antd';
  

  const { Title } = Typography;

  class ResetPasswordDjangoForm extends React.Component {
    state = {
      confirmDirty: false,
      userid: -1,
      token : "",
      url_token : "",
      user : {},
      error_message : ""
    };
  
    //when you update the pw
    handleSubmit = e => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //get auth token fromfirefox cache
           axios({
            method: 'put',
            url: `/api/resetpasswd/`,
            data: {               
                user_id:this.state.userid,
                new_password:values.password,
            }
          })
          //redirect the page
           this.props.history.push('/');
          }
      });
    };
    
    componentDidMount() {
      //get user from app data
      this.state.url_token = window.location.pathname.split('/')[2];
        let url_pattern = this.props.location.search;
        url_pattern = url_pattern.replace('?','');
      if (url_pattern !== undefined && url_pattern !== "" ){
          const url_params = url_pattern.split('&');
          for (let params in url_params){
              const values = url_params[params].split('=');
              if (values[0] === "ec")
                  this.state.url_token = values[1];
              ////console.log(url_params[params]);
          }
          window.scrollTo(window.scrollX+1,window.scrollY+1);
      }


      //timer to refresh display (button click)
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
      this.setState({token:getUserToken()});
      //get connected user token, than ask for url parameter ID persons
      //when we get token, perform get request to django to get user according to token
      //if good response, save user in state to be able to use his id
      //else put error message in state response.statusText
      axios.get('/api/userurl/',{params:{'urltoken':this.state.url_token}})
          .then(res => {
              this.state.userid = res.data[0].id;
              //console.log(this.state.userid);
          }).catch(err => this.setState({
            error_message: "Invalid Url !"
          })
          );

  }

  //stop timer
  componentWillUnmount() {
    clearInterval(this.interval);
  }

    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  
    compareToFirstPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    };
  
    validateToNextPassword = (rule, value, callback) => {
      const { form } = this.props;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    };
  

    render() {
      const { getFieldDecorator } = this.props.form;

  
      return (
        <div>
        {
          this.state.error_message !== undefined?
          <div>
          <Title style={{marginLeft:'43%'}}> Modify user password </Title>
          <input type="text" type='hidden' name="username" value={this.state.user.login} />
        <Form style={{marginTop:'7%',marginLeft:'31%'}} onSubmit={this.handleSubmit}>
          <Form.Item hasFeedback>
            Password : 
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password autoComplete="new-password" style={{width:'200px',marginLeft: '10px'}} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"/>)}
          </Form.Item>
          <Form.Item hasFeedback>
            Confirm password : 
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password autoComplete="new-password" style={{width:'200px',marginLeft: '10px'}} onBlur={this.handleConfirmBlur}  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password" />)}
          </Form.Item>
        
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                Update password
            </Button>
        </Form.Item>


        </Form>
        </div>
        :
        <div>This webpage is only reachable from your user page !</div>
        }
        </div>
      );
    }
  }
  
  const WrappedResetPasswordDjangoForm = Form.create({ name: 'resetpassword' })(ResetPasswordDjangoForm);

  //get data from app
  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error
    }
}

//bind auth method to global auth system
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedResetPasswordDjangoForm);