import React from 'react';

class SuccessValid extends React.Component {
    render(){
        return(
            <div style={{marginTop:'7%'}}><span>Email account validation is successfull ! You can now login.</span></div>
        );
    }
}

export default SuccessValid;