import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import * as actions from '../store/actions/auth';
import {getUserToken,getUserID} from '../store/utility'

import {
    Form,
    Input,
    Icon,
    Button,
    Typography,
    Checkbox 
  } from 'antd';
import {CreateGroupReload} from "../utils/api_callbacks";
  

  const { Title } = Typography;

  //Loaded on create groupe page
  class GroupRegistrationForm extends React.Component {
    //component data (R/W permissions)
    state = {
      confirmDirty: false,      groups : [],
      //group status
      private : true,
      //user id got by firefox cache
      connected_user_id : -1,
      created_group_id : -1,
      token : "",
    };

    //on click checkbox privacy
    handlePrivacyChange = e => {
      this.setState({
          private: e.target.checked
      });
    }

    //commit the group creation to django, than add creator as manager
    handleSubmit = e => {
      e.preventDefault();
      this.props.sendTracking(-1,"Web,Group,Create,create");

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (this.state.connected_user_id !== -1 && !err) {
            CreateGroupReload(values.name,this.state.private?"private":"public");
            this.props.history.push('/group');
          }
      });
    };
  
    componentDidMount() {
      //get user id from cache
      this.setState({connected_user_id: getUserID()});
      this.setState({token:getUserToken()});
      //timer to refresh display (button click)
      this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  }

  //stop timer
  componentWillUnmount() {
    //stop timer to refresh display (button click)
    clearInterval(this.interval);
  }

    handleConfirmBlur = e => {
      const { value } = e.target;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
  


    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        this.state.token === null || this.state.token === "" ?
        <div>Please register to access this page</div>
        :
        <div style={{textAlign:"center"}}>
        <Title > Group creation </Title>
        <Form style={{marginTop:'7%'}} onSubmit={this.handleSubmit}>
        <Form.Item>
          Name :
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input a group!' }],
          })(
            <Input
              style={{width:'200px',marginLeft: '10px'}}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Group name"
            />,
          )}
        </Form.Item>

        <Form.Item>
          <Checkbox checked={this.state.private} onChange={this.handlePrivacyChange}>
           Private ?
          </Checkbox>
        </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight: '10px'}} >
                Create group
            </Button>
        </Form.Item>


        </Form>
        </div>
      );
    }
  }
  
  const WrappedGroupRegistrationForm = Form.create({ name: 'register' })(GroupRegistrationForm);

  //get the data from application
  const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error : state.error,
        isAuthenticated: state.token !== null,
    }
}

//bind onAuth function to global auth function
const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team) => dispatch(actions.authSignup(username, email, password,name,surname,phone,adress,fonction,quota,id_institution,id_laboratory,id_team))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WrappedGroupRegistrationForm);