import React from 'react';
import axios from 'axios';
import {encryptString,decryptString,getUserToken,getUserID} from '../store/utility'
import { List,DatePicker, Tooltip  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import {
    Button,
  } from 'antd';
  


  function msToTime(s) {
      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return hrs + 'h' + mins + 'm' + secs + 's';
  }

  class UserSessionList extends React.Component {

    sessionDuration = (date1,date2) => {
        const login = Date.parse(date1);
        const logout = Date.parse(date2);

        if (logout < login)
       {
         return 0;
       }

        const duration = parseInt(logout) - parseInt(login);
        return msToTime(duration);
     }
     
    //current component variables, used to store or retrieve values
    state = {
      user: {},
      user_id: -1,
      connected_user_id: -1,
      token : "",
      staff : false,
      userSessions : [],
      maxSessionLimit : 100,
      dateBeginning : "",
      dateEnd : "",
    };

    staff = false;

    onChangeDateEnd = (date, dateString) => {
      //console.log(dateString);
      this.setState({dateEnd:dateString});
    }

    onChangeDateBeginning = (date, dateString) => {
      //console.log(dateString);
      this.setState({dateBeginning:dateString});
    }

    AskServerForSessions = () => {
      if (this.state.dateBeginning !== null && this.state.dateBeginning !== "" && this.state.dateEnd !== null && this.state.dateEnd !== "")
      {
        this.setState({userSessions:[]});
        axios.get('/api/connexionlistdate/',{ params : {user_id: this.state.user_id,maxLimit:this.state.maxSessionLimit,dateBeginning:this.state.dateBeginning,dateEnd:this.state.dateEnd}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
         // console.log(res.data);
          this.setState({userSessions:res.data})
        });
      }
    }
    //after receiving data (old data is given as params)
    componentDidUpdate(prevProps,prevState){
      if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({staff:res.data['staff']});
        });
    }

      if (this.state.user_id !== prevState.user_id){
        axios.get('/api/connexionlist/',{ params : {user_id: this.state.user_id,maxLimit:this.state.maxSessionLimit}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
         // console.log(res.data);
          this.setState({userSessions:res.data})
        });
      }
    }

    //when component is loaded, but not rendered
    componentDidMount() {
    //staff value in firefox cache, as string
    this.setState({token:getUserToken()});
    this.setState({connected_user_id:getUserID()});

    if (localStorage.getItem('userSessionProps') !== undefined && this.props.location.aboutProps === undefined){
      this.props.location.aboutProps = JSON.parse(decryptString(localStorage.getItem('userSessionProps')));
    }
    let savedUser =  JSON.stringify(this.props.location.aboutProps)
    localStorage.setItem('userSessionProps', encryptString(savedUser));
    //get user through webpage
    this.setState({user_id: this.props.location.aboutProps.id});
    this.setState({user:this.props.location.aboutProps});
      //timer to refresh display (button click)
  }


  //stop timer
  componentWillUnmount() {
    clearInterval(this.interval);
    //save user in the firefox cache in case of reload 
    let savedUser =  JSON.stringify(this.state.user)
    localStorage.setItem('userSessionProps', encryptString(savedUser));
  }

    render() {
      return (
        !this.state.staff?
        <div>You need to be staff to access this</div>
        :
        <div style={{marginLeft:'5%',marginTop:'4%'}}>
          <h1 style={{marginLeft:'35%',color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>{this.state.user.login} sessions list ({this.state.userSessions.length} lasts)</h1>
          <h1 style={{marginLeft:'39%',color:'#292e37',fontFamily:'Baskerville',fontSize:'27px',fontWeight:"bold",minWidth:'750px'}}>Select session between 2 dates</h1>

          <span style={{marginLeft:'30%'}} className='new-content-text'>Beggining :</span> <DatePicker onChange={this.onChangeDateBeginning} />
           <span style={{marginLeft:'5%'}} className='new-content-text'>End :</span> <DatePicker onChange={this.onChangeDateEnd} />
           <Tooltip title="search">
              <Button style={{marginLeft:'5%'}} onClick={this.AskServerForSessions} shape="circle" ><SearchOutlined /></Button>
          </Tooltip>
          <br></br><br></br>
          <List
              itemLayout="horizontal"
              style={{marginTop:'1%',marginLeft:'27%',width:'45%'}}
              dataSource={this.state.userSessions}
              renderItem={item => (
              <List.Item
              extra={
                <span className='new-content-text'> OS : {item.type_os} <br></br> Browser : {item.browser} {item.browser_version}</span>
              }
              >
                      <List.Item.Meta
                  title={
                      <div><span className='new-content-text'> Login : {new Date(item.date_login).toLocaleString()} <br></br>Logout : {item.date_logout?new Date(item.date_logout).toLocaleString():"Undefined"} <br></br>
                       Session duration :</span> <span style={{color:item.active?'green':'red'}} className='new-content-text'> {item.active?"Still active":item.date_logout?this.sessionDuration(item.date_login,item.date_logout):"Undefined"} </span></div>
                  }
                  description = {
                    <span style={{color:'#44D4FF',fontWeight:'bold'}} className='new-content-text'>{item.is_mobile === true?"On mobile,":""} {item.is_tablet === true?"On tablet,":""} {item.is_pc === true?"On PC,":""} {item.is_bot === true?"Is a bot,":""}</span>
                  }
                  />
              </List.Item>
              )}
              />
        </div>

      );
    }
  }
  
  export default UserSessionList;