import React from "react";
import {connect} from "react-redux";
import {SearchByGroup, SearchByName, SearchByStudy, SearchByTag} from "../store/reducers/datasetSlice";
import TextArea from "antd/es/input/TextArea";
import {Button} from "antd";


class DatasetSearchFilter extends React.Component {

    state = {
        current_search_name: "",
        current_search_group: "",
        current_search_tag: "",
        current_search_study: "",
    }

    componentDidMount() {
    }

    onChangeSearchBarName = (value) => {
        this.setState({current_search_name: value.target.value === undefined ? "" : value.target.value});
    }

    onChangeSearchBarStudy = (value) => {
        this.setState({current_search_study: value.target.value === undefined ? "" : value.target.value});
    }

    onChangeSearchBarGroup = (value) => {
        this.setState({current_search_group: value.target.value === undefined ? "" : value.target.value});
    }

    onChangeSearchBarTag = (value) => {
        this.setState({current_search_tag: value.target.value === undefined ? "" : value.target.value});
    }
    startSearchOnButton = () => {
        this.props.SearchByNameFunc(this.state.current_search_name);
        this.props.SearchByStudyFunc(this.state.current_search_study);
        this.props.SearchByGroupFunc(this.state.current_search_group);
        this.props.SearchByTagFunc(this.state.current_search_tag);

    }

    render() {
        return (
            <div>
                <h3 style={{fontSize:"20px",marginLeft:"5px",marginTop:"10px",textAlign:"unset"}}> Filter by text</h3><div style={{textAlign: "center", height: '100%',paddingTop:"5px"}}>

            <TextArea
                placeholder="Search using dataset name"
                onChange={this.onChangeSearchBarName}
                style={{width: "90%"}}
                autoSize={{minRows: 2, maxRows: 3}}
                defaultValue={this.props.dataset.current_filter_search_name !== undefined ? this.props.dataset.current_filter_search_name : ""}
                className='SearchBarFilter'
            />
            {
                this.props.connected && <div>
                    <br/>
                    <TextArea
                        placeholder="Search using dataset group"
                        onChange={this.onChangeSearchBarGroup}
                        style={{paddingLeft: "10px", paddingRight: "10px", width: "90%"}}
                        autoSize={{minRows: 2, maxRows: 3}}
                        defaultValue={this.props.dataset.current_filter_search_group !== undefined ? this.props.dataset.current_filter_search_group : ""}
                        className='SearchBarFilter'
                    />
                    <br/> <br/>
                    <TextArea
                        placeholder="Search using dataset study"
                        onChange={this.onChangeSearchBarStudy}
                        style={{paddingLeft: "10px", paddingRight: "10px", width: "90%"}}
                        autoSize={{minRows: 2, maxRows: 3}}
                        defaultValue={this.props.dataset.current_filter_search_study !== undefined ? this.props.dataset.current_filter_search_study : ""}
                        className='SearchBarFilter'
                    />
                    <br/> <br/>
                    <TextArea
                        placeholder="Search using dataset tag"
                        onChange={this.onChangeSearchBarTag}
                        style={{paddingLeft: "10px", paddingRight: "10px", width: "90%"}}
                        autoSize={{minRows: 2, maxRows: 3}}
                        defaultValue={this.props.dataset.current_filter_search_tag !== undefined ? this.props.dataset.current_filter_search_tag : ""}
                        className='SearchBarFilter'
                    />


                </div>
            }
            <br/>
            <br/>
            <div style={{textAlign: "center", width: "100%", marginBottom: '20px'}}>
                <Button onClick={() => this.startSearchOnButton()}>Search
                    datasets
                </Button>
                <br></br>
                <br></br>
            </div>
            </div></div>);
    }
}


const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        connected: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        SearchByNameFunc: (name) => dispatch(SearchByName(name)),
        SearchByStudyFunc: (study) => dispatch(SearchByStudy(study)),
        SearchByGroupFunc: (group) => dispatch(SearchByGroup(group)),
        SearchByTagFunc: (tag) => dispatch(SearchByTag(tag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetSearchFilter);