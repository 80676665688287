import React, { Component } from "react";
import { List,Button,Select} from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';
import '../App.css';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip }  from 'recharts';
import MorphoMap from '../components/Map.js';

const { Option } = Select;


class MorphoStats extends Component {
    constructor(props){
        super(props);
    }

    state = {
        id_user : -1,
        token : "",
        staff : false,
        user_list : [],
        select_user : undefined,
        dateEnd : "",
        dateBeginning : "",
        maxLimit : 100,
        connexionTab : undefined,
        charData_ : undefined,
        interactionList : [],
        show_user_id : false,
        highlight_date : false,
        highlight_user : false,
        users_connexion_count : -1,
        users_connexion_list : undefined,
        anonymous_connexion_count : -1,
        anonymous_connexion_list : undefined,
        anonymous_treated : false,
        users_treated : false,
        anon_ip_got : false,
        users_ip_got : false,
        coordinated_by_ip : [],
        daysBefore : 31,

        display_map : false,
    }

    componentDidMount(){
        this.state.id_user = getUserID();
        this.setState({token : getUserToken()});
    }

    componentDidUpdate(prevProps,prevState){
    
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
            
            axios.get('/api/people/',{headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    user_list: res.data,
                });
            });

            this.loadStatsForAllDay();
            
            this.getMapValues();

        }
        
    }

    getMapValues = () => {
        axios.get('/api/connexioncountusers/',{params:{dayLimit:this.state.daysBefore},headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                users_connexion_count: res.data.count,
            });

            if (res.data.list !== undefined){
                //console.log(res.data.list);
                this.setState({
                    users_connexion_list: res.data.list,
                });
            }
    });


    axios.get('/api/connexioncountanonyms/',{params:{dayLimit:this.state.daysBefore},headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                anonymous_connexion_count: res.data.count,
            });
            if (res.data.list !== undefined){
                //console.log(res.data.list);
                this.setState({
                    anonymous_connexion_list: res.data.list,
                });
            }
    });
    }
    
    onChangeDateEnd = (date, dateString) => {
        this.setState({dateEnd:dateString});
      }
  
      onChangeDateBeginning = (date, dateString) => {
        this.setState({dateBeginning:dateString});
      }

      loadStatsForAllDay = () => {
          const day_list = this.state.daysBefore;
          this.state.dateEnd = new Date().toISOString().split('T')[0];
          const d = new Date();
          d.setDate(d.getDate()-day_list);
        this.state.dateBeginning = d.toISOString().split('T')[0];
        if ( this.state.dateEnd !== undefined || this.state.dateEnd !== "" || this.state.dateBeginning !== undefined || this.state.dateBeginning !== "") {
            this.setState({highlight_date:true});
        }
        this.setState({show_user_id:true});
        if (this.state.dateEnd !== undefined && this.state.dateEnd !== "" && this.state.dateBeginning !== undefined && this.state.dateBeginning !== "") {
            this.setState({highlight_date:false});
            axios.get('/api/connexiongraph/',{params:{dateEnd:this.state.dateEnd,dateBeginning:this.state.dateBeginning},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                //console.log(res.data);
                this.setState({
                    connexionTab: res.data,
                });
            });

            axios.get('/api/interactionlist/',{params:{maxLimit:this.state.maxLimit},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    interactionList: res.data,
                });
            });
        }
    }

    loadStatsForAll = () => {
        if ( this.state.dateEnd !== undefined || this.state.dateEnd !== "" || this.state.dateBeginning !== undefined || this.state.dateBeginning !== "") {
            this.setState({highlight_date:true});
        }
        this.setState({show_user_id:true});
        if (this.state.dateEnd !== undefined && this.state.dateEnd !== "" && this.state.dateBeginning !== undefined && this.state.dateBeginning !== "") {
            this.setState({highlight_date:false});
            axios.get('/api/connexiongraph/',{params:{dateEnd:this.state.dateEnd,dateBeginning:this.state.dateBeginning},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                //console.log(res.data);
                this.setState({
                    connexionTab: res.data,
                });
            });

            axios.get('/api/interactionlist/',{params:{maxLimit:this.state.maxLimit},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    interactionList: res.data,
                });
            });
        }
    }


    loadStatsForUser = () => {
        const user = this.state.select_user;
        if (user === undefined){
            this.setState({highlight_user:true});
        }

        if ( this.state.dateEnd !== undefined || this.state.dateEnd !== "" || this.state.dateBeginning !== undefined || this.state.dateBeginning !== "") {
            this.setState({highlight_date:true});
        }
        if (user !== undefined && this.state.dateEnd !== undefined && this.state.dateEnd !== "" && this.state.dateBeginning !== undefined && this.state.dateBeginning !== "") {
            this.setState({highlight_user:false});
            this.setState({highlight_date:false});
            axios.get('/api/connexiongraphbyuser/',{params:{user_id:user.id,dateEnd:this.state.dateEnd,dateBeginning:this.state.dateBeginning},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                //console.log(res.data);
                this.setState({
                    connexionTab: res.data,
                });
            });

            axios.get('/api/userinteractions/',{params:{maxLimit:this.state.maxLimit,id_user:user.id},headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    interactionList: res.data,
                });
            });


            this.setState({show_user_id:false});
        }
        
    }

    
    render(){
        const useroptions = this.state.user_list.map(d => <Option value={d} key={d.login}>{d.name+" "+d.surname}</Option>);
        return (
            <div style={{marginTop:'3%'}}>
            {
                this.state.staff?
                <div style={{margin:'auto',alignContent:'center',textAlign:'center'}}>
                    <Button style={{margin:'auto'}} onClick={() => {this.setState({display_map:!this.state.display_map})}}>{this.state.display_map?"Hide the map":"Display the map"}</Button> 
                    <div style={{float:'left',marginTop:'7%'}} ><h2 style={{fontSize:'17px',color:'black'}}>Number of connected users : {this.state.users_connexion_count}</h2>
                    <h2 style={{fontSize:'17px',color:'black'}}>Number of connected anonymous : {this.state.anonymous_connexion_count}</h2>
                    <br></br><br></br>
                    <h2 style={{fontSize:'17px',color:'black'}}>Number of users in choosen period : {this.state.users_connexion_list !== undefined?this.state.users_connexion_list.length:-1}</h2>
                    <h2 style={{fontSize:'17px',color:'black'}}>Number of anonymous in choosen period : {this.state.anonymous_connexion_list !== undefined?this.state.anonymous_connexion_list.length:-1}</h2>
                    </div>
                    <br></br><br></br>
           
                    <div>
                    {
                        this.state.display_map &&
                        <MorphoMap markers={this.state.anonymous_connexion_list !== undefined?this.state.anonymous_connexion_list.concat(this.state.users_connexion_list):undefined}></MorphoMap>

                    }
                        <br></br>
                        <Button style={{margin:'auto'}} onClick={() => {this.state.daysBefore=1;this.getMapValues();this.loadStatsForAllDay();}}>Last day</Button>
                        <Button style={{margin:'auto',marginLeft:'100px'}} onClick={() => {this.state.daysBefore=31;this.getMapValues();this.loadStatsForAllDay();}}>Last month</Button>
                        <Button style={{margin:'auto',marginLeft:'100px'}} onClick={() => {this.state.daysBefore=365;this.getMapValues();this.loadStatsForAllDay();}}>Last year</Button>                    
                    </div>
                    {
                        this.state.connexionTab !== undefined && 
                        <div>
                        <br></br><br></br>
                        <AreaChart
                        style={{marginLeft:'30%'}}
                        width={1080}
                        height={400}
                        data={this.state.connexionTab}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Area
                            type='monotone'
                            dataKey='count'
                            stroke='#8884d8'
                            fill='#8884d8'
                            />
                        </AreaChart>
                        </div>
                    }

                    <br></br><br></br>
                    
                    <div style={{margin:'auto',marginLeft:'15%',alignContent:'center',textAlign:'center'}} >
                        <h2 style={{fontSize:'15px',color:'black'}}>Load by User : </h2>
                    <Select
                    showSearch
                    style={this.state.highlight_user?{outline:'solid',outlineWidth:1,outlineColor:'red',width: 300}:{width: 300}}
                    placeholder="Select an user"
                    value={this.state.select_user?this.state.select_user.login:"Select an user"}
                    optionFilterProp="children"
                    onChange={(value) => {this.setState({select_user:value})}}
                    filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                     {useroptions}
                </Select>
        
                <Button style={{marginLeft:'5px'}} onClick={this.loadStatsForUser}>Load</Button> 
                <Button style={{marginLeft:'5px'}} onClick={this.loadStatsForAll}>Load for all users</Button> 
                </div>
                
                <br></br><br></br>


            {
                this.state.interactionList !== undefined && 
                <div>
                <br></br><br></br>
                <List
                itemLayout="horizontal"
                style={{marginTop:'1%',marginLeft:'37%',width:'45%'}}
                dataSource={this.state.select_user !== undefined && this.state.select_user !== "Select an user"?this.state.interactionList.filter(interact => interact.id_user === this.state.select_user.login):this.state.interactionList}
                renderItem={item => (
                <List.Item
                extra={
                    <span className='new-content-text'> {new Date(item.access).toLocaleString()}</span>
                }
                >
                        <List.Item.Meta
                    title={
                        this.state.show_user_id?<span className='new-content-text'>  {item.id_user !== null && item.id_user !== "public.access"?"User":"IP"} : {item.id_user !== null && item.id_user !== "public.access"?item.id_user:item.ip} - {item.name}</span>:<span className='new-content-text'>  {item.name}</span>
                    }
                    description = {
                        <span className='new-content-text'>{item.id_dataset?" Dataset : "+item.id_dataset:""}</span>
                    }
                    />
                </List.Item>
                )}
                />
                </div>
            }
                </div>
                :
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px',marginTop:'2%'}}> You don't have access to see this page </h1>
            }
            </div>
        );
    }

}

export default MorphoStats;