import {connect} from "react-redux";
import {GetSetInUrl, IsAdminOrCreator} from "../utils/tools";
import React from "react";
import {AutoComplete, Button, Form, Tooltip, List, Input} from "antd";
import {
    ChooseGroup,
    ChooseStudy,
    DeleteStudy,
    PostNewStudy,
    PostUpdateStudy,
    UnlinkDatasetStudy
} from "../utils/api_callbacks";
import {getUserID, getUserToken} from "../utils/CookieManagement";
import {SharingGroupInstance} from "../Sharings/sharingGroupInstanceContainer";
import {SharingStudyInstance} from "../Sharings/sharingStudyInstanceContainer";
import user from "../containers/User";
import {NavLink} from "react-router-dom";
import axios from "axios";

const {Option} = AutoComplete;

export function ChooseSetFromUrl(url) {
    return GetSetInUrl(url);
}

class UpdateStudy extends React.Component {


    state = {
        study_id: -1,
        group_id : -1,
        new_study_name : "",
        new_study_description: "",
    }
    DeleteSelectedStudy = (study_id,group_id) => {
        DeleteStudy(study_id,group_id);
    }

    Unlink_Set_from_study = (set_id) => {
        UnlinkDatasetStudy(set_id);
    }
    getSessionId = (url) => {
        return parseInt(url.match(/^\d+|\d+\b|\d+(?=\w)/g)[1])
    }

    PostStudy = () => {
        //updatestudy
        PostUpdateStudy(this.state.new_study_name,this.state.new_study_description,this.state.study_id,this.state.group_id);
    }


    componentDidMount() {
        ChooseGroup(ChooseSetFromUrl(window.location.pathname))
        ChooseStudy(this.getSessionId(window.location.pathname));
        this.setState({study_id:this.getSessionId(window.location.pathname)});
        this.setState({group_id:ChooseSetFromUrl(window.location.pathname)});

    }

    render(){
        if (this.props.user.current_choosen_study === -1) {
            return <div></div>
        }
        const studies = this.props.user.studies_list.filter(study => study !== undefined && study.id === this.props.user.current_choosen_study);

        if (studies.length < 1) {
            return <div></div>
        }

        const groups = Object.values(this.props.user.group_list).filter(group => group.id === this.props.user.current_choosen_group);
        if (groups.length < 1) {
            return <div></div>
        }
        const group = groups[0];

        const study = studies[0];
        const datasets = Object.values(this.props.datasets.entities).filter(set => set.id_study_id === study.id);
        const user_list = this.props.user_contacts;
        return <div style={{marginLeft:"10%"}}>
            <h1 style={{textAlignLast:"center",color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',fontWeight:"bold",minWidth:'750px'}}>Update study : {study.name}</h1>
            <div style={{textAlign:"center"}}>
            <Input
                style={{width: '10%'}}
                placeholder={"Study name"}
                value={this.state.new_study_name}
                onChange={e => {
                    this.setState({new_study_name: e.target.value})
                }}>
            </Input>
            <br></br>
            <br></br>

            <Input
                style={{width: '10%'}}
                placeholder={"Study description"}
                value={this.state.new_study_description}
                onChange={e => {
                    this.setState({new_study_description: e.target.value})
                }}>
            </Input>
            <br></br>
            <br></br>
            <Button type="primary" onClick={this.PostStudy}
                    style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                Update
            </Button>
            <Button type="danger" disabled={datasets !== undefined && datasets.length > 0}
                    onClick={() => this.DeleteSelectedStudy(study.id,group.id)} style={{marginRight: '10px'}}>
                Delete
            </Button>
                <br/><br/>
            {datasets !== undefined && datasets.length > 0 && <span>Please unlink all datasets from study to be able to delete it</span>}
            </div>
            {
                datasets !== undefined && datasets.length > 0 &&
                <div style={{marginTop:"3%"}}>
                    <h3 style={{color: '#44D4FF', fontSize: '20px',textAlign:"center"}}>Datasets in study : </h3>
                    <List
                        itemLayout="horizontal"
                        style={{width: '100%'}}
                        dataSource={datasets}
                        renderItem={item => (
                            <div>
                                <span style={{color: 'grey', fontSize: '18px'}} >
                                    <NavLink to={'/dataset/' + item.id + "/"}>
                                        {item.name}
                                    </NavLink>
                                    </span>
                                <Tooltip placement={"bottom"} title={<span>Unlink dataset study</span>}><img onClick={() => {this.Unlink_Set_from_study(item.id);}} style={{float:"right",cursor:"pointer"}} width={"30"} src={"/images/web-unlink.png"}></img></Tooltip>
                                <br></br>
                                <br></br>
                            </div>
                        )}
                    />
                </div>
            }
            {
                user_list !== undefined && user_list.length > 0 &&
            <div>
                        <h3 style={{color: '#44D4FF', fontSize: '20px',textAlign:"center"}}>Invite user : </h3>
                        <List
                            itemLayout="horizontal"
                            style={{width: '100%'}}
                            dataSource={user_list}
                            renderItem={item => (
                                <SharingStudyInstance user={item} study={study}></SharingStudyInstance>
                            )}

                        />
                </div>
            }
        </div>;
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset,
        user_contacts: state.user.user_contacts,
        user: state.user,
        tags: state.tag,
        user_is_admin: state.user.is_staff,
        sharings: state.sharing.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStudy);