import React from "react";
import {Redirect} from "react-router-dom";
import {Col, Row, Tooltip} from 'antd';
import {AddToFavourite, RemoveFromFavourite, SendTracking} from "../utils/api_callbacks";
import {isOnStandalone, RetrieveSettings, textEllipsis} from "../utils/tools";
import {connect} from "react-redux";
import {encryptString} from "../utils/CookieManagement";
import {AddOverwriteById, AddQualitiesById} from "../store/reducers/datasetSlice";
import {QualitySlider} from "./QualitySlider";
import {TimeSlider} from "./TimeSlider";
import {SetRedirectedUrl} from "../store/reducers/userSlice";

class DatasetInstance extends React.Component {
    state = {
    }


    addIdToFavourite = (id_set) => {
        AddToFavourite(id_set);
    }

    RemoveIdFromfavourite = (id_set) => {
        RemoveFromFavourite(id_set);
    }


    openUnityFromAway = () => {
        this.props.openUnityFromAway();
    }
    unityLoadFunc = (dataset) => {
        this.props.unityLoadFunc(dataset);
    }

    render() {
        //display error
        var item = this.props.set;
        let set_priority = undefined;
        if (Object.keys(this.props.dataset_by_proprity).includes(item.id)) {
            set_priority = this.props.priorities[this.props.dataset_by_proprity[item.id]];
        }
        let row_options = undefined;
        if (this.props.dataset_by_tag[item.id] !== undefined && this.props.dataset_by_tag[item.id].length > 0) {
            row_options = this.props.dataset_by_tag[item.id].map(tag => {
                const tag_object = this.props.tags[tag];
                if (tag_object === undefined){return <div></div>}
                return <div style={{display: "inline-block",marginLeft:"3px"}} key={"tag_" + tag_object.id} >
                    <span style={{
                        border: 'solid 1px grey',
                        borderRadius: '5px',
                        backgroundColor: tag_object.color,
                        color: 'black'
                    }}>{tag_object.name}</span>
                </div>;
            });
        }
        const is_public = Object.values(this.props.sharings).some(sharing => sharing.id_base === item.id && sharing.base === "dataset" && sharing.id_who === 0) || item.public;
        const quality_count = this.props.set_quality_by_id[item.id];

        return (
            <div key={item.id}>
                        <div>
                            {/* GIF or viewer button */}
                            <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                     title={<span>Load this dataset in the visualizer</span>}>
                                <img onContextMenu={(evt) => {
                                    evt.preventDefault();
                                    localStorage.setItem('unityProps', encryptString(JSON.stringify(item)));
                                    window.open(window.location.href, '_blank');
                                    return false;
                                }}
                                     onClick={() => {
                                         if (this.props.unity_loaded || isOnStandalone() || RetrieveSettings(this.props.settings, "standalone_start") === "true") {
                                             this.openUnityFromAway();
                                             SendTracking(item.id, "Web,Dataset,visualize");
                                             this.unityLoadFunc(item);
                                         }
                                     }} style={{
                                    cursor: 'pointer',
                                    float: 'left',
                                    verticalAlign: 'center',
                                    borderRadius: '10px',
                                    marginRight: '2px'
                                }} width={50}
                                     src={this.props.gif_by_dataset[item.id] === "false" ? this.props.unity_loaded ? "/images/refonte/load_small.png" : "/images/refonte/greyload_small.png" : "/GIF/DATASET_" + item.id + "-50.gif"}
                                     alt={"Load a dataset"}></img>
                            </Tooltip>
                            {
                                //If user is connected and is in collaborative mode , display priorities
                                this.props.connected && RetrieveSettings(this.props.settings, "collaborativemode") !== "false" && set_priority !== undefined &&
                                <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                         title={"Dataset priority : " + set_priority.value}>
                                    <div style={{
                                        width: '2%',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        height: '50%',
                                        backgroundColor: set_priority.value === 'Very high' ? 'red' : set_priority.value === 'High' ? 'yellow' : set_priority.value === 'Low' ? '#7CFC00' : 'transparent',
                                        borderRadius: '20% / 20%'
                                    }}></div>
                                </Tooltip>
                            }
                            {
                                //Rendering favourite or not
                                this.props.connected && RetrieveSettings(this.props.settings, "favoritedisplay") !== "false" && this.props.favourite_by_dataset_id[item.id] === true ?
                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>Remove this dataset from favourites</span>}><img
                                        onClick={() => {
                                            SendTracking(item.id, "Web,Dataset,Favourite,remove");
                                            this.RemoveIdFromfavourite(item.id);
                                        }} alt="Data is favourite" style={{
                                        float: 'left',
                                        marginRight: '4px',
                                        paddingTop:row_options===undefined?"15px":"unset",
                                        cursor: 'pointer'
                                    }} width={20} src="/images/refonte/star_on.png"/></Tooltip>
                                    :
                                    this.props.connected && RetrieveSettings(this.props.settings, "favoritedisplay") !== "false" &&
                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>Set this dataset in favourites</span>}><img
                                        onClick={() => {
                                            SendTracking(item.id, "Web,Dataset,Favourite,add");
                                            this.addIdToFavourite(item.id)
                                        }} alt="Not favourite"
                                        style={{float: 'left', marginRight: '4px', cursor: 'pointer', paddingTop:row_options===undefined?"15px":"unset"}} width={20}
                                        src="/images/refonte/star_off.png"/></Tooltip>

                            }
                            <span onClick={() => {
                                SendTracking(item.id, "Web,Dataset,click");
                                this.props.SetRedirectedUrl("/dataset/" + item.id)

                            }} className='content-text' style={{cursor: 'pointer',position:"absolute",paddingTop:row_options===undefined?"15px":"unset",}}
                            >{window.innerWidth < 1200 ? textEllipsis(item.name, 30) : window.innerWidth < 600 ? textEllipsis(item.name, 10) : item.name} </span>
                            {
                                is_public &&
                                <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                         title={<span>This data is public</span>}><img alt="Public data" style={{
                                    float: 'right',
                                    marginRight: '4px'
                                }} width={35} src="/images/refonte/opendata.png"/></Tooltip>

                            }

                            { /*Determinate dataset type icon*//*Determinate if we have to display public icon */
                                item.type === 0 ?
                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>This data was created from observations</span>}><img
                                        alt="Observed data" style={{float: 'right', marginRight: '4px'}} width={35}
                                        src="/images/refonte/microscope.png"></img></Tooltip>
                                    :
                                    item.type === 1 ?
                                        <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                                 title={<span>This data was created by a simulation</span>}><img
                                            alt="Simulated data" style={{float: 'right', marginRight: '4px'}} width={35}
                                            src="/images/refonte/simulated.png"></img></Tooltip>
                                        :
                                        item.type === 2 &&
                                        <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                                 title={<span>This data was drawed</span>}><img alt="Drawing" style={{
                                            float: 'right',
                                            marginRight: '4px'
                                        }} width={35} src="/images/refonte/drawing.png"></img></Tooltip>
                            }


                            {
                                //if set has time : time slider
                                item.mintime < item.maxtime &&
                                <TimeSlider item={item} connected={this.props.connected}
                                            is_public={is_public}></TimeSlider>
                            }
                            {
                                //if set has multiple quality : quality slider
                                quality_count > 1 &&
                                <QualitySlider item={item} is_public={is_public}
                                               AddQualitiesById={this.props.AddQualitiesById} id_set={item.id}
                                               quality_count={quality_count}></QualitySlider>
                            }
                            <br></br>
                            {
                                //Rendering the list of tags
                                row_options !== undefined &&

                                <div style={{display: "inline"}} >{row_options}</div>
                            }
                        </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        set_quality_by_id: state.dataset.sets_quality_by_id,
        sharings: state.sharing.entities,
        datasets: state.dataset.entities,
        dataset_by_tag: state.tag.dataset_by_tag,
        tags: state.tag.entities,
        overwrote_times: state.dataset.overwrote_time_by_id,
        gif_by_dataset: state.dataset.gif_by_dataset,
        unity_loaded: state.dataset.unity_loaded,
        favourite_by_dataset_id: state.user.favourite_by_dataset_id,
        dataset_by_proprity: state.priority.dataset_by_proprity,
        priorities: state.priority.entites,
        connected: state.user.is_connected,
        settings: state.user.settings,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {
        AddOverwriteById: (time_range) => dispatch(AddOverwriteById(time_range)),
        AddQualitiesById: (quality) => dispatch(AddQualitiesById(quality)),
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DatasetInstance);
