import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import {getUserID} from '../store/utility';
class HelpFiji extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
		staff : false,
        source_link : "helpfiles/cionnectome.md",
        connected_user_id : -1,
    }
    
    componentDidMount(){
        this.state.connected_user_id = getUserID();
       // this.props.sendTracking(-1,"Web,Help,Fiji,view");
    }

    render(){
        return(
			<div>
            <div style={{margin:'auto',marginTop:'1%'}}>
                {
                    this.state.source_link !== "" &&
                    <MorphoMDEditor subfolderName={"cionnectome"} sourceLink={this.state.source_link} > </MorphoMDEditor>
                }
        	</div>
			</div>
        );
    }
}

export default HelpFiji;