import React from "react";
import {connect} from "react-redux";
import {DeleteInfoReload, RenameInfoReload, ShareInfoReload} from "../utils/api_callbacks";
import Search from "antd/es/input/Search";
import {getUserID} from "../utils/CookieManagement";
import {IsAtLeastManager} from "../utils/tools";

class InfoInstance extends React.Component {

    state = {
        current_info_rename: -1,
        show_rename_panel: false,
        show_delete_panel: false,
        show_delete_panel_all: false,
        current_info_delete: -1,
    }

    deleteInfo = (id_info) => {
        const chosen_set = this.props.chosen_set;
        DeleteInfoReload(chosen_set, id_info);
        this.closeDeletePanel();
    }

    onRenameInfo = (new_name) => {
        const chosen_set = this.props.chosen_set;
        RenameInfoReload(new_name, this.state.current_info_rename, chosen_set);
        this.closeRenamePanel();
    }

    shareInfo = (info_object) => {
        const chosen_set = this.props.chosen_set;
        ShareInfoReload(chosen_set, info_object);
        this.closeDeletePanel();
    }

    canDeleteOrRename = (info) => {
        if (getUserID() === info.id_people) return true;
        return IsAtLeastManager(this.props.chosen_set);
    }

    openRenamePanel = (id_set) => {
        this.setState({current_info_rename: id_set});
        this.setState({show_rename_panel: true});
    }

    closeRenamePanel = () => {
        this.setState({current_info_rename: -1});
        this.setState({show_rename_panel: false});
    }

    openDeletePanel = (id_set) => {
        this.setState({current_info_delete: id_set});
        this.setState({show_delete_panel: true});
    }

    closeDeletePanel = () => {
        this.setState({current_info_delete: -1});
        this.setState({show_delete_panel: false});
    }

    render() {
        const item = this.props.info;
        if (item === undefined) return <div></div>
        return <div>
            {/* Left part of the info rendering (name or rename)*/}
            {
                this.state.show_rename_panel === true && this.state.current_info_rename === item.id ?
                    //if we chose to rename the info
                    <div>
                        <Search
                            placeholder="New information name..."
                            disabled={!this.canDeleteOrRename(item)}
                            allowClear
                            enterButton="Apply"
                            size="default"
                            style={{width: "50%"}}
                            onSearch={this.onRenameInfo}
                        />
                        <img alt="Close" style={{
                            filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                            display: 'inline',
                            marginLeft: '5px',
                            cursor: 'pointer'
                        }}
                             onClick={() => {
                                 if (this.canDeleteOrRename(item)) {
                                     this.closeRenamePanel()
                                 }
                             }} width={30} src="/images/refonte/window_close.png"></img>
                    </div>
                    :
                    //normal display of infos
                    <div>
                        <img alt="Rename information"
                             style={{
                                 filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                                 display: 'inline',
                                 cursor: 'pointer',
                                 marginRight: '20px'
                             }} onClick={() => {
                            if (this.canDeleteOrRename(item)) this.openRenamePanel(item.id)
                        }} width={30} src="/images/refonte/rename.png"></img>
                        <span style={{color: '#44D4FF', fontSize: '18px'}} className='layout-text'>{item.infos}  </span>
                    </div>

            }
            {
                this.state.show_delete_panel === true && this.state.current_info_delete === item.id ?
                        <div>
                            <span style={{ float:'right',marginRight:"10%"}}>Delete information ?</span>
                            <br></br>
                            <img alt="Delete info" style={{display: 'inline', marginTop:"1%",float:'right', cursor: 'pointer',marginRight:"10%"}}
                                 onClick={() => this.deleteInfo(item.id)} width={30} src="/images/valid.png"></img> <img
                            alt="Close delete panel" style={{display: 'inline',  marginTop:"1%", float:'right',marginRight:"3%", cursor: 'pointer'}}
                            onClick={() => this.closeDeletePanel()} width={30} src="/images/refonte/window_close.png"></img>
                        </div>
                        :
                        <div style={{display: "inline-block", width: "20%", float: "right"}}>
                            {/* Right part of the info (delete or share) */}
                            <img alt="Delete information" style={{display: 'inline', marginLeft: '20%', cursor: 'pointer'}}
                                 onClick={() => {
                                     if (this.canDeleteOrRename(item)) {
                                         this.openDeletePanel(item.id);
                                     }
                                 }} width={30} src="/images/garbage.png"></img>
                            <img alt="Share information" style={{
                                filter: "grayscale(" + (this.canDeleteOrRename(item) ? "0%)" : "60%)"),
                                display: 'inline',
                                marginLeft: '12px',
                                cursor: 'pointer'
                            }}
                                 onClick={() => {
                                     if (this.canDeleteOrRename(item)) this.shareInfo(item)
                                 }} width={30}
                                 src={item.common === 1 ? "/images/refonte/share_on.png" : "/images/refonte/share_off.png"}></img>
                        </div>
            }
            <p>Type : {item.datatype}<br></br>Date : {item.date.replace('T', ' ').replace('Z', ' ')}<br></br>Owner
                : {this.props.owner_by_info[item.id]}</p>
        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        rights_by_dataset_id: state.user.rights_by_dataset_id,
        owner_by_info: state.infos.owner_by_info,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoInstance);
