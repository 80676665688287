import React from 'react';
import SimulationCreationCustomForm from '../containers/SimulationUploadForm';
import {getUserToken} from '../store/utility';

class UploadSimulation extends React.Component{
    render() {
        return( 
            getUserToken() === null?
            <div style={{marginTop:'7%'}}><span>Please log in</span> </div>
            :
            <div>
            <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px'}}> Create a simulation </h1>
            <div style={{marginTop:'7%'}}>
            <SimulationCreationCustomForm {...this.props}></SimulationCreationCustomForm>
            </div>
            </div>
        );
    }
}

export default UploadSimulation;