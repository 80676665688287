import React from 'react';
import {Upload,Input,Icon,Tooltip,Form,Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../App.css';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import {getUserToken,getUserID} from '../store/utility'
const { TextArea } = Input;

class SimulationCreationCustomForm extends React.Component {
    handleFormSubmit = (event) => {
        event.preventDefault();
        const title = event.target[0].value;
    }

    state = {
        token: "",
        connected_user_id: -1,
        name_entered: false,
        comment_entered: false,
        file_uploaded: false,
        file_uploaded_zip: false,
        simulation_name: "",
        simulation_comment: "",
        formtype: 0,
        window_type: "",
        lpy_simulation: "",
        simulation_error: "",
        buttonClicked: false,
        fileList: [],
        fileListUpload: [],
        uploading: false,
        uploadingzip: false,
        is_not_lpy: false,
        is_not_zip: false,
        is_simulator: false,
        is_admin: false
    }

    isUserStaff = () => {
        axios.get('/api/people-simulator/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({ is_simulator: res.data['simulator'] });
            });
    }

    componentDidMount() {
        this.state.token = getUserToken();
        this.state.connected_user_id = getUserID();

        axios.get('/api/people-simulator/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({ is_simulator: res.data['simulator'] });
            });

        axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({ is_admin: res.data['staff'] });
            });
    }

    componentWillUnmount() { }

    PatchSimulationChange = () => {
        this.setState({ buttonClicked: true });
        if (this.state.simulation_name === "") {
            this.state.simulation_name = "simulation_not_named";
        }
        if (this.state.simulation_comment === "") {
            this.state.simulation_comment = "...";
        }

        if (!this.state.file_uploaded) { return; }
        //this.props.sendTracking(-1, "Web,Simulation,Upload,create");

        let formData = new FormData();
        formData.append('name', this.state.simulation_name);
        formData.append('comments', this.state.simulation_comment);
        formData.append('file', this.state.fileList[0])
        if (this.state.fileListUpload.length > 0 ){
            formData.append('zip', this.state.fileListUpload[0])
        }


        axios({
            method: 'post',
            url: `/api/createsimulationweb/`,
            headers: { Authorization: `Token ${this.state.token}`,'Content-Type': 'multipart/form-data' },
            data: formData,
        }).then(res => { this.setState({ lpy_simulation: res.data.name }) }).catch(err => this.setState({ simulation_error: err }));
    }

    hoverForm = (type) => {
        this.setState({ window_type: type });
    }

    handleTypeChange = (value) => {
        this.setState({
            formtype: value,
        });
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {
        const { uploading, fileList , uploadingzip,fileListUpload } = this.state;

        const zip_props = {
            onRemove: file => {
                this.setState({ file_uploaded_zip: false });
                this.setState(state => {
                    const index = state.fileListUpload.indexOf(file);
                    const newFileList = state.fileListUpload.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileListUpload: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if (file.name.endsWith('.zip')) {
                    this.setState({ is_not_zip: false });
                    this.setState({ file_uploaded_zip: true });
                    this.setState(state => ({
                        fileListUpload: [...state.fileListUpload, file],
                    }));
                }
                else {
                    this.setState({ is_not_zip: true });
                }
                return false;
            },
            fileListUpload,
        };

        const props = {
            onRemove: file => {
                this.setState({ file_uploaded: false });
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if (file.name.endsWith('.lpy')) {
                    this.setState({ is_not_lpy: false });
                    this.setState({ file_uploaded: true });
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                }
                else {
                    this.setState({ is_not_lpy: true });
                }
                return false;
            },
            fileList,
        };

        return (
            <div>
                {
                    !this.state.is_simulator && !this.isUserStaff() ?
                        <div>You are not authorized to access this page</div>
                        :
                        this.state.lpy_simulation === "" && this.state.simulation_error ?
                            <div><h2 style={{ color: '#292e37', fontFamily: 'Baskerville', fontSize: '34px', textAlign: 'center', fontWeight: "bold", minWidth: '750px' }}>Error during creation : {this.state.simulation_error}</h2>
                                <br></br><br></br>
                                <Tooltip placement="bottom" title={<span>Back welcome page</span>}><NavLink exact to={{ pathname: '/' }}><h2 style={{ color: '#292e37', fontFamily: 'Baskerville', fontSize: '27px', textAlign: 'center', fontWeight: "bold", minWidth: '750px' }}>Back to welcome page</h2></NavLink></Tooltip>
                            </div>
                            :
                            this.state.lpy_simulation !== "" ?
                                <div><h2 style={{ color: '#292e37', fontFamily: 'Baskerville', fontSize: '34px', textAlign: 'center', fontWeight: "bold", minWidth: '750px' }}>Your simulation "{this.state.simulation_name}" has been created</h2>
                                    <br></br><br></br>
                                    <Tooltip placement="bottom" title={<span>See simulation details</span>}><NavLink exact to={{ pathname: `/simulation`, preChoosenSimulation: this.state.name_simulation }}><h2 style={{ color: '#44D4FF', fontFamily: 'Baskerville', fontSize: '27px', textAlign: 'center', fontWeight: "bold", minWidth: '750px' }}>Go to the uploaded simulation</h2></NavLink></Tooltip>
                                </div>
                                :
                                <div>

                                    {
                                        this.state.window_type !== "" &&
                                        <div style={{ backgroundColor: 'white', borderRadius: '25px', border: '2px solid #44D4FF', position: 'absolute', float: 'right', marginLeft: '75%', marginTop: '1px', marginRight: '10px', width: '12%', zIndex: '30' }}>
                                            <br></br>
                                            <h1 style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: '17px' }}>{this.state.window_type}</h1>
                                            <br></br>
                                        </div>
                                    }
                                    <Form>
                                        <Form.Item onChange={e => { this.setState({ simulation_name: e.target.value }) }}>
                                            <Tooltip placement="right" title={<span>Enter the simulation name</span>}>
                                                <h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px' }}>Name : </h2>
                                                <TextArea
                                                    autoSize={{ minRows: 1, maxRows: 6 }}
                                                    style={{ width: '50%', marginLeft: '10px' }}
                                                    prefix={<Icon type="font-colors" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Simulation name"
                                                />
                                            </Tooltip>
                                        </Form.Item>
                                        <Form.Item onChange={e => { this.setState({ simulation_comment: e.target.value }) }}>
                                            <Tooltip placement="right" title={<span>Enter the simulation description</span>}>
                                                <h2 style={{ display: 'inline', color: '#44D4FF', fontSize: '20px', width: '80%' }}>Description : </h2>
                                                <TextArea
                                                    style={{ width: '60%' }}
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    prefix={<Icon type="font-colors" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Simulation description"
                                                />
                                            </Tooltip>
                                        </Form.Item>

                                        {
                                            !this.state.file_uploaded && this.state.buttonClicked ?
                                                <h2 style={{ color: 'red', fontSize: '18px' }}>You need to upload a simulation's script (.lpy file) to create a simulation</h2>
                                                :
                                                this.state.is_not_lpy &&
                                                <h2 style={{ color: 'red', fontSize: '18px' }}>The simulation's script that you upload MUST be a .lpy file !</h2>
                                        }

                                        <div><h2 style={{ fontSize: '21px' }}>
                                            Choose a simulation file
                                        </h2>
                                            <Upload {...props}>
                                                <Button>
                                                    <UploadOutlined /> Select File (lpy)
                                                </Button>
                                            </Upload>
                                        </div>
                                        <br></br><br></br>
                                        <div><h2 style={{ fontSize: '21px' }}>
                                            Choose a data Archive (zip)
                                        </h2>
                                            <Upload {...zip_props}>
                                                <Button>
                                                    <UploadOutlined /> Select Archive (zip)
                                                </Button>
                                            </Upload>
                                        </div>
                                        <br></br><br></br>
                                        <Form.Item>
                                            <Button type="primary" onClick={this.PatchSimulationChange} style={{ borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px' }} >
                                                Create simulation
                                            </Button>
                                        </Form.Item>



                                    </Form>
                                </div>
                }
            </div>
        );
    }
}

export default SimulationCreationCustomForm;