import React from "react";
import {IsAtLeastManager} from "../utils/tools";
import {List, Radio} from "antd";
import {ChangeGroupState, ChangeUserState} from "../utils/api_callbacks";

export const SharingInstance = (props) => {
    const sharings = props.sharings;
    const chosen_set = props.id_set;
    const set_owner = props.set_owner;
    const group = props.group;
    const user = props.user;
    const is_group = (props.type === "group");
    if (sharings === undefined || (group === undefined && user === undefined)) return <div></div>
    let current_type = -1;
    if (is_group) {
        sharings.filter(sharing => sharing.id_group === group.id).map(sharing => {
            if (parseInt(sharing.how) > current_type) {
                current_type = parseInt(sharing.how)
            }
        });
    } else {
        sharings.filter(sharing => sharing.id_who === user.id).map(sharing => {
            if (parseInt(sharing.how) > current_type) {
                current_type = parseInt(sharing.how)
            }
        });
    }
    const value = current_type === 1 ? "manager" : current_type === 0 ? "reader" : "norights";
    return (<List.Item>
        <List.Item.Meta
            title={<span style={{color: ((!is_group && set_owner === user.id) || (is_group && group.id_owner === user.id))?'#44D4FF':'grey', fontSize: '18px',fontWeight:(!is_group && set_owner === user.id)?"bold":"unset"}}
                      className='layout-text'>{is_group ? group.name : user.name+" "+user.surname}{(!is_group && set_owner === user.id)|| (is_group && group.id_owner === user.id)?" (owner) ":""}  </span>}
        />
        <Radio.Group disabled={!IsAtLeastManager(chosen_set) || ((!is_group && set_owner === user.id)|| (is_group && group.id_owner === user.id))} onChange={(e) => {
            if (is_group) {
                ChangeGroupState(group.id, e, props.id_set)
            } else {
                ChangeUserState(user.id, e, props.id_set);
            }
        }}
                     value={value}>
            <Radio value={"manager"}>Manager</Radio>
            <Radio value={"reader"}>Reader</Radio>
            <Radio value={"norights"}>No rights</Radio>
        </Radio.Group>
    </List.Item>);
}

