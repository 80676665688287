import React from "react";
import {Redirect} from "react-router-dom";
import {Tooltip} from 'antd';
import {AddToFavourite, RemoveFromFavourite, SendTracking} from "../utils/api_callbacks";
import {IsAtLeastManager, IsAtLeastReader, isOnStandalone, RetrieveSettings} from "../utils/tools";
import {connect} from "react-redux";
import {encryptString} from "../utils/CookieManagement";
import {AddOverwriteById, AddQualitiesById} from "../store/reducers/datasetSlice";
import {TimeSlider} from "./TimeSlider";
import {QualitySlider} from "./QualitySlider";
import {SetRedirectedUrl} from "../store/reducers/userSlice";

class ChosenSetTopPanel extends React.Component {
    state = {
    }


    // No dataset picked => Head to dataset list
    CancelSetSelection = () => {
        this.RedirectTo("/dataset/");
    }

    // Test path
    IsCurrentPath = (path) => {
        return window.location.pathname === path;
    }

    //Use custom redirection tool
    RedirectTo = (path) => {
        if (window.location.pathname !== path && window.location.pathname + "/" !== path)  this.props.SetRedirectedUrl(path);
    }

    //Callback to api_callbacks for favourite management
    addIdToFavourite = (id_set) => {
        AddToFavourite(id_set);
    }

    //Callback to api_callbacks for favourite management
    RemoveIdFromfavourite = (id_set) => {
        RemoveFromFavourite(id_set);
    }

    //call back to start unity
    openUnityFromAway = () => {
        this.props.openUnityFromAway();
    }
    //call back to start unity
    unityLoadFunc = (dataset) => {
        this.props.unityLoadFunc(dataset);
    }


    render() {
        //Item is gathered by the parent
        const item = this.props.set;
        if (item === undefined) return <div></div>
        //Priority is computed from the store
        let set_priority = undefined;
        if (Object.keys(this.props.dataset_by_proprity).includes(item.id)) {
            set_priority = this.props.priorities[this.props.dataset_by_proprity[item.id]];
        }
        //use store sharings to find if dataset is public (id_who at 0) #TODO : factoriser
        const is_public = Object.values(this.props.sharings).some(sharing => sharing.id_base === item.id && sharing.base === "dataset" && sharing.id_who === 0) || item.public;
        //Dataset quality for the slider (gathered from the store)
        const quality_count = parseInt(this.props.set_quality_by_id[item.id]);
        return (
            <div style={{display: "inline"}} key={item.id}>
                {/* Back to dataset list */}
                <img alt="back" onClick={() => {
                    this.CancelSetSelection();
                }} style={{marginRight: '20px', cursor: 'pointer'}} width={30}
                     src="/images/refonte/back.png"></img>

                {  ((this.props.connected && IsAtLeastReader(item.id)) || is_public) &&

                        <div style={{display: "inline"}}>

                            {
                                //Rendering favourite or not
                                this.props.connected && RetrieveSettings(this.props.settings, "favoritedisplay") !== "false" && this.props.favourite_by_dataset_id[item.id] ?

                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>Remove this dataset from favourites</span>}><img
                                        onClick={() => {
                                            SendTracking(item.id, "Web,Dataset,Favourite,remove");
                                            this.RemoveIdFromfavourite(item.id);
                                        }} alt="Data is favourite" style={{
                                        marginRight: '4px',
                                        cursor: 'pointer'
                                    }} width={20} src="/images/refonte/star_on.png"/></Tooltip>
                                    :
                                    this.props.connected && RetrieveSettings(this.props.settings, "favoritedisplay") !== "false" &&
                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>Set this dataset in favourites</span>}><img
                                        onClick={() => {
                                            SendTracking(item.id, "Web,Dataset,Favourite,add");
                                            this.addIdToFavourite(item.id)
                                        }} alt="Not favourite"
                                        style={{ marginRight: '4px', cursor: 'pointer'}} width={20}
                                        src="/images/refonte/star_off.png"/></Tooltip>

                            }
                            {/* GIF or viewer button */}
                            <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                     title={"Load this dataset in the visualizer"}>
                                <img onContextMenu={(evt) => {
                                    evt.preventDefault();
                                    localStorage.setItem('unityProps', encryptString(JSON.stringify(item)));
                                    window.open(window.location.href, '_blank');
                                    return false;
                                }}
                                     onClick={() => {
                                         if (this.props.unity_loaded || isOnStandalone() || RetrieveSettings(this.props.settings, "standalone_start") === "true") {
                                             this.openUnityFromAway();
                                             SendTracking(item.id, "Web,Dataset,visualize");
                                             this.unityLoadFunc(item);
                                         }
                                     }} style={{
                                    cursor: 'pointer',
                                    marginLeft: '0px',
                                    borderRadius: '10px',
                                    marginRight: '2px'
                                }} width={50}
                                     src={this.props.gif_by_dataset[item.id] === "false" ? this.props.unity_loaded ? "/images/refonte/load_small.png" : "/images/refonte/greyload_small.png" : "/GIF/DATASET_" + item.id + "-50.gif"}
                                     alt={"Load a dataset"}></img>
                            </Tooltip>
                            {
                                //If user is connected and is in collaborative mode , display priorities
                                this.props.connected && RetrieveSettings(this.props.settings, "collaborativemode") !== "false" && set_priority !== undefined &&
                                <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                         title={"Dataset priority : " + set_priority.value}>
                                    <div style={{
                                        width: '2%',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                        height: '50%',
                                        display: "inline",
                                        backgroundColor: set_priority.value === 'Very high' ? 'red' : set_priority.value === 'High' ? 'yellow' : set_priority.value === 'Low' ? '#7CFC00' : 'transparent',
                                        borderRadius: '20% / 20%'
                                    }}></div>
                                </Tooltip>
                            }
                            {/* Access to general */}
                            <img alt="Sessions" onClick={() => {
                                this.RedirectTo("/dataset/" + this.props.chosen_set)
                            }} style={{marginRight: '10px', cursor: 'pointer', borderBottom: 'unset', backgroundColor:this.IsCurrentPath("/dataset/" + this.props.chosen_set)?"#44D4FF70":"unset"}} width={30}
                                 src="/images/refonte/session.png"></img>

                            {/* Access to sessions */}
                            {this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                                <img alt="Sessions" onClick={() => {
                                    this.RedirectTo("/dataset/" + this.props.chosen_set + "/sessions")
                                }} style={{marginRight: '10px', cursor: 'pointer', borderBottom: 'unset'}} width={30}
                                     src="/images/web-link.png"></img>
                            }
                            {/* Access to sharings */}
                            {this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                                <img alt="Sharings" onClick={() => {
                                    this.RedirectTo("/dataset/" + this.props.chosen_set + "/sharings")
                                }} style={{marginRight: '10px', cursor: 'pointer', borderBottom: 'unset'}} width={30}
                                     src="/images/refonte/share.png"></img>
                            }
                            {/* Access to infos */}
                            {this.props.connected && IsAtLeastReader(this.props.chosen_set) &&
                                <img alt="Infos" onClick={() => {
                                    this.RedirectTo("/dataset/" + this.props.chosen_set + "/properties")
                                }} style={{marginRight: '10px', cursor: 'pointer', borderBottom: 'unset'}} width={30}
                                     src="/images/refonte/IconeInfos.png"></img>
                            }
                            {/* Access to update */}
                            {
                                this.props.connected && IsAtLeastManager(this.props.chosen_set) &&
                                <img alt="Settings" onClick={() => {
                                    this.RedirectTo("/dataset/" + this.props.chosen_set + "/update")
                                }} style={{marginRight: '10px', cursor: 'pointer', borderBottom: 'unset'}} width={30}
                                     src="/images/refonte/parameters.png"></img>
                            }

                            {/* Public */}
                            {
                                (is_public) &&
                                <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                         title={<span>This data is public</span>}><img alt="Public data" style={{
                                    float: 'right',
                                    marginRight: '4px'
                                }} width={35} src="/images/refonte/opendata.png"/></Tooltip>

                            }

                            { /*Determinate dataset type icon*//*Determinate if we have to display public icon */
                                item.type === 0 ?
                                    <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                             title={<span>This data was created from observations</span>}><img
                                        alt="Observed data" style={{float: 'right', marginRight: '1%'}} width={35}
                                        src="/images/refonte/microscope.png"></img></Tooltip>
                                    :
                                    item.type === 1 ?
                                        <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                                 title={<span>This data was created by a simulation</span>}><img
                                            alt="Simulated data" style={{float: 'right', marginRight: '1%'}} width={35}
                                            src="/images/refonte/simulated.png"></img></Tooltip>
                                        :
                                        item.type === 2 &&
                                        <Tooltip destroyTooltipOnHide={true} placement="bottom"
                                                 title={<span>This data was drawed</span>}><img alt="Drawing" style={{
                                            float: 'right',
                                            marginRight: '1%'
                                        }} width={35} src="/images/refonte/drawing.png"></img></Tooltip>
                            }
                            {
                                //if set has time : time slider
                                item.mintime < item.maxtime &&
                                <TimeSlider item={item} connected={this.props.connected}
                                            is_public={is_public}></TimeSlider>
                            }
                            {
                                //if set has multiple quality : quality slider
                                quality_count > 1 &&
                                <QualitySlider item={item} is_public={is_public}
                                               AddQualitiesById={this.props.AddQualitiesById} id_set={item.id}
                                               quality_count={quality_count}></QualitySlider>
                            }


                        </div>
                }
            </div>
        );
    }


}

// bind components props to specific parts of the store
// ex : is_connected : state.user.is_connected
const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        set_quality_by_id: state.dataset.sets_quality_by_id,
        sharings: state.sharing.entities,
        datasets: state.dataset.entities,
        dataset_by_tag: state.tag.dataset_by_tag,
        tags: state.tag.entities,
        overwrote_times: state.dataset.overwrote_time_by_id,
        gif_by_dataset: state.dataset.gif_by_dataset,
        unity_loaded: state.dataset.unity_loaded,
        favourite_by_dataset_id: state.user.favourite_by_dataset_id,
        dataset_by_proprity: state.priority.dataset_by_proprity,
        priorities: state.priority.entites,
        connected: state.user.is_connected,
        settings: state.user.settings,
    }
}

// bind components props to specific actions that will update the store
// ex : function_name : (data) => dispatch(function_in_slice()),
const mapDispatchToProps = dispatch => {
    return {
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
        AddOverwriteById: (time_range) => dispatch(AddOverwriteById(time_range)),
        AddQualitiesById: (quality) => dispatch(AddQualitiesById(quality)),

    }
}

// connect bind the 2 functions above to the component
export default connect(mapStateToProps, mapDispatchToProps)(ChosenSetTopPanel);
