import React from "react";
import {connect} from "react-redux";
import {chooseFilterType, ResetSortsAndFilters} from "../store/reducers/datasetSlice";
import DatasetGroupFilter from "./datasetGroupFilterList";
import DatasetTagFilter from "./datasetTagFilterList";
import PhyloTree from "./Phylotree";
import DatasetSearchFilter from "./datasetSearchFilterList";
import {Menu, Tooltip} from "antd";
import {SendTracking} from "../utils/api_callbacks";
import DatasetAdvancedFilter from "./datasetAdvancedFilterList";
import {SetRedirectedUrl} from "../store/reducers/userSlice";


class DatasetFilteringPanel extends React.Component {

    state = {}

    componentDidMount() {
    }


    RenderPanel = () => {
        switch (this.props.dataset.current_filter_type) {
            case 0:
                return <DatasetGroupFilter></DatasetGroupFilter>;
            case 1:
                return <PhyloTree></PhyloTree>
            case 2:
                return <DatasetTagFilter></DatasetTagFilter>;
            case 3:
                return <DatasetSearchFilter></DatasetSearchFilter>
            case 4:
                return <DatasetAdvancedFilter></DatasetAdvancedFilter>;
            default:
                return <span>Nothing</span>;
        }
    }


    render() {
        return <div className="FilteringPanel" >
            <Menu style={{borderBottom:"unset"}}  selectedKeys={[this.props.dataset.current_filter_type.toString()]} mode="horizontal"
            >
                {this.props.connected &&
                    <Menu.Item
                               className="removeSelectedBorder" key="0" onClick={() => {
                        SendTracking(-1, "Web,DatasetList,Filter,Group");
                        this.props.ChooseFiltering(0);
                    }}>
                        {this.props.dataset.current_filter_type.toString() === "0" &&
                            <img onClick={() => {this.props.SetRedirectedUrl("/group");}}  style={{verticalAlign:"top"}}  alt="group management page" width="20" src="/images/refonte/parameters.png"></img>
                        }
                        <Tooltip style={{textAlign:"left"}} placement="bottom" title={<span>Filter by group or study</span>}><img src={this.props.dataset.current_filtered_studies.length>0 || this.props.dataset.current_filtered_orphans.length>0?"/images/refonte/users-group_2_notif.png":"/images/refonte/users-group_2.png"} width="40" alt={"Studiess"}></img></Tooltip>

                    </Menu.Item>
                }
                <Menu.Item ref='sortTree'
                           disabled={this.props.dataset.current_phylotree === ""}
                           className="removeSelectedBorder" key="1" onClick={() => {
                    SendTracking(-1, "Web,DatasetList,Filter,Tree");
                    this.props.ChooseFiltering(1);
                }}>
                    <Tooltip placement="bottom" title={<span>Filter by phylogenetic tree</span>}><img src={this.props.dataset.current_filtered_tree_branch !== -1?"/images/refonte/phylogenetic_notif.png":"/images/refonte/phylogenetic.png"} width="40" alt={"Phylotree"}></img></Tooltip>
                </Menu.Item>
                {this.props.connected &&

                    <Menu.Item ref='sortFavorites'
                               className="removeSelectedBorder" key="2" onClick={() => {
                        SendTracking(-1, "Web,DatasetList,Filter,Tags");
                        this.props.ChooseFiltering(2);
                    }}>
                        {this.props.dataset.current_filter_type.toString() === "2" &&
                            <img onClick={() => {this.props.SetRedirectedUrl("/tags");}} style={{verticalAlign:"top"}}  alt="tags management page" width="20" src="/images/refonte/parameters.png"></img>
                        }
                        <Tooltip placement="bottom" title={<span>Filter by tags</span>}><img src={this.props.dataset.current_filtered_tags.length>0?"/images/refonte/price-tag_notif.png":"/images/refonte/price-tag.png"} width="35" alt={"Tags"}></img></Tooltip>

                    </Menu.Item>
                }
                <Menu.Item ref='sortFavorites'
                           className="removeSelectedBorder" key="3" onClick={() => {
                    SendTracking(-1, "Web,DatasetList,Filter,Search");
                    this.props.ChooseFiltering(3);
                }}>
                    <Tooltip placement="bottom" title={<span>Filter by Search fields</span>}><img
                        alt={"FilterByText"} width='30' src={this.props.dataset.current_filter_search_study !== "" || this.props.dataset.current_filter_search_tag !== "" || this.props.dataset.current_filter_search_group !== "" || this.props.dataset.current_filter_search_name?'/images/search_notif.png':'/images/search.png'}></img></Tooltip>
                </Menu.Item>
                {this.props.connected &&
                <Menu.Item ref='sortFavorites'
                           className="removeSelectedBorder" key="4" onClick={() => {
                    SendTracking(-1, "Web,DatasetList,Filter,Favourites");
                    this.props.ChooseFiltering(4);
                }}>
                    <Tooltip placement="bottom" title={<span>Advanced filters</span>}><img
                        alt={"FilterByText"} width='30' src={this.props.dataset.current_only_favourites || this.props.dataset.current_only_user || this.props.dataset.current_only_others ?'/images/refonte/star_on_3_notif.png':'/images/refonte/star_on_3.png'}></img></Tooltip>
                </Menu.Item>
                }
            </Menu>
            <div style={{backgroundColor:"#67C4F826",marginTop:"3px"}}>{this.RenderPanel()}</div>


        </div>
    }
}


const mapStateToProps = (state) => {
    return {
        dataset: state.dataset,
        connected: state.user.is_connected,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = (dispatch) => {
    return {
        ChooseFiltering: (filter) => dispatch(chooseFilterType(filter)),
        ResetSortsAndFilters: () => dispatch(ResetSortsAndFilters()),
        SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetFilteringPanel);