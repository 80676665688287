import React from "react";
import {connect} from "react-redux";
import {AutoComplete, Button, Input, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {AddNewInfo, ChooseSetFromUrl, SendTracking} from "../utils/api_callbacks";
import ChosenSetTopPanel from "../Dataset/ChosenSetTopPanel";
import {IsAdminOrCreator} from "../utils/tools";

const {Option} = AutoComplete;

class NewInfo extends React.Component {

    state = {
        new_name: "Default_name",
        new_type: "Label",
        fileList: [],
        file_uploaded: false
    }

    componentDidMount() {
        ChooseSetFromUrl(window.location.pathname, this.props.chosen_set);
    }

    UploadInfo = () => {
        this.setState({buttonClicked: true});
        if (this.state.new_name === "") {
            this.state.set_name = "New info not named";
        }

        if (!this.state.file_uploaded) {
            return;
        }

        SendTracking(-1, "Web,Infos,Upload,create");
        AddNewInfo(this.state.new_name, this.state.new_type, this.props.chosen_set, this.state.fileList[0])
    }

    render() {
        const {uploading, fileList} = this.state;
        const props = {
            onRemove: file => {
                this.setState({file_uploaded: false});
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                if (file.name.endsWith('.txt')) {
                    this.setState({file_uploaded: true});
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                }
                return false;
            },
            fileList,
        };
        const chosen_dataset = this.props.datasets[parseInt(this.props.chosen_set)]
        return <div>

            <ChosenSetTopPanel {...this.props} set={chosen_dataset}></ChosenSetTopPanel>
            <br></br>
            {chosen_dataset!==undefined &&<p className={"DatasetDescriptionName"}>{chosen_dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${chosen_dataset.id})` : ""}</p>}
            {this.props.connected && <div style={{marginTop: "1%"}}>
                    <span style={{fontSize: '21px', color: 'black'}}>Create a property</span>
                <br></br>   <br></br>
                <h2 style={{marginTop:"2%",display: 'inline', color: '#44D4FF', fontSize: '19px'}}>Name</h2> <br></br><br></br>
                <Input onChange={(value) => {
                    this.setState({new_name: value.target.value})
                }} style={{width: '60%'}} placeholder="Default name"/><br></br><br></br>
                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '19px'}}>Property type</h2>
                <br></br><br></br>
                <Select style={{width: '200px', marginLeft: '10px'}}
                        defaultValue={this.state.new_type}
                        onChange={(value) => this.setState({new_type: value === 0 ? "label" : value === 1 ? "number" : value === 2 ? "text" : "others"})}>
                    <Option value={0}>Label</Option>
                    <Option value={1}>Number</Option>
                    <Option value={2}>Text</Option>
                    <Option value={3}>Others</Option>
                </Select><br></br><br></br>
                <h2 style={{display: 'inline', color: '#44D4FF', fontSize: '19px'}}>Upload file</h2> <br></br><br></br>

                <Upload  {...props}>
                    <Button>
                        <UploadOutlined/> Select File (txt)
                    </Button>
                </Upload>

                <Button type="primary" onClick={this.UploadInfo}
                        style={{borderColor: '#44D4FF', backgroundColor: '#44D4FF', marginRight: '10px'}}>
                    Upload info
                </Button>

            </div>
            }
        </div>

    }
}

const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        connected: state.user.is_connected,
        datasets: state.dataset.entities,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(NewInfo);
