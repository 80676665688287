import React from "react";
import {connect} from "react-redux";
import {Button, Col, List, message, Row} from "antd";
import {bytesToSize, IsAdminOrCreator, IsAtLeastReader,SecondsToDisplay} from "../utils/tools";
import axios from "axios";
import {getUserToken} from "../utils/CookieManagement";
import FloatingPanel from "../components/center_panel";
import {LoadUserDatasets} from "../utils/api_callbacks";


class DatasetGeneralView extends React.Component {

    componentDidMount() {

    }

    state = {
        display_loading_panel : false,
        loading_panel_text : "",

    }

    bind_loading_panel = (isactive,text) => {
        this.setState({display_loading_panel:isactive,loading_panel_text:text});
    }

    // call back to the backend to generate ro crate for dataset and user
    export_dataset = () => {
        this.bind_loading_panel(true,"Exporting dataset , please wait . . .");
        axios({
            method: 'get',
            url: `/api/exportdataset/`,
            params: {id_dataset: this.props.chosen_set},
            headers: {Authorization: `Token ${getUserToken()}`}
        }).then(res => {
            this.bind_loading_panel(false,"");
            let path = res.data.path;
            window.open(path);
            // GATHER TEMP URL TO RO-CRATE
            // DOWNLOAD THE RO-CRATE
        }).catch(err => {
            this.bind_loading_panel(false,"");
            console.log(err);
        });

    }
    render() {
        //compute html from tags to the dataset
        var tag_s = [];
        if (Object.keys(this.props.tags_for_set).includes(this.props.chosen_set.toString())) {
            tag_s = this.props.tags_for_set[this.props.chosen_set.toString()];
        }
        var tags_set = Object.values(this.props.tags).filter(tag => tag_s.includes(tag.id));

        const tags_options = (tags_set.length === 0 ? undefined : tags_set.map(tag => <Col key={tag.id}
                                                                                           span={2}>
            <span style={{
                border: 'solid 1px grey',
                borderRadius: '3px',
                backgroundColor: tag.color,
                color: 'black'
            }}>{tag.name} {IsAdminOrCreator(this.props.chosen_set) ? ` (id ${tag.id})` : ""}</span>
        </Col>));

        //dataset object from store
        const dataset = this.props.datasets[this.props.chosen_set]
        if (dataset === undefined) {
            return <div></div>
        }


        // find study and group for the dataset if is not orphans, using the store user slice
        let set_group = undefined;
        let set_study = undefined;
        if (dataset.id_study_id !== undefined && dataset.id_study_id !== -1) {
            const groups = this.props.user.group_list;
            const studies = this.props.user.studies_by_group;
            if (studies !== {}) {
                for (const groupid in studies) {
                    let flag = false;
                    studies[groupid].map(study => {
                        if (study.id === dataset.id_study_id) {
                            set_study = study;
                            flag = true;
                        }
                    });
                    if (flag) {
                        for (const gid in groups) {
                            if (groups[gid].id === set_study.id_group) {
                                set_group = groups[gid];
                                break;
                            }

                        }
                    }
                }
            }

        }

        // get links from chosen dataset from store
        let links = this.props.set_links[this.props.chosen_set];
        if (links === undefined) {
            links = [];
        }
        // owner from store
        const owner = this.props.owner_by_id[this.props.chosen_set]
        // size from store
        const size = this.props.size_by_id[this.props.chosen_set]

        // Find if dataset is public #TODO : factoriser
        const is_public = Object.values(this.props.sharings).some(sharing => sharing.id_base === dataset.id && sharing.base === "dataset" && sharing.id_who === 0) || dataset.public;
        // Find if dataset priority
        const set_prio = Object.entries(this.props.sets_by_priority).filter(([k, v]) => k === this.props.chosen_set.toString());
        var priority = -1;
        if (set_prio.length > 0 && set_prio[0][1] !== 4) {
            priority = this.props.priorities[set_prio[0][1]];
        }
        // NCBI categories
        var categories = "Unclassified"
        if (this.props.categories_by_id !== undefined && this.props.categories_by_id[this.props.chosen_set] !== undefined) {
            categories = this.props.categories_by_id[this.props.chosen_set];
        }

        return (this.state.display_loading_panel ?
                <FloatingPanel text={this.state.loading_panel_text}></FloatingPanel>
                :
                <div className="SingleSetView">
                    {
                        (is_public || (this.props.connected && IsAtLeastReader(dataset.id))) && <div>
                            { /* Complete view only if user reader */}
                            <p className={"DatasetDescriptionName"}>{dataset.name}{IsAdminOrCreator(this.props.chosen_set) ? ` (id ${dataset.id})` : ""}</p>
                            <img alt="No dataset preview gif available."
                                 style={{cursor: 'pointer', float: 'right', marginRight: '5px'}} width={300}
                                 src={"/GIF/DATASET_" + dataset.id + "-300.gif"}></img>
                            <br/>
                            <br/>
                            <span style={{fontSize: '21px', color: 'black', marginTop: "1%"}}>Dataset metadata</span>
                            <br/>
                            <div className={"DivSpacingDatasetDescription"}>
                                <h2 className={"DatasetDescriptionProperty"}>
                                    Upload the : </h2>
                                <span
                                    className={"DatasetDescriptionValue"}>{dataset.date.replace("T", " ").replace("Z", " ")}</span>
                            </div>
                            <div className={"DivSpacingDatasetDescription"}><h2 className={"DatasetDescriptionProperty"}>
                                Owner name : </h2>
                                <span className={"DatasetDescriptionValue"}>{owner}</span>
                            </div>
                            <div className={"DivSpacingDatasetDescription"}><h2 className={"DatasetDescriptionProperty"}>
                                Description : </h2>
                                <span className={"DatasetDescriptionValue"}>{dataset.comments}</span>
                            </div>
                            {dataset.spf > 0 &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Time Post Fertilization : </h2>
                                    <span className={"DatasetDescriptionValue"}>{SecondsToDisplay(dataset.spf)}s </span>
                                </div>}
                            {dataset.dt > 0 &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Delta time : </h2>
                                    <span className={"DatasetDescriptionValue"}>{SecondsToDisplay(dataset.dt)}s </span>
                                </div>}
                            <div className={"DivSpacingDatasetDescription"}><h2 className={"DatasetDescriptionProperty"}>
                                Type : </h2>
                                {
                                    dataset.type === 0 ?
                                        <img alt="Observed data" className={"DatasetTypeIcon"}
                                             width={35} src="/images/refonte/microscope.png"></img>
                                        :
                                        dataset.type === 1 ?
                                            <img alt="Simulated data"
                                                 className={"DatasetTypeIcon"}
                                                 width={35} src="/images/refonte/simulated.png"></img>
                                            :
                                            dataset.type === 2 &&
                                            <img alt="Drawing" className={"DatasetTypeIcon"}
                                                 width={35} src="/images/refonte/drawing.png"></img>
                                }
                            </div>
                            {
                                dataset.id_study_id !== undefined && dataset.id_study_id !== -1 &&
                                <div className={"DivSpacingDatasetDescription"}>
                                    {set_group !== undefined &&
                                        <div><h2 className={"DatasetDescriptionProperty"}> Group : </h2>
                                            <span className={"DatasetDescriptionValue"}> {set_group.name}</span>
                                            <br/>
                                        </div>

                                    }
                                    <br></br>
                                    {set_study !== undefined &&
                                        <div><h2 className={"DatasetDescriptionProperty"}> Study : </h2>
                                            <span className={"DatasetDescriptionValue"}> {set_study.name}</span>
                                        </div>
                                    }
                                </div>
                            }
                            <div className={"DivSpacingDatasetDescription"}><h2 className={"DatasetDescriptionProperty"}>
                                Taxonomy : </h2>
                                <span
                                    className={"DatasetDescriptionValue"}>{categories.replace(/[\n\r]/g, ' ').replace(/;/gi, ' : ')}</span>
                            </div>
                            {dataset.id_ncbi !== -1 &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    NCBI Taxonomy ID : </h2>
                                    <span className={"DatasetDescriptionValue"}>{dataset.id_ncbi}</span>
                                </div>}
                            {
                                bytesToSize(size) !== undefined && !isNaN(bytesToSize(size)) &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Dataset Size : </h2>
                                    <span className={"DatasetDescriptionValue"}>{bytesToSize(size)}</span>
                                </div>
                            }
                            {
                                priority !== -1 && priority !== undefined &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Priority : </h2>
                                    <span className={"DatasetDescriptionValue"}>{priority.value}</span>
                                </div>
                            }
                            {
                                tags_options !== undefined &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Tags : </h2>
                                    <Row justify="start">{tags_options}</Row>
                                </div>
                            }
                            {links.length > 0 &&
                                <div className={"DivSpacingDatasetDescription"}><h2
                                    className={"DatasetDescriptionProperty"}>
                                    Links : </h2>
                                    <List

                                        itemLayout="horizontal"
                                        style={{
                                            width: '60%',
                                            paddingLeft: '15px',
                                            outline: ' 0.5px solid #44D4FF',
                                            outlineOffset: '2px'
                                        }}
                                        dataSource={links}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<span
                                                        style={{color: 'grey', fontSize: '18px'}}
                                                        className='content-text'>{item.description}</span>}
                                                    description={<span style={{color: 'grey', fontSize: '18px'}}
                                                                       className='layout-text'><p><a
                                                        style={{color: '#44D4FF'}}
                                                        href={item.link}>{item.link}</a>
                                            </p></span>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </div>}

                            {
                                this.props.staff &&
                                <Button style={{float: "right", "marginRight": "30px"}}
                                        onClick={() => this.export_dataset()}> Export Dataset
                                </Button>
                            }

                        </div>
                    }
                </div>

        )
    }
}
// bind components props to specific parts of the store
// ex : is_connected : state.user.is_connected
const mapStateToProps = (state) => {
    return {
        chosen_set: state.dataset.current_choosen_set,
        datasets: state.dataset.entities,
        set_links: state.dataset.links_by_dataset,
        staff: state.user.is_staff,
        owner_by_id: state.dataset.set_owner_by_id,
        connected: state.user.is_connected,
        size_by_id: state.dataset.size_by_datasets,
        sets_by_priority: state.priority.dataset_by_proprity,
        priorities: state.priority.entities,
        tags_for_set: state.tag.dataset_by_tag,
        tags: state.tag.entities,
        sharings: state.sharing.entities,
        user: state.user,
        categories_by_id : state.dataset.set_categorie_by_id,
    }
}


// bind components props to specific actions that will update the store
// ex : function_name : (data) => dispatch(function_in_slice()),
const mapDispatchToProps = dispatch => {
    return {}
}

// connect bind the 2 functions above to the component
export default connect(mapStateToProps, mapDispatchToProps)(DatasetGeneralView);