import React from "react";
import axios from "axios";
import Plot from 'react-plotly.js';
import {Menu} from "antd";

function sortObject(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}
class DisplayDownloadsInteraction extends React.Component {

    componentDidMount() {
        axios({
            method: 'get',
            url: `/api/getdownloadinteractions/`,
            params: {
                maxLimit: 1000,
            }}).then((res) => {
                return res.data.map((element) =>
                    {
                        var splitted_elem = element.name.split(',');
                        var element_os = splitted_elem[3];
                        var element_build = splitted_elem[4];
                        var element_version = splitted_elem[5];
                        element.os = element_os;
                        element.build = element_build;
                        element.version = element_version;
                        return element;
                    })
            }).then(interactions => {
                var interaction_by_build = {};
                var count_by_build = {};
                var interaction_by_os = {};
                var count_by_os = {};
                var min_count_date = 0;
                var max_count_date = 0;
                var min_date = "";
                var max_date = "";
                var interaction_by_date = {};
                var count_by_date = {};
                interactions.forEach(element => {
                    if (!(element.os in interaction_by_os)){
                        interaction_by_os[element.os] =[];
                    }
                    interaction_by_os[element.os].push(element);
                    if (!(element.build in interaction_by_build)){
                        interaction_by_build[element.build] =[];
                    }
                    interaction_by_build[element.build].push(element);
                    var date = element.access.split("T")[0];
                    if (min_date === "" || date < min_date){
                        min_date = date;
                    }
                    if (max_date === "" || date > max_date){
                        max_date = date;
                    }
                    if (!(date in interaction_by_date)){
                        interaction_by_date[date] =[];
                    }
                    interaction_by_date[date].push(element);
                });
                //console.log(interaction_by_date);
                for (const [key, value] of Object.entries(interaction_by_os)) {
                    count_by_os[key] = value.length;
                }
                for (const [key, value] of Object.entries(interaction_by_build)) {
                    count_by_build[key] = value.length;
                }
                for (const [key, value] of Object.entries(interaction_by_date)) {
                    if (value.length < min_count_date){
                        min_count_date = value.length;
                    }
                    if (value.length > max_count_date){
                        max_count_date = value.length;
                    }
                    count_by_date[key] = value.length;
                }
                //console.log(count_by_date);
                this.setState({interaction_list:interactions});
                this.setState({min_date:min_date});
                this.setState({max_count_date:max_count_date});
                this.setState({min_count_date:min_count_date});
                this.setState({max_date:max_date});
                this.setState({interaction_by_os:interaction_by_os});
                this.setState({interaction_by_build:interaction_by_build});
                this.setState({interaction_by_date:interaction_by_date});
                this.setState({count_by_build:sortObject(count_by_build)});
                this.setState({count_by_os:sortObject(count_by_os)});
                this.setState({count_by_date:sortObject(count_by_date)});
            });
    }

    state = {
        interaction_list : [],
        interaction_by_build : {},
        count_by_build : {},
        interaction_by_os : {},
        count_by_os : {},
        interaction_by_date : {},
        count_by_date : {},
        min_date : "",
        min_count_date : 0,
        max_count_date : 0,
        max_date : "",
        display_type : "date",
    }

    handleClick = e => {
        this.setState({
            display_type: e.key,
        });
    };

    render(){
        return (<div>
            {
                this.state.interaction_list === null || this.state.interaction_list === undefined || this.state.interaction_list.length === 0 ?
                <div style={{marginTop:"10%"}}>
                    <h> Interaction list is empty or still loading. </h>
                </div>
                :
                    <div style={{marginTop:"10%"}}>
                    <div style={{width:'100%'}}>
                        <Menu onClick={this.handleClick} style={{display:'flex',justifyContent: 'center'}} defaultSelectedKeys={this.state.source_link} mode="horizontal">
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="date">
                                <span className='new-content-text'> Downloads by date </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="os">
                                <span className='new-content-text'> Downloads by Operating System </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="build">
                                <span className='new-content-text'> Downloads by build </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                        {this.state.display_type === "date" ?
                    <Plot
                        style={{width:'100%'}}
                        data={[
                            {
                                x: Object.keys(this.state.count_by_date),
                                y: Object.values(this.state.count_by_date),
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: {color: 'red'},
                            }
                        ]}
                        layout={ {title: 'MorphoNet application download history',
                            xaxis: {
                                autorange: true,
                                range: [this.state.min_date, this.state.max_date],
                                rangeselector: {buttons: [
                                        {
                                            count: 1,
                                            label: '1m',
                                            step: 'month',
                                            stepmode: 'backward'
                                        },
                                        {
                                            count: 6,
                                            label: '6m',
                                            step: 'month',
                                            stepmode: 'backward'
                                        },
                                        {step: 'all'}
                                    ]},
                                rangeslider: {range: [this.state.min_date, this.state.max_date]},
                                type: 'date'
                            },
                            yaxis: {
                                autorange: true,
                                range: [this.state.min_count_date, this.state.max_count_date],
                                type: 'linear'
                            }} }
                    />
                :
                    this.state.display_type === "os" ?
                        <Plot
                            style={{width:'100%'}}
                            data={[
                                {
                                    x: Object.keys(this.state.count_by_os),
                                    y: Object.values(this.state.count_by_os),
                                    type: 'bar',
                                }
                            ]}
                            layout={ {title: 'MorphoNet application downloads by Operating System',
                                } }
                        />
                    :
                        <Plot
                            style={{width:'100%'}}
                            data={[
                                {
                                    x: Object.keys(this.state.count_by_build),
                                    y: Object.values(this.state.count_by_build),
                                    type: 'bar',
                                }
                            ]}
                            layout={ {title: 'MorphoNet application downloads by build type',
                            } }
                        />
                        }
                    </div>

            }
        </div>);
    }

}
export default DisplayDownloadsInteraction;