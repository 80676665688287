import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Menu} from 'antd';
import {getUserToken,getUserID} from '../store/utility'
import axios from 'axios';
import MorphoMDEditor from "../components/MarkdownEditor";

class Help extends React.Component {
    constructor(props){
        super(props);
	}
    state = {
        staff : false,
        token : "",
        connected_user_id : -1,
        menu : "welcome",
        beta: false,
    }

    componentDidMount() {
        this.state.connected_user_id =  getUserID();
        this.setState({token : getUserToken()});
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("menu")
        if (type !== undefined && type !== "" && type !== null){
            this.setState({menu:type});
        }
        else{
            this.setState({menu:"welcome"});
        }

        if ( getUserToken() !== null && getUserToken() !== ""){
            axios.get('/api/people-betatest/', { headers: { Authorization: `Token ${getUserToken()}` } })
            .then(res => {
                this.setState({beta:res.data['beta']});
            });
        }

    }

    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });
        }
    }

    handleClick = e => {
        this.setState({
            menu: e.key,
        });
    }

    render(){
        return(
			<div>
            <div style={{margin:'auto',marginTop:'3%',textAlign:'center'}}>
                <h1 style={{fontWeight:'bold',fontSize:'42px', textDecoration:'underline'}}>Do you need some help ?</h1>
                <br></br>
                <br></br>
                <div style={{width:'100%'}}>
                    <Menu onClick={this.handleClick} style={{display:'flex',justifyContent: 'center'}} defaultSelectedKeys={this.state.menu} mode="horizontal">
                        <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="welcome">
                            <span className='new-content-text' style={{fontSize:'30px'}}> Welcome </span>
                        </Menu.Item>
                        <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="firsthelp">
                            <span className='new-content-text' style={{fontSize:'30px'}}> First Helpers </span>
                        </Menu.Item>
                        <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="advanced">
                            <span className='new-content-text' style={{fontSize:'30px'}}> Advanced Helpers </span>
                        </Menu.Item>
                        <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="devHelp">
                            <span className='new-content-text' style={{fontSize:'30px'}}> Technical Help </span>
                        </Menu.Item>
                        <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="anyquestion">
                            <span className='new-content-text' style={{fontSize:'30px'}}> Any Questions ? </span>
                        </Menu.Item>
                    </Menu>
                </div>


                <br></br>
                <br></br>

                <Row style={{textAlign:'center', margin:'auto', justifyContent:'center'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {
                        this.state.menu === "welcome" &&
                        <div>
                            <div style={{margin:'auto'}}>
                                <MorphoMDEditor subfolderName={"welcome"} sourceLink={"helpfiles/welcome.md"} > </MorphoMDEditor>
                            </div>
                        </div>
                    }
                    {
                        this.state.menu === "firsthelp" &&
                        <div style={{marginLeft:'8%'}}>
                            <Col  span={8} style={{width: 275, margin:'auto', justifySelf:'center'}} >
                                <div style={{ margin:'auto', border:'2px solid grey', borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"Application"} width={100} src='images/refonte/computer.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink exact to={{pathname:`/help_standalone`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Application</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Download and use MorphoNet Applications</span>
                                </div>
                            </Col>
                            <Col  span={8} style={{width: 275, margin:'auto', justifySelf:'center'}} >
                                <div style={{ margin:'auto', border:'2px solid grey', borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"Application"} width={100} src='images/refonte/web_logo.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink exact to={{pathname:`/help_website`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Website</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Usage of MorphoNet Website</span>
                                </div>
                            </Col>


                            <Col  span={8} style={{width: 275, margin:'auto', justifySelf:'center'}} >
                                <div style={{ margin:'auto', border:'2px solid grey', borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"Curation"} width={100} src='images/microscope.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink exact to={{pathname:`/help_curation`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Curations</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Explanation to curate your data</span>
                                </div>
                            </Col>

                            <Col  span={8} style={{width: 275, margin:'auto', justifySelf:'center'}} >
                                <div style={{ margin:'auto', border:'2px solid grey', borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"DemosVideos"} width={100} src='images/logo_demo_video.svg'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink exact to={{pathname:`/videos`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Demos Videos</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>What can you do with MorphoNet?</span>
                                </div>
                            </Col>

                            <Col  span={8} style={{width: 275 , margin:'auto' , justifySelf:'center'}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img  alt="LoadHelpApp" width={100} src='images/refonte/load.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_app`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>3D Data Viewer</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Instructions to use the <br></br>interface.</span></div>
                            </Col>

                          <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',height : 275}} >
                                    <br></br>
                                    <img alt="help fiji" width={100} src='images/refonte/fiji_logo.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_fiji`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Fiji Plugin</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Tutorial for MorphoNet <br></br> Fiji plugin.</span></div>

                            </Col>
                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',height : 275}} >
                                    <br></br>
                                    <img alt="help fiji" width={100} src='images/virtual-reality.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_vr`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Virtual Reality module</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Tutorial for MorphoNet <br></br> virtual reality.</span></div>

                            </Col>
                        </div>
                    }

                    {
                        false && this.state.menu === "datastories" &&
                        <div style={{marginLeft:'12%'}} >
                            <Col span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"help data stories"} width={100} src='images/Datasets.png'></img>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}} exact to={{pathname:`/help_cionnectome`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Cionnectome</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Example for cionnectome data</span></div>
                            </Col>
                        </div>
                    }


                    {
                        this.state.menu === "advanced" &&
                        <div style={{marginLeft:'12%'}} >
                            <Col span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt='Python tutorial 1' width={100} src='images/logo_google_collab.png'></img>
                                    <br></br>
                                    <a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}} href="https://colab.research.google.com/drive/17j8oBZxag_u-_ihTeb7BkRDTVd-Yd4bn?usp=sharing">Python tutorial 1</a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Visualize/Analyze complex 3D+time.</span></div>
                            </Col>

                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img  alt='Python tutorial 2'  width={100} src='images/logo_google_collab.png'></img>
                                    <br></br>
                                    <a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}} href="https://colab.research.google.com/drive/1yAKKTXL6ZezFOLS8U4M2Ew4JIYr6bJxq?usp=sharing">Python tutorial 2</a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}> How to segment and track 3D cells.</span></div>
                            </Col>

                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img  alt='Python tutorial 3'  width={100} src='images/logo_google_collab.png'></img>
                                    <br></br>
                                    <a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}} href="https://colab.research.google.com/drive/11hrZdKUa0e7CelMyJAGITVTtESZwX1af?usp=sharing">Python tutorial 3</a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}> How to create a simple simulation.</span></div>
                            </Col>

                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img  alt='Python tutorial 4' width={100} src='images/logo_google_collab.png'></img>
                                    <br></br>
                                    <a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}} href="https://colab.research.google.com/drive/1XuTx6LzYuV1Z122n7sxUAzxs9oSpTgQ9?usp=sharing">Python tutorial 4</a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}> How cell adjacency relationships impact cell state transitions.</span></div>
                            </Col>

                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img  alt='Formats'  width={100} src='images/logo_format_new.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_format`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Format</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>You want to upload <br></br> various Data ?</span></div>

                    </Col>

                            <Col  span={4} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',height : 275}} >
                                    <br></br>
                                    <img alt="converters" width={100} src='images/logo_converter_new.svg'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_converter`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Converter</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>You have other <br></br> data format ?</span></div>

                    </Col>


                        </div>
                    }



                    {
                        this.state.menu === "devHelp" &&
                        <div style={{marginLeft: '27%'}}>
                            <Col span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt={"api"} width={100} src='images/api_icon.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_api`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>API Overview</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>You want to upload<br></br> more Data ? </span></div>
                            </Col>

                            <Col span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey', height : 275}} >
                                    <br></br>
                                    <img alt="api documentation" width={100} src='images/logo_api_documentation.svg'></img>
                                    <br></br>
                                    <br></br>
                                    <a style={{margin:'auto'}} href="helpfiles/API/index.html" ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>API documentation</a></a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Details of the python <br></br>API </span></div>
                            </Col>

                            <Col  span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey' , height : 275}} >
                                    <br></br>
                                    <img  alt="morphoapi" width={100} src='images/logo_integration_morpho.svg'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/morphoapi`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>MorphoNet Integration</a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Help for developer.</span></div>
                         </Col>
                    </div>

                    }

                    {
                        this.state.menu === "anyquestion" &&
                        <div style={{marginLeft: '27%'}}>
                            <Col span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',  height : 250}} >
                                    <br></br>
                                    <img alt="sc forum" width={100} src='images/logo_imagesc.png'></img>
                                    <br></br>
                                    <br></br>
                                    <a href="https://forum.image.sc/" style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>FAQ</a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Forum</span></div>
                            </Col>

                            <Col span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',  height : 250}} >
                                    <br></br>
                                    <img alt="troubleshootings" width={100} src='images/debug.png'></img>
                                    <br></br>
                                    <br></br>
                                    <NavLink style={{margin:'auto'}}exact to={{pathname:`/help_troubleshoot`}} ><a style={{fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Troubleshooting </a></NavLink>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}>Well-known issues</span></div>
                            </Col>

                            <Col  span={8} style={{margin:'auto', width: 275}}>
                                <div style={{margin:'auto',border:'2px solid grey',borderColor:'lightgrey',  height : 250}} >
                                    <br></br>
                                    <img alt="twitter" width={100} src='images/logo_twitter.svg'></img>
                                    <br></br>
                                    <br></br>
                                    <a href="https://twitter.com/MorphoTweet" style={{margin:'auto',fontSize:'21px',fontWeight:'bold',textDecoration:'underline', color:'black'}}>Updates ? </a>
                                    <br></br>
                                    <br></br>
                                    <span style={{color:'grey',fontSize:'17px'}}> Follow us on twitter </span></div>
                            </Col>
                    </div>

                    }

                </Row>
        { /*      <table style={{margin:'auto',marginLeft:'25%',width:'60%'}}>
                <thead >
                <tr>
                    <th colspan="2"><p ></p></th>
                </tr>
                </thead> 
                <tbody>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/refonte/update.png" width="70px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/patchnotes`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Patch notes</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>List of features released during last update</span></td>
                        <p></p>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/logotransparent.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_app`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Application</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>A general help on MorphoNet visual interface</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/refonte/web_logo.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_website`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Website</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>A general help on MorphoNet website</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/api_icon.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_api`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Python API</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>A general help on API commands to interact with MorphoNet</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/cloud-upload-2.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_format`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Format</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>Standard Dataset and Information structure that can be uploaded to MorphoNet</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/tutorial.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_tutorial`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Tutorials</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>Different tutorials using MorphoNet, with python API</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/converters.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_converter`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Converters</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>Severals converters to convert your data to MorphoNet</span></td>
                    </tr>
                    <tr>
                        <td style={{padding:'5px 5px 5px 5px'}}><img src="images/debug.png" width="80px"></img></td>
                        <td style={{}}><NavLink style={{marginLeft:'5%'}}exact to={{pathname:`/help_troubleshoot`}} ><a style={{fontSize:'17px',fontWeight:'bold'}}> Troubleshooting</a></NavLink></td>
                        <td style={{}}><span style={{color:'black',fontSize:'17px'}}>Known and recurrent problems during MorphoNet experience</span></td>
                    </tr>
                </tbody>
            </table>
        */ }

            </div>
			</div>
        );
    }
}

export default Help;