import React from "react";
import {Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {IsAtLeastManager} from "../utils/tools";
import {SendTracking} from "../utils/api_callbacks";

// Represents a sesison in the list of sessions for a dataset
export const SessionInstance = (props) => {
    //hook to get the value of connection from the store
    const connected = useSelector(state => state.user.is_connected)
    // hook to get the current clicked set
    const chosen_set = useSelector(state => state.dataset.current_choosen_set)
    //session is given by the parent (the list component cf : SessionListContainer)
    const item = props.session;
    // HTML div return , "" for nothing
    return <div style={{marginTop: "2%"}}>
        {/* Title */}
        <span style={item.defaut === 1 ? {fontWeight: 'bold', color: 'grey', fontSize: '18px'} : {
            color: 'grey', fontSize: '18px'
        }} className='layout-text'>{item.name}{item.defaut === 1 ? " (default)" : ""}  </span>
        <br/> <br/>
        URL : <a style={{color: '#44D4FF'}} href={"/"+item.shortcut}>{window.location.host}/{item.shortcut}</a><br></br>
        {/* Access update page */}
        <span>Parameters : {item.url.replace(/&/gi, " ; ")}</span>
        {
            connected && IsAtLeastManager(chosen_set) &&
            <NavLink exact={true} to={'/dataset/' + chosen_set + "/sessions/" + item.id + "/update/"}>
                <img width={30} alt="Session settings"
                     style={{display: 'inline', float: "right", marginRight: "20%", cursor: 'pointer'}}
                     src="/images/refonte/parameters.png"></img>
            </NavLink>
        }
        {/* Launch session */}
        <Link exact={true} to={item.shortcut === null ? '/' : '/' + item.shortcut}>
            <img onClick={() => {
                SendTracking(props.chosen_set, "Web,Dataset,Session,launch");
            }} style={{display: 'inline', marginRight: '20px', float: "right"}} alt="Load from Unity" width={30}
                 src="/images/refonte/load.png"></img>
        </Link>
    </div>

}

