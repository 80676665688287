import React, { Component } from "react";
import { List } from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';
import '../App.css';

class VideosList extends Component {
    constructor(props){
      super(props);
    }

    state = {
        id_user : -1,
        token : "",
        all_videos : []
    }

    componentDidMount(){
        //update view on resize
        this.state.id_user = getUserID();
        this.state.token = getUserToken();
        axios.get('/api/getvideoslist/',{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            const videos_sorted = res.data.sort((a, b) => {
                return a.name > b.name;
            });
            this.setState({
                all_videos: res.data,
            });
        });
    }




    componentDidUpdate(prevProps,prevState){
       
    }

    render(){
        return (
            <div>
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px',marginTop:'5%'}}> Video tutorials </h1>

                    {
                        this.state.all_videos.length > 0 ?
                        <div style={{marginTop:'5%'}}>
                        <List
                            itemLayout="horizontal"
                            style={{width:'100%',marginLeft:'15px'}}
                            dataSource={this.state.all_videos}
                            renderItem={item => (
                                <div id={item.title}><span style={{float:'left',color:'#44D4FF',fontWeight:"bold",fontSize:'24px'}} className='content-text'>{item.title}</span><br></br><br></br>
                                <List.Item>
                                <List.Item.Meta
                                    avatar={                   
                                        <video width={1920/3} height={1080/3} controls>
                                        <source src={"/helpfiles/VideoCapsules/"+item.name} type="video/mp4"></source>
                                        </video>
                                      }
                                    title={
                                    
                                        item.description.split("\\n").map(function(item, idx) {
                                            return (
                                                <span style={{color:'black',fontSize:'18px'}} key={idx}>
                                                    {item}
                                                    <br/>
                                                </span>
                                             )
                                        })
                                    
                                    /*<span style={{fontFamily:'Arial',fontSize:'16px'}}>{item.description.replace("\\n",<br></br>)}</span>*/}
                                />
 
                                </List.Item> 
                                <br></br> <br></br>
                                </div>
                            )}
                            
                            />
                            </div>
                            :
                            <p style={{fontFamily:'Arial',fontSize:'18px',fontWeight:"bold",marginLeft:'15px'}}> No videos availables </p>
                        }
                                                
            </div>
        );
    }
}

//      <Tooltip placement="top" title={<span>Restore the deleted information</span>}><img onClick={() => {this.restoreCorrespondence(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer'}} alt="Load from Unity"  width={40} src="/images/valid.png"></img></Tooltip>
//      <Tooltip placement="top" title={<span>Definitely erase the deleted information</span>}><img onClick={() => {this.eraseCorrespondence(item.id)}} style={{display:'inline',marginLeft:'10px',cursor:'pointer'}} alt="Load from Unity"  width={30} src="/images/delete.png"></img></Tooltip>
export default VideosList;