import React from "react";
import {IsAtLeastManager} from "../utils/tools";
import {List, Radio} from "antd";
import {useSelector} from "react-redux";
import {ChangePeoopleStudy, ChangePeopleGroupState} from "../utils/api_callbacks"


export const SharingStudyInstance = (props) => {
    const study = props.study;
    const user = props.user;
    const people_study_by_study = useSelector(state =>
        state.user.people_study_by_study);
    var value = "norights";
    if (Object.keys(people_study_by_study).includes(study.id.toString()) && people_study_by_study[study.id.toString()].length > 0) {
        if (Object.keys(people_study_by_study).includes(study.id.toString())) {
            const userstud = people_study_by_study[study.id].filter(study => study.id_user === user.id);
            if (userstud !== undefined && userstud.length > 0) {
                value = userstud[0].status;
            }
        }
    }

    return (<List.Item>
        <List.Item.Meta
            title={<span style={{color: 'grey', fontSize: '14px'}}
                      className='layout-text'>{user.name} {user.surname} </span>}
        />
        <Radio.Group disabled={!IsAtLeastManager()} onChange={(e) => {
            ChangePeoopleStudy( e, people_study_by_study[study.id], user.id,study.id)
        }}
                     value={value}>
            <Radio value={"manager"}>Manager</Radio>
            <Radio value={"reader"}>Reader</Radio>
            <Radio value={"norights"}>No rights</Radio>
        </Radio.Group>
    </List.Item>);
}

