import React from 'react';

class HelpDoc extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
	}

    render(){
        return(
                <div>
                <h3 style={{marginLeft:'33%',fontSize:'31px'}}>Morphonet Help format documentation</h3>
                <div style={{marginTop:'7%'}}>
                <p style={{fontSize:'19px'}}>A complete syntax guide for the help format can be found <a href="https://www.markdownguide.org/basic-syntax/"> here</a></p>
                <br></br>
                <h3 style={{fontSize:'25px'}}>Picture </h3>
                <p style={{fontSize:'19px'}}> After uploading your image using the uploader above the file editor, you can insert it in 2 ways:</p>
                <br></br>
                <img alt={"Uploader"} src="/images/doc/uploader.png" width="480px"></img>
                <br></br>
                <p style={{fontSize:'19px'}}>If your image already has the correct width and the correct height, you can use this line : <b>![<i>image placeholder text if it's not loaded</i>](<i>image link</i>)</b></p>
                <p style={{fontSize:'19px'}}>You can use this menu to insert the line in the source : <img  alt="menu image" src="/images/doc/imgmenu.png" width="50px"></img></p>
                <br></br>
                <p style={{fontSize:'19px'}}>If you need to resize the image, you must HTML Syntax : <b>&lt;img src="<i>image link</i>" height="<i>new height</i>px" width="<i>new width</i>px"&gt;&lt;/img&gt;</b>
                <br></br>
                You can resize by giving the width, or height, or both.</p>
                <br></br>
                <p style={{fontSize:'19px'}}>Example :</p>
                <p style={{fontSize:'19px'}}>&lt;img src="/images/logotransparent.png" width="80px"&gt; will display as : <img alt="logo transparent" src="/images/logotransparent.png" width="80px"></img></p>
                
                <br></br>
                <h3 style={{fontSize:'25px'}}>Link </h3>
                <p style={{fontSize:'19px'}}>You can use this menu to insert the line in the source : <img alt="menu links" src="/images/doc/linkmenu.png" width="50px"></img></p>
                <br></br>
                <p style={{fontSize:'19px'}}>This will add this line to the source : <b>[<i>displayed text</i>](<i>link</i>)</b>
                <br></br>
                The link can be a complete link, for example <b>https://www.google.fr</b> or a link inside the MorphoNet site, for example <b>datasets</b>.</p>

                <br></br>
                <h3 style={{fontSize:'25px'}}>Comments </h3>
                <p style={{fontSize:'19px'}}> You can insert comments to keep text visible only for person that have access to doc source code:</p>
                <br></br>
                <p style={{fontSize:'19px'}}>You can comment something using "<b>&lt;</b>" and "<b>&gt;</b>"</p>
                <br></br>
                <p style={{fontSize:'19px'}}>Example :</p>
                <p style={{fontSize:'19px'}}>"Text1 and  &lt;Text2&gt;" will display as : Text1 and </p>

                <br></br>
                <h3 style={{fontSize:'25px'}}>Array </h3>
                <p style={{fontSize:'19px'}}> You can insert array using this menu, and selecting the correct number of columns and lines:</p>
                <img alt="Array" src="/images/doc/tableau.png" width="180px"></img>
                <br></br>
                <br></br>
                <p style={{fontSize:'19px'}}>This will generate this text in the source : </p>
                <p style={{fontSize:'19px'}}>
                    
                | Head | Head | Head | Head |<br></br>
                | --- | --- | --- | --- |<br></br>
                | Data | Data | Data | Data |<br></br>
                | Data | Data | Data | Data |<br></br>
                | Data | Data | Data | Data | </p>
                <br></br>
                <p style={{fontSize:'19px'}}>That will be displayed as : </p>
                <img alt="line array" src="/images/doc/tabexample.png" width="180px"></img>
                <br></br>
                <p style={{fontSize:'19px'}}>You can add a line to the array with this line : </p>
                <p style={{fontSize:'19px'}}>| Data | Data | Data | Data |</p>
			</div>
            </div>
        );
    }
}

export default HelpDoc;