import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';


export const removeCookie = (name) => {
    Cookies.remove(name);
}

export const getUserToken = () => {
    if (getCookie('token') !== undefined) return getCookie('token');
    return null;
}

export const getUserID = () => {
    if (getCookie('user') !== undefined) return getCookie('user');
    return null;
}
export const getCookie = (name) => {
    if (Cookies.get(name) === undefined) {
        return null;
    }

    return decryptString(Cookies.get(name));
}

export const encryptString = message => {
    return CryptoJS.AES.encrypt(message, 'ncodsubnvojsfvsdv542154ds').toString();
}

export const decryptString = message => {
    let bytes = CryptoJS.AES.decrypt(message, 'ncodsubnvojsfvsdv542154ds');
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const setCookie = (name, value, parameters) => {
    //console.log(parameters);
    let cryptValue = encryptString(value);
    Cookies.set(name, cryptValue, parameters);
}