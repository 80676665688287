import React, {useEffect} from 'react';
import {Button, message, Spin} from 'antd';
const FloatingPanel = (props) => {

    return (
        <div style={{marginTop:"2%"}}>
            <Spin tip={props.text !== undefined && props.text !== "" && props.text !== null?props.text:""} size="large">
                <div className="content" />
            </Spin>
        </div>
    );
};
export default FloatingPanel;