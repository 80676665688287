import React from "react";
import {AddOverwriteById} from "./store/reducers/datasetSlice";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {SetRedirectedUrl} from "./store/reducers/userSlice";

class RedirectionSystem extends React.Component {
    state = {
    }


    redirectFeedBack = () => {
        window.history.pushState({}, '',window.location.pathname);
        this.props.SetRedirectedUrl("");
    }
    render() {
        return (

            <div>
                <h></h>
                {
                    this.props.current_redirected_url !== "" && this.props.current_redirected_url !== window.location.pathname &&

                    <Redirect to={this.props.current_redirected_url}>{this.redirectFeedBack()}</Redirect>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
return {
    current_redirected_url: state.user.current_redirected_url,
    }
}

//if bind into the export connect, give access to function you defined to modify state (here we give this page access to log function,it has to call onAuth to log the user in all the site)
const mapDispatchToProps = dispatch => {
return {
    SetRedirectedUrl : (url) => dispatch(SetRedirectedUrl(url)),
    AddOverwriteById: (time_range) => dispatch(AddOverwriteById(time_range)),

}
}
export default connect(mapStateToProps, mapDispatchToProps)(RedirectionSystem);
