import React from 'react';
import { NavLink } from 'react-router-dom';
import { List,Input, Button} from 'antd';
import {getUserToken,getUserID} from '../store/utility';
import axios from 'axios';


class DistantServerManagement extends React.Component {
    constructor(props){
        super(props);
	}
	
	state = {
		staff : false,
        token : "",
        connected_user_id : -1,

        server_list : undefined,
        choosen_server_name : "",
        choosen_server_id : -1,
        dataset_list : undefined,
        infos_list : undefined,
        new_server_adress : "",
        new_server_path : "",
        status_by_dataset : {},
        status_by_info : {},
        httpTestById : [],
        sshTestById : [],
        mesh_pathById : [],
        info_pathById : [],
        server_error : "",
	}

  deleteServer = (server_id) => {
    if (window.confirm('Are you sure you wish to delete this server ?')) 
    {
        const dataset_count = -1;
        const info_count = -1;
        this.setState({choosen_server_id : server_id});
    axios.get('/api/deleteserver/', {params:{id_server:server_id}, headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
        if (res.data.status !== "done")
        {this.setState({server_error:res.data.status});}
        else {
          window.location.reload();
        }
    });
  }
  } 

  DeleteSelectedDataset = (set_id) => {
    if (window.confirm('Are you sure you wish to delete this dataset ?')) 
    {
      this.props.sendTracking(this.state.set_id,"Web,Users,Server,Dataset,delete");
      axios({
      method: 'get',
      url: `/api/deletedatasetweb/`,
      params:{id_dataset:set_id},
      headers :{ Authorization: `Token ${this.state.token}` }
    }).then(res => {
      axios.get('/api/datasetonserver/', {params:{id_server:this.state.choosen_server_id}, headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => {
          if (res.data !== "failed")
          {this.setState({dataset_list:res.data.list});}
          else {
            this.setState({dataset_list:[]});
          }
      });
    });
    }
  }


  
  DeleteSelectedCorrespondence = (corr_id) => {
    if (window.confirm('Are you sure you wish to delete this correspondece ?')) 
    {
      this.props.sendTracking(this.state.set_id,"Web,Users,Server,Correspondence,delete");
      axios({
      method: 'get',
      url: `/api/deletecorrespondenceweb/`,
      params:{id_correspondence:corr_id},
      headers :{ Authorization: `Token ${this.state.token}` }
    }).then(res => {
      axios.get('/api/infoonserver/', {params:{id_server:this.state.choosen_server_id}, headers: { Authorization: `Token ${this.state.token}` } })
      .then(res => {
         if (res.data !== "failed")
          {this.setState({infos_list:res.data.list});}
          else {
            this.setState({infos_list:[]});
          }
      });
    });
    }
  }

testHTTP = (id_server) => {
    axios.get('/api/testserverhttp/', {params:{id_server:id_server}, headers: { Authorization: `Token ${this.state.token}` } })
    .then(res => {
      const nextStatus = [...this.state.httpTestById];
      nextStatus[id_server] = res.data;
      this.setState({ httpTestById: nextStatus });
    });
}


testSSH = (id_server) => {
  axios.get('/api/testserverhttp/', {params:{id_server:id_server}, headers: { Authorization: `Token ${this.state.token}` } })
  .then(res => {
    const nextStatus = [...this.state.sshTestById];
    nextStatus[id_server] = res.data;
    this.setState({ sshTestById: nextStatus });
  });
}
    
createServer = () => {
    if (this.state.new_server_adress !== "" && this.state.new_server_path !== ""){
      let formData = new FormData();
        formData.append('adress',this.state.new_server_adress);
        formData.append('path',this.state.new_server_path);
        formData.append('id_owner',this.state.connected_user_id);
        
        axios({
          method: 'post',
          url: `/api/distantserver/`,
          headers :{ Authorization: `Token ${this.state.token}` },
          data: formData,
        }).then(res => {this.setState({new_server_adress:""});this.setState({new_server_path:""});
        axios.get(`/api/distantserver/`,{ headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
            this.setState({
                server_list: res.data,
            });
        });});
    }
  }

    componentDidMount(){
        this.state.connected_user_id =  getUserID();
        this.setState({token : getUserToken()});
    }

    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({staff:res.data['staff']});
            });

            axios.get(`/api/distantserver/`,{ headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                this.setState({
                    server_list: res.data,
                });
            });
        }

        if (prevState.choosen_server_id !== this.state.choosen_server_id && this.state.choosen_server_id !== -1){
          this.setState({info_pathById:{}});
          this.setState({mesh_pathById:{}});
            axios.get('/api/datasetonserver/', {params:{id_server:this.state.choosen_server_id}, headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
                if (res.data !== "failed")
                {this.setState({dataset_list:res.data.list});}
                else {
                  this.setState({dataset_list:[]});
                }
            });

            axios.get('/api/infoonserver/', {params:{id_server:this.state.choosen_server_id}, headers: { Authorization: `Token ${this.state.token}` } })
            .then(res => {
               if (res.data !== "failed")
                {this.setState({infos_list:res.data.list});}
                else {
                  this.setState({infos_list:[]});
                }
            });
        }

    }

    testServer = () => {
      this.state.dataset_list.map(set => {
          const id = set[1];
          axios.get('/api/testdataset/', {params:{id_server:this.state.choosen_server_id,id_dataset:id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          if (res.data.test !== true && res.data.test !== "true"){
            this.state.mesh_pathById[id] = res.data.mesh_link;
          }
        this.state.status_by_dataset[id] = (res.data.test === true || res.data.test === "true");
        });
      });

      this.state.infos_list.map(info => {
          const id = info[1];
          axios.get('/api/testdataset/', {params:{id_server:this.state.choosen_server_id,id_correspondence:id}, headers: { Authorization: `Token ${this.state.token}` } })
        .then(res => {
          if (res.data.test !== true && res.data.test !== "true"){
            this.state.info_pathById[id] = res.data.mesh_link;
          }
          this.state.status_by_info[id] = res.data.test === true || res.data.test === "true";
        });
      });
    }

    render(){
    return(
			<div>
                <h1 style={{color:'#292e37',fontFamily:'Baskerville',fontSize:'34px',textAlign:'center',fontWeight:"bold",minWidth:'750px'}}> Your distant servers </h1>
                <div style={{textAlign:'center'}}><p style={{fontSize:'19px',fontWeight:"bold"}}>Add a server</p>
                   <h2 style={{display:'inline',color:'#44D4FF',fontSize:'20px'}}>Adress :</h2>
                  <Input
                    style={{width:'200px',marginLeft: '10px'}}
                    onChange={e => {this.setState({new_server_adress:e.target.value})}}
                    value={this.state.new_server_adress}
                    placeholder="Server adress"
                  />

                  <h2 style={{marginLeft:'10%',display:'inline',color:'#44D4FF',fontSize:'20px'}}>Path :</h2>
                  <Input
                    style={{width:'200px',marginLeft: '10px'}}
                    onChange={e => {this.setState({new_server_path:e.target.value})}}
                    value={this.state.new_server_path}
                    placeholder="MorphoNet directory path"
                  />

                  <Button disabled={this.state.new_server_adress === "" || this.state.new_server_path === ""} onClick={() =>   
                      {this.props.sendTracking(-1,"Web,Users,Server,create");this.createServer();}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginLeft:'10%'}} key="1" type={this.state.new_server_adress !== "" && this.state.new_server_path !== ""?"primary":"danger"}>
                        <span style={{color:'white'}}>Create server</span>
                      </Button></div>
                      <br></br><br></br><br></br>
                 <div style={{float:'left',width:'35%'}}><p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Your servers</p>
                  <List
                  itemLayout="horizontal"
                  dataSource={this.state.server_list}
                  style={{outline:' 0.5px solid #44D4FF',outlineOffset:'2px',paddingLeft:'10px',paddingRight:'10px',}}
                  renderItem={item => (
                  <div>
                  {
                  <List.Item
                  actions ={[
                    <Button onClick={() =>   
                        {this.props.sendTracking(-1,"Web,Users,Server,delete");this.setState({choosen_server_name:item.adress});this.setState({choosen_server_id:item.id})}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginLeft:'15%'}} key="1" type="primary">
                          <span>Show data</span>
                        </Button>,
                  <Button onClick={() => {  
                    this.props.sendTracking(-1,"Web,Users,Server,delete");this.deleteServer(item.id);}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginLeft:'5%'}} key="1" type="primary">
                      <img alt="delete" width={20} src='/images/garbage.png'></img>
                    </Button>,
                  <Button onClick={() =>   
                    {this.props.sendTracking(-1,"Web,Users,Server,TestHTTP");this.testHTTP(item.id);}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginLeft:'5%'}} key="1" type="primary">
                       <span>Test Download</span>
                    </Button>,
                  <Button onClick={() =>   
                    {this.props.sendTracking(-1,"Web,Users,Server,TestSSH");this.testSSH(item.id);}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginLeft:'5%'}} key="1" type="primary">
                       <span>Test Upload</span>
                    </Button>]}
                  >
                  <List.Item.Meta
                    title={<div><span style={{marginLeft:'1%',fontSize:19}}>Adress : {item.adress}</span><br></br> <br></br>  </div>}
                    description={<div><span style={{marginLeft:'2%',fontSize:17,color:this.state.httpTestById[item.id] !== undefined && this.state.httpTestById[item.id] === "True"? "#40ff00": this.state.httpTestById[item.id] !== undefined && this.state.httpTestById[item.id] === "False"?"#ff1a1a":"unset"}}> {this.state.httpTestById[item.id] !== undefined && this.state.httpTestById[item.id] === "True"? "Test Download : OK": this.state.httpTestById[item.id] !== undefined && this.state.httpTestById[item.id] === "False"?"Test Download : Failed":""}  </span><br></br>
                    <span style={{marginLeft:'2%',fontSize:17,color:this.state.sshTestById[item.id] !== undefined && this.state.sshTestById[item.id] === "True"? "#40ff00": this.state.sshTestById[item.id] !== undefined && this.state.sshTestById[item.id] === "False"?"#ff1a1a":"unset"}}> {this.state.sshTestById[item.id] !== undefined && this.state.sshTestById[item.id] === "True"? "Test Upload : OK": this.state.sshTestById[item.id] !== undefined && this.state.sshTestById[item.id] === "False"?"Test Upload : Failed":""}  </span></div>}
                  />
                  </List.Item>
                  }
                  </div>
                  )}
                  />
                  </div>
            {
                this.state.choosen_server_id !== -1 && this.state.choosen_server_id !== undefined &&
                  <div> <div style={{float:'right',width:'60%'}}
                  >
                  {
                  this.state.server_error !== "" && this.state.server_error !== undefined &&
                    <div><span style={{fontSize:'21px',color:'red'}}>{this.state.server_error}</span><br></br><br></br></div>
                  }
                  <p style={{color:'#44D4FF',fontFamily:'Arial',fontSize:'25px',fontWeight:"bold"}}> Data on {this.state.choosen_server_name} server </p>
                  <p style={{fontSize:'19px',fontWeight:"bold"}}>Datasets</p>
                  <List
                  itemLayout="horizontal"
                  dataSource={this.state.dataset_list}
                  style={{outline:' 0.5px solid #44D4FF',outlineOffset:'2px'}}
                  renderItem={item => (
                  <div>
                  {
                  <List.Item
                  actions ={[
                    <Button onClick={() =>   
                        {this.DeleteSelectedDataset(item[1])}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight:'20%'}} key="1" type="primary">
                           <img alt="delete" width={20} src='/images/garbage.png'></img>
                        </Button>,]}
                  style={{backgroundColor:this.state.status_by_dataset[item[1]] !== undefined && this.state.status_by_dataset[item[1]] === true?'#40ff00':this.state.status_by_dataset[item[1]] !== undefined?'#ff1a1a':'unset'}}
                  >
                  <List.Item.Meta
                    title={<NavLink exact to={{pathname:`/dataset`,preChoosenSet:item[1]}}><span style={{marginLeft:'1%',fontSize:19}} className='new-content-text'>{item[0]}</span>  </NavLink>}
                    description={this.state.mesh_pathById[item[1]] !== undefined && this.state.mesh_pathById[item[1]] !== ""?<span style={{marginLeft:'2%',fontSize:17}}>Verifiy mesh : {this.state.mesh_pathById[item[1]]}</span>:
                    item[2] === 1?<span style={{marginLeft:'2%',fontSize:17,color:'red'}}>This data is partially deleted, remove it from your bin to be able to delete a server</span>:<span></span>
                  }
                  />
                  </List.Item>
                  }
                  </div>
                  )}
                  />
                  <br></br>
                <p style={{fontSize:'19px',fontWeight:"bold"}}>Informations</p>
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.infos_list}
                  style={{outline:' 0.5px solid #44D4FF',outlineOffset:'2px'}}
                  renderItem={item => (
                  <div>
                  {
                  <List.Item
                  actions ={[
                    <Button onClick={() =>   
                        {this.DeleteSelectedCorrespondence(item[1])}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',marginRight:'20%'}} key="1" type="primary">
                           <img alt="delete" width={20} src='/images/garbage.png'></img>
                        </Button>,]}
                  style={{backgroundColor:this.state.status_by_info[item[1]] !== undefined && this.state.status_by_info[item[1]] === true?'#40ff00':this.state.status_by_info[item[1]] !== undefined?'#ff1a1a':'unset'}}
                  >
                  <List.Item.Meta
                    title={<div><span style={{marginLeft:'1%',fontSize:19}}>{item[0]}</span><br></br> <br></br>  </div>}
                    description={this.state.info_pathById[item[1]] !== undefined && this.state.info_pathById[item[1]] !== ""?<span style={{marginLeft:'2%',fontSize:17}}>Verify information : {this.state.info_pathById[item[1]]}</span>:<span></span>}
                  />
                  </List.Item>
                  }
                  </div>
                  )}
                  />
                  <br></br><br></br>
                  <div style={{textAlign:'center'}}><Button onClick={() =>   
                      {this.props.sendTracking(-1,"Web,Users,Server,TestServer");this.testServer();}} style={{borderColor:'#44D4FF',backgroundColor:'#44D4FF',margin:'auto',width:'25%'}} key="1" >
                        <span style={{color:'white'}}>Test files</span>
                      </Button></div>
                  </div>
                  </div>
                  }
                  
                  <br></br>
			</div>
        );
    }
}

export default DistantServerManagement;