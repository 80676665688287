// import react, react-markdown-editor-lite, and a markdown parser you like
import * as React from 'react'
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import htmlParser from 'react-markdown/plugins/html-parser';
import 'react-markdown-editor-lite/lib/index.css';
import axios from 'axios';
import {getUserToken} from '../store/utility';

  class MorphoMDDisplay extends React.Component {
    constructor(props){
        super(props);
    }



    state = {
        edit_mode : "false",
        source_link : "",
        next_content : "",
        content : "",
        token : "",
        status : "false",
        update_content_again : false,
    }
    // Register plugins if required
    // MdEditor.use(YOUR_PLUGINS_HERE);
    
    componentDidUpdate(prevProps,prevState){
        if (this.state.update_content_again !== prevState.update_content_again && this.state.update_content_again === true){
            this.setState({update_content_again:false});
            axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache'}})
            .then(res => {
                const result = res.data;
                if (result.includes("!doctype")){
                this.setState({update_content_again:true});
            }
            else {
                this.setState({content:result})
                window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
            }
            }).catch(err => {});
        }

        if (prevProps.sourceLink  !== this.props.sourceLink){
            this.setState({source_link : this.props.sourceLink});
            axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache','Content-Type': 'text/plain'}})
            .then(res => {
                const result = res.data;
                if (result.includes("!doctype")){
                    this.setState({update_content_again:true});
                }
                else {

                    this.setState({content:result})
                    window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
                }
            }).catch (err => {this.setState({update_content_again:true});})
        }

        if (prevProps.newContent  !== this.props.newContent){
            if (!this.props.newContent.includes("!doctype")){
                this.setState({content : this.props.newContent});
            }
        }
    }
    // Initialize a markdown parser
    mdParser = new MarkdownIt({html: true,
        linkify: true,
        typographer: true,});
    



    componentDidMount(){
        this.state.token = getUserToken();
        this.setState({edit_mode:"false"});
        this.setState({source_link : this.props.sourceLink});
        axios.get(this.props.sourceLink,{headers:{'Cache-Control': 'no-cache','Content-Type': 'text/plain'}})
        .then(res => {
            const result = res.data;
            if (result.includes("!doctype")){
                this.setState({update_content_again:true});
            }
            else {

                this.setState({content:result})
                window.scrollTo(window.scrollX+1,window.scrollY+1);window.scrollTo(window.scrollX-1,window.scrollY-1);
            }
        }).catch (err => {this.setState({update_content_again:true});})
        }

    parseHtml = htmlParser({
        isValidNode: node => node.type  !== 'script',
        processingInstructions: [/* ... */]
      })

    render() {
        return (
            <div>
                    <MdEditor
                    style={{marginTop:'2px'}}
                    value={this.state.content}
                    config={{
                        view: {
                            menu: false,
                            md: false,
                            html: true,
                            fullScreen: true,
                        },
                        canView : {
                            menu :false,
                            md: false
                        }
                        }}
                    renderHTML={(text) => this.mdParser.render(text)}
                    onChange={this.handleEditorChange}
                    />          
        </div>
        );
    }
  }
  
  export default MorphoMDDisplay;