import React from 'react';
import MorphoMDEditor from '../components/MarkdownEditor';
import {getUserToken,getUserID} from '../store/utility'
import axios from 'axios';
import {Menu} from "antd";
import RenderHTMLFromPage from "../components/RenderHTMLFromPage";

class HelpCuration extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
        staff : false,
        token : "",
        subfolder_link : "",
        connected_user_id : -1,
        displayplugins:false,
        source_link : "helpfiles/curations.md",
    }

    handleClick = e => {
        this.setState({
            source_link: e.key,
        });
        switch(e.key){
            case "helpfiles/curation.md":
                this.setState({subfolder_link:"curation",displayplugins:false});
                break;
            case "helpfiles/curations.md":
                this.setState({subfolder_link:"app_curations",displayplugins:false});
                break;
            case "helpfiles/error-diagram.md":
                this.setState({subfolder_link:"error-diagram",displayplugins:false});
                break;
            case "helpfiles/regionproperties.md":
                this.setState({subfolder_link:"regionproperties",displayplugins:false});
                break;
            case "pluginlist":
                this.setState({displayplugins:true});
                break;
        }
    };

    componentDidUpdate(prevProps,prevState){
        if (prevState.token !== this.state.token && this.state.token !== null && this.state.token !== ""){
            axios.get('/api/people-admin/', { headers: { Authorization: `Token ${this.state.token}` } })
                .then(res => {
                    this.setState({staff:res.data['staff']});
                });
        }
    }

    componentDidMount(){
        this.state.connected_user_id = getUserID();
        this.setState({token : getUserToken()});
        this.props.sendTracking(-1,"Web,Help,Curation,view");
    }


    render(){
        return(
            <div>
                <div style={{margin:'auto'}}>
                    <h1 className='main-title' style={{display:"inline-block",width:"100%",textAlign:"center"}}> MorphoCuration help </h1>
                    <br></br><br></br>
                    <div style={{width:'100%'}}>
                        <Menu onClick={this.handleClick} style={{display:'flex',justifyContent: 'center'}} defaultSelectedKeys={this.state.source_link} mode="horizontal">
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/curations.md">
                                <span className='new-content-text'> Curation menu </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/regionproperties.md">
                                <span className='new-content-text'> Segmentation properties </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/error-diagram.md">
                                <span className='new-content-text'> Quick curation guide </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="pluginlist">
                                <span className='new-content-text'> Curation plugins list </span>
                            </Menu.Item>
                            <Menu.Item className="removeSelectedBorder" style={{widht:'12%'}} key="helpfiles/curation.md">
                                <span className='new-content-text'> Curation example </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <br></br><br></br>
                    {
                        this.state.source_link !== "" && !this.state.displayplugins &&
                        <MorphoMDEditor subfolderName={this.state.subfolder_link === undefined || this.state.subfolder_link === "" ? "curation" :this.state.subfolder_link} sourceLink={this.state.source_link} > </MorphoMDEditor>
                    }
                    {
                        this.state.displayplugins &&
                        <div style={{height:"70vh"}}>
                            <RenderHTMLFromPage  htmlLink="helpfiles/API/morphonet.plugins.html"></RenderHTMLFromPage>
                        </div>
                    }

                </div>
            </div>
        );
    }
}

export default HelpCuration;
